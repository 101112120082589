import {useSelector} from "react-redux";
import {
    AntDesignOutlined,
    CloseCircleOutlined,
    MoreOutlined,
    UnorderedListOutlined,
    UserOutlined
} from "@ant-design/icons";
import {Avatar, Tooltip} from "antd";
import {useState} from "react";

const mappedWeekday = {
    2: "Thứ hai",
    3: "Thứ ba",
    4: "Thứ tư",
    5: "Thứ năm",
    6: "Thứ sáu",
    7: "Thứ bảy",
    8: "Chủ nhật",
};
const ClassroomSchedules = ({value, record, options}) => {
    const commonUsers = useSelector((state) => state.common?.users)

    const getUserInfo = (id) => {
        return commonUsers.find((u) => u?.id === id)
    }

    const schedules = record?.schedules
    return (
        <div className={"text-start fw-bold"}>
            {schedules?.map((schedule, key) =>
                <Schedule schedule={schedule} scheduleKey={key} getUserInfo={getUserInfo}/>
            )}
        </div>
    )
}

export default ClassroomSchedules

const Schedule = ({scheduleKey, schedule, getUserInfo}) => {
    const [showShifts, isShowShifts] = useState(false)
    return (
        <div key={scheduleKey} className={"mb-1"}>
            <div className={"d-flex align-items-center justify-content-between"}>
                <div>
                    <span className={"fw-bold text-purple-bright"}>{mappedWeekday[schedule?.week_day]} : </span>
                </div>
                <div className={"ms-1"}>
                    <span className={"text-success fw-bold"}>{schedule?.start_time}</span>
                    <span> - </span>
                    <span className={"text-danger fw-bold"}>{schedule?.end_time}</span>
                </div>
                <div onClick={() => {
                    isShowShifts(!showShifts)
                }}
                     className={"bg-label-purple text-xs px-2 rounded cursor-pointer ms-2"}>
                    {!showShifts &&
                        <Tooltip title={"Xem ca học"}>
                            <UnorderedListOutlined className={"text-success fw-bold"}/>
                        </Tooltip>
                    }
                    {showShifts &&
                        <Tooltip title={"Thu nhỏ"}>
                            <CloseCircleOutlined className={"text-danger fw-bold"}/>
                        </Tooltip>
                    }
                </div>
            </div>
            {showShifts &&
                <>
                    <div className={"border-bottom my-1"}></div>
                    {schedule?.shifts?.map((shift, shiftKey) =>
                        <div className={"text-xs border-bottom pb-2"}>
                            <div className={"fw-bold mb-1"}>
                                Ca {shiftKey + 1} : ({shift?.start_time} - {shift?.end_time})
                            </div>
                            {(getUserInfo(shift?.teacher_id) || getUserInfo(shift?.supporter_id)) ?
                                <div>
                                    <div className={"small"}>
                                        {getUserInfo(shift?.teacher_id) &&
                                            <Tooltip className={"cursor-pointer"}
                                                     title={getUserInfo(shift?.teacher_id)?.name + " - " + getUserInfo(shift?.teacher_id)?.uuid}>
                                                <Avatar className={"border"}
                                                        size={"small"}
                                                        src={getUserInfo(shift?.teacher_id)?.avatar}></Avatar>
                                            </Tooltip>
                                        }
                                        {getUserInfo(shift?.supporter_id) &&
                                            <Tooltip className={"cursor-pointer"}
                                                     title={getUserInfo(shift?.supporter_id)?.name + " - " + getUserInfo(shift?.supporter_id)?.uuid}>
                                                <Avatar size={"small"}
                                                        src={getUserInfo(shift?.supporter_id)?.avatar}></Avatar>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                                :
                                <div>(Không có giáo viên hoặc trợ giảng)</div>
                            }
                            <div className={"mt-2"}>Phòng học : {shift?.room || '(Trống)'}</div>
                        </div>
                    )}
                </>
            }
        </div>
    )
}