import React from 'react';
import {useSelector} from "react-redux";


const LoadingOverlay = () => {
    const commonState = useSelector((state) => state.common)

    return commonState?.loadingState ? (
        <div className="loading-overlay">
            <div className="loading-spinner"></div>
        </div>
    ) : null;
};

export default LoadingOverlay;
