import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllSalarySheetAction = async (query) => {
    return await (new Api()).get('salary_sheets', query, getAuthHeader());
}

export const syncSalarySheetAction = async () => {
    return await (new Api()).post('salary_sheets/sync', {}, getAuthHeader())
}

export const getOneSalarySheetAction = async (id, data) => {
    return await (new Api()).get(`salary_sheets/${id}`, data, getAuthHeader())
}

export const updateSalarySheetAction = async (data, id) => {
    return await (new Api()).put(`salary_sheets/${id}`, data, getAuthHeader())
}

export const deleteSalarySheetAction = async (id) => {
    return await (new Api()).delete(`salary_sheets/${id}`, getAuthHeader())
}

