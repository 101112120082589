import {useEffect} from "react";

const UnAuthenticatedLayout = ({children}) => {
    useEffect(() => {
        const jwtToken = localStorage.getItem('jwt_token')
        if (jwtToken) {
            window.location.href = window.location.origin
        }
    }, [])
    return (
        <div>
            {children}
        </div>
    )
}

export default UnAuthenticatedLayout