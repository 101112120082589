import {useEffect, useState} from "react";
import {getAllSharedCardsAction, getOneCardAction, updateCardAction} from "../../_api/CardApi";
import InputFormComponent from "../InputFormComponent";
import {getCommonFormRequestStruct} from "../../_api/CommonApi";

const EditCardDrawer = ({id, callback}) => {
    const [card, setCard] = useState({})
    const [errorBag, setErrorBag] = useState([])
    const [formStruct, setFormStruct] = useState([])

    useEffect(() => {
        getCommonFormRequestStruct('card').then((r) => {
            setFormStruct(r.data)
        })
    }, [id]);

    useEffect(() => {
        getOneCardAction(id).then(r => {
                if (r?.success) {
                    setCard(r?.data)
                }
            }
        )
    }, [id])

    const handleUpdate = () => {
        updateCardAction(card, id).then((r) => {
            if (r?.success) {
                getOneCardAction(id,{
                    fields: "id,uuid,van,student_id,classroom_id,original_days,bonus_days,original_fee,promotion_fee,paid_fee," +
                        "allow_deg,limit_deg,attended_days,total_days,total_fee,can_use_day_by_paid,unpaid_fee,can_use_day,can_use_fee," +
                        "user_abilities,van_and_attended_days,student,allow_deg,can_attendance",
                }).then(r => callback(r?.data))

            } else {
                setErrorBag(r?.errors)
            }
        })
    }

    return (
        <div>
            <InputFormComponent
                formStruct={formStruct}
                form={card}
                setForm={setCard}
                errorBag={errorBag}
                drawer={true}
            />
            <div className={"my-2 px-2"}>
                <button onClick={handleUpdate} className={"btn-purple-bright"}>Cập nhật</button>
            </div>
        </div>
    )
}

export default EditCardDrawer