import {useEffect, useState} from "react";
import {getAllSalaryCriteriaAction} from "../../_api/SalaryCriteriaApi";

const SelectSalaryCriteriaDrawer = ({callback}) => {
    const [salaryCriteriaCollection, setSalaryCriteriaCollection] = useState([])

    useEffect(() => {
        getAllSalaryCriteriaAction({
            limit: 100000000
        }).then(r => {
            if (r?.success) {
                setSalaryCriteriaCollection(r?.data?.data)
            }
        })
    }, []);

    return (
        <div>
            {salaryCriteriaCollection?.map((salaryCriteria, key) =>
                <div onClick={
                    () => {
                        callback(salaryCriteria)
                    }
                } key={key} className={"border mb-2 p-2 rounded cursor-pointer"}>
                    <div className={"fw-bold"}>{salaryCriteria?.criteria_name}</div>
                    <div
                        className={"text-xs text-muted"}>{salaryCriteria?.criteria_description || salaryCriteria?.criteria_name}</div>
                    <div className={"text-xs"}>
                        <div className={salaryCriteria?.direction_color}>Loại chỉ tiêu
                            : {salaryCriteria?.direction_title}</div>
                        <div>Hệ số mặc định: {salaryCriteria?.default_value.toLocaleString()}</div>
                        <div>
                            Công thức
                            tính: {salaryCriteria?.default_value.toLocaleString()} * {salaryCriteria?.count_type === 1 ? "X (Tính theo số lượng)" : "X / 100 (Tính theo phần trăm)"}</div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SelectSalaryCriteriaDrawer