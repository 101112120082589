import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllStudyLogsAction = async (query) => {
    return await (new Api()).get('studylogs', query, getAuthHeader());
}

export const getAllSharedStudyLogsAction = async (query) => {
    return await (new Api()).get('studylogs/s', query, getAuthHeader());
}

export const createStudyLogsAction = async (data) => {
    return await (new Api()).post('studylogs', data, getAuthHeader())
}

export const getOneStudyLogsAction = async (id) => {
    return await (new Api()).get(`studylogs/${id}`, {}, getAuthHeader())
}

export const updateStudyLogsAction = async (data,id) => {
    return await (new Api()).put(`studylogs/${id}`, data, getAuthHeader())
}

export const submitStudyLogsAction = async (id) => {
    return await (new Api()).put(`studylogs/${id}/submit`, {}, getAuthHeader())
}
export const confirmStudyLogsAction = async (id) => {
    return await (new Api()).put(`studylogs/${id}/confirm`, {}, getAuthHeader())
}
export const confirmAllStudyLogsAction = async (id) => {
    return await (new Api()).put(`studylogs/${id}/confirm_all`, {}, getAuthHeader())
}


export const cancelStudyLogsAction = async (id) => {
    return await (new Api()).put(`studylogs/${id}/cancel`, {}, getAuthHeader())
}

export const restoreStudyLogsAction = async (id) => {
    return await (new Api()).put(`studylogs/${id}/restore`, {}, getAuthHeader())
}

export const approveStudyLogsAction = async (id) => {
    return await (new Api()).put(`studylogs/${id}/approve`, {}, getAuthHeader())
}
export const refuseStudyLogsAction = async (id) => {
    return await (new Api()).put(`studylogs/${id}/refuse`, {}, getAuthHeader())
}
export const deleteStudyLogsAction = async (id) => {
    return await (new Api()).delete(`studylogs/${id}`,  getAuthHeader())
}