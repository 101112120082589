import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import Notification from "../../_classes/Notification";
import {createTransactionAction} from "../../_api/TransactionApi";
import {Col, Drawer, InputNumber, Select} from "antd";
import DatetimeField from "../Fields/DatetimeField";
import TextArea from "antd/lib/input/TextArea";
import AvatarField from "../Fields/AvatarField";

const SalarySheetActionColumn = ({value, record}) => {
    const [open,setOpen] = useState(false)
    const nav = useNavigate()
    return (
        <div className={"text"}>
            <div
                onClick={() => {
                    nav(`/salary_sheets/${record?.id}/show`)
                }}
                className={"fw-bold cursor-pointer text-purple-bright"}>Xem chi tiết phiếu
            </div>
            <div
                onClick={() => {
                    setOpen(true)
                }}
                className={"fw-bold cursor-pointer text-success"}>Chi lương
            </div>
            <div className={"fw-bold cursor-pointer text-hologram-blue"}>In phiếu lương</div>

            <Drawer
                closable
                destroyOnClose
                title={"Thêm mới giao dịch - Đóng học phí"}
                placement="right"
                loading={false}
                size={'large'}
                onClose={() => {
                    setOpen(false)
                }}
                open={open}
            >
                <QuickCreateSalaryLogTransaction/>
            </Drawer>
        </div>
    )
}

export default SalarySheetActionColumn

const QuickCreateSalaryLogTransaction = ({objectId, setOpen}) => {
    const [data, setData] = useState({
        object_id: objectId,
        object_type: "salary_log",
        transaction_type: "new",
    })
    const [errors, setErrors] = useState([])

    if (objectId === 0) {
        Notification.warn("Dữ liệu chưa chốt")
        setOpen(false)
        return;
    }

    const handleCreateTransaction = () => {
        createTransactionAction(data).then((r) => {
            if (r?.success) {
                Notification.success("Thêm thành công")
                setOpen(false)
            } else {
                setErrors(r?.errors)
            }
        })
    }
    return (
        <div>
            <div className={"row"}>
                <Col xl={12} md={24} className={"mb-3"}>
                    <div className={"fw-bold mb-1"}>Số tiền chi</div>
                    <InputNumber
                        value={data?.amount || 0}
                        onChange={(r) => setData({...data, amount: r})}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        placeholder={"Số tiền"} className={"w-100"}
                        suffix={"đ"}
                        size={"large"}
                    />
                </Col>
                <Col xl={12} md={24} className={"mb-3"}>
                    <div className={"fw-bold mb-1"}>Ngày chi</div>
                    <DatetimeField value={data?.transaction_day} setValue={(r) => {
                        setData({...data, transaction_day: r})
                    }}/>
                </Col>

                <Col xl={12} md={24} className={"mb-3"}>
                    <div className={"fw-bold mb-1"}>Phương thức thanh toán</div>
                    <Select
                        size={"large"}
                        defaultValue={"cash"}
                        value={data?.payment_method}
                        onChange={(r) => setData({...data, payment_method: r})}
                        options={[
                            {
                                value: "cash",
                                label: "Tiền mặt"
                            },
                            {
                                value: "atm",
                                label: "Chuyển khoản ngân hàng"
                            },
                            {
                                value: "credit",
                                label: "Thẻ trả góp"
                            }
                        ]}
                        className={"w-100"}
                    />
                </Col>
                <Col xl={12} md={24} className={"mb-3"}>
                    <div className={"fw-bold mb-1"}>Phương thức thanh toán</div>
                    <Select
                        size={"large"}
                        defaultValue={"new"}
                        value={data?.transaction_type}
                        onChange={(r) => setData({...data, transaction_type: r})}
                        options={[
                            {
                                value: "new",
                                label: "Mới (New)"
                            },
                            {
                                value: "renew",
                                label: "Gia hạn (Renew)"
                            },
                        ]}
                        className={"w-100"}
                    />
                </Col>
                <Col xl={24} md={24} className={"mb-3"}>
                    <div className={"fw-bold mb-1"}>Ghi chú thanh toán</div>
                    <TextArea
                        status={errors['notes'] ? "error" : null}
                        value={data?.notes}
                        onChange={(r) => {
                            setData({...data, notes: r.target.value})
                        }}
                        style={{minHeight: "200px"}}
                        size={"large"}
                        rootClassName={'p-2'}
                        placeholder={""}/>
                </Col>
            </div>

            <div className={"mb-3"}>
                <div className={"fw-bold mb-1"}>Bằng chứng thanh toán (Phiếu chi, giao dịch, ...)</div>
                <AvatarField value={data?.object_image} setValue={(r) => {
                    setData({...data, object_image: r})
                }}/>
            </div>

            <div className={"create"}>
                <button onClick={handleCreateTransaction} className={"btn-hologram"}>Chi lương</button>
            </div>
        </div>
    )
}