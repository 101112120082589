import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import InputFormComponent from "../../_components/InputFormComponent";
import {useEffect, useMemo, useState} from "react";
import {getCommonFormRequestStruct} from "../../_api/CommonApi";
import {getOneStaffAction, updateStaffAction} from "../../_api/StaffApi";
import Notification from "../../_classes/Notification";
import {useNavigate, useParams} from "react-router-dom";
import {BackwardFilled} from "@ant-design/icons";

const UpdateStaffScreen = () => {
    const [formStruct, setFormStruct] = useState([])
    const [staff, setStaff] = useState({})
    const nav = useNavigate();
    const {id} = useParams();
    const [errorBag, setErrorBag] = useState([])

    useMemo(() => {
        getCommonFormRequestStruct('staff').then((r) => {
            setFormStruct(r.data)
        })
    }, []);

    useMemo(() => {
        if (id !== undefined) {
            getOneStaffAction(id).then((response) => {
                setStaff(response?.data)
            })
        }
    }, [id]);

    const handleUpdateStaff = () => {
        updateStaffAction(staff, id).then((r) => {
            if (r?.success) {
                Notification.success(r?.data?.message)
                nav(-1)
            } else {
                setErrorBag(r?.errors)
            }
        })
    }
    return (
        <AuthenticatedLayout>
            <div className={"h4 text-dark"}>
                <span onClick={() => {
                    nav(-1)
                }} className={"text-xs text-purple-bright cursor-pointer mx-2"}>
                <BackwardFilled/>
                Trở về
                </span>
                <span>Chỉnh sửa nhân viên</span>
            </div>
            <div className={"my-4"}>
                <InputFormComponent
                    formStruct={formStruct}
                    form={staff}
                    setForm={setStaff}
                    errorBag={errorBag}
                />
            </div>
            <button onClick={handleUpdateStaff} className={"btn-purple-bright mt-2 p-2 px-3 mx-3"}>Cập nhật
            </button>
        </AuthenticatedLayout>
    )
}

export default UpdateStaffScreen