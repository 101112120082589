import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllCardsAction = async (query) => {
    return await (new Api()).get('cards', query, getAuthHeader());
}

export const getAllSharedCardsAction = async (query) => {
    return await (new Api()).get('cards/s', query, getAuthHeader());
}

export const createCardAction = async (data) => {
    return await (new Api()).post('cards', data, getAuthHeader())
}

export const getOneCardAction = async (id, data) => {
    return await (new Api()).get(`cards/${id}`, data, getAuthHeader())
}

export const updateCardAction = async (data, id) => {
    return await (new Api()).put(`cards/${id}`, data, getAuthHeader())
}
