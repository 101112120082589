import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useNavigate} from "react-router-dom";
import ForbiddenImage from "../../_assets/images/forbidden.webp"
const ForbiddenScreen = () => {
    const nav = useNavigate()
    return (
        <AuthenticatedLayout>
            <div style={{height: 800}} className={"rounded d-flex justify-content-center align-items-center"}>
                <div>
                    <img alt={"not_found_image"} src={ForbiddenImage || ''} style={{width: "30rem"}}/>
                    <div className={"text-center my-2"}>Bạn không có quyền truy cập</div>
                    {/*<div onClick={() => {*/}
                    {/*    nav(-1)*/}
                    {/*}} className={"text-purple-bright fw-bold text-center cursor-pointer"}>Tải lại*/}
                    {/*</div>*/}
                    <div onClick={() => {
                        nav("/")
                    }} className={"text-purple-bright fw-bold text-center cursor-pointer"}>Quay về trang chủ
                    </div>
                </div>
            </div>
        </AuthenticatedLayout>
    )
}

export default ForbiddenScreen