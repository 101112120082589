import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import notFoundImage from "../../_assets/images/not_found.gif"
import {useNavigate} from "react-router-dom";

const NotFoundScreen = () => {
    const nav = useNavigate()
    return (
        <AuthenticatedLayout>
            <div style={{height: 800}} className={"rounded d-flex justify-content-center align-items-center"}>
                <div>
                    <img alt={"not_found_image"} src={notFoundImage || ''} style={{width: "30rem"}}/>
                    <div className={"text-center my-2"}>Không tìm thấy đường dẫn : {window.location.href}</div>
                    <div onClick={() => {
                        nav("/")
                    }} className={"text-purple-bright fw-bold text-center cursor-pointer"}>Quay về trang chủ
                    </div>
                </div>
            </div>
        </AuthenticatedLayout>
    )
}

export default NotFoundScreen