import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllSupportersAction = async (query) => {
    return await (new Api()).get('supporters', query, getAuthHeader());
}

export const getAllSharedSupportersAction = async (query) => {
    return await (new Api()).get('supporters/s', query, getAuthHeader());
}

export const createSupporterAction = async (data) => {
    return await (new Api()).post('supporters', data, getAuthHeader())
}

export const getOneSupporterAction = async (id) => {
    return await (new Api()).get(`supporters/${id}`, {}, getAuthHeader())
}

export const updateSupporterAction = async (data,id) => {
    return await (new Api()).put(`supporters/${id}`, data, getAuthHeader())
}
