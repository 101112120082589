import SettingLayout from "../../_layouts/SettingLayout";
import {useEffect, useState} from "react";
import {
    deleteRoleAction,
    getAllRolesAction,
    getAllRolesTableAction,
    getOneRoleAction,
    updateRoleAction
} from "../../_api/RoleApi";
import {
    CheckOutlined,
    DeleteOutlined,
    EditOutlined,
    InfoCircleFilled,
    InfoCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import {Checkbox, Popconfirm, Radio, Table, Tooltip} from "antd";
import {comingSoon} from "../../_classes/Helper";
import CreateRoleModal from "../../_components/Modals/CreateRoleModal";
import Notification from "../../_classes/Notification";
import UpdateRoleModal from "../../_components/Modals/UpdateRoleModal";
import RoleUserGroupComponent from "../../_components/RoleUserGroupComponent";

const RoleSettingScreen = () => {
    const [roles, setRoles] = useState()

    const [selectedRole, setSelectedRole] = useState(undefined)

    const [currentRoleId, setCurrentRoleId] = useState(undefined)

    const [openQuickCreateRole, isOpenQuickCreateRole] = useState(false)
    const [openQuickUpdateRole, isOpenQuickUpdateRole] = useState(false)
    const [onUpdateId, setOnUpdateId] = useState(undefined)
    useEffect(() => {
        getAllRolesAction().then((r) => {
            if (r?.success) {
                setRoles(r?.data?.data)
            }
        })
    }, [])

    const [table, setTable] = useState({})

    useEffect(() => {
        if (currentRoleId !== undefined) {
            getOneRoleAction(currentRoleId).then(r => {
                if (r?.success) {
                    setSelectedRole(r?.data)
                }
            })
        }
    }, [currentRoleId]);

    useEffect(() => {
        if (selectedRole) {
            getAllRolesTableAction().then(r => {
                if (r?.success) {
                    setTable(r?.data)
                }
            })
        }
    }, [selectedRole]);

    const isActive = ({module, permission}) => {
        return selectedRole?.permissions?.find(p => (p.module_key === module.module_key && p.permission_key === permission.permission_key))
    }

    function handleChangPermission({module, permission, active}) {
        let data = {}
        if (active) {
            data = {
                added_permissions: [
                    {
                        module_key: module?.module_key,
                        permission_key: permission?.permission_key
                    }
                ]
            }
        } else {
            data = {
                removed_permissions: [
                    {
                        module_key: module?.module_key,
                        permission_key: permission?.permission_key
                    }
                ]
            }
        }

        updateRoleAction(data, currentRoleId).then(r => {
            if (r?.success) {
                Notification.success(r?.data?.message)
                getOneRoleAction(currentRoleId).then(r => {
                    if (r?.success) {
                        setSelectedRole(r?.data)
                    }
                })
            }
        })
    }

    return (
        <SettingLayout tab={'role'}>
            <div className={"row"} style={{minHeight: "90vh"}}>
                <div className={"col-md-3 col-sm-4 col-12"}>
                    <div className={"p-2 border-end h-100"}>
                        <div className={"fw-bold d-flex justify-content-between"}>
                            <div className={"fw-bold"}>Thiết lập nhóm quyền</div>
                            <div>
                                <PlusOutlined onClick={() => isOpenQuickCreateRole(true)} className={"cursor-pointer"}/>
                            </div>
                            <CreateRoleModal
                                setIsModalOpen={isOpenQuickCreateRole}
                                isModalOpen={openQuickCreateRole}
                                callback={() => {
                                    getAllRolesAction().then((r) => {
                                        if (r?.success) {
                                            setRoles(r?.data?.data)
                                        }
                                    })
                                }}
                            />
                        </div>
                        <div className={"mt-3"}>
                            {roles?.map((role, key) =>
                                <div className={"d-flex justify-content-between  ms-2 border-bottom py-2"} key={key}>
                                    <div>
                                        <span
                                            onClick={() => setCurrentRoleId(role?.id)}
                                            className={selectedRole?.id === role?.id ? "fw-bold cursor-pointer" : "cursor-pointer"}>
                                        - {role?.role_name}
                                        </span>
                                        {role?.is_default === 1 &&
                                            <span className={"ms-2 text-purple-bright"}>
                                           <Tooltip title={'Nhóm quyền mặc định của hệ thống'}>
                                                <InfoCircleOutlined/>
                                           </Tooltip>
                                        </span>
                                        }
                                    </div>
                                    <div>
                                        {
                                            role?.is_default === 0 &&
                                            <span>
                                            <EditOutlined
                                                onClick={() => {
                                                    setOnUpdateId(role?.id)
                                                    isOpenQuickUpdateRole(true)
                                                }}
                                                className={"px-1 fw-bold text-purple-bright"}/>
                                                <UpdateRoleModal
                                                    id={onUpdateId}
                                                    setIsModalOpen={isOpenQuickUpdateRole}
                                                    isModalOpen={openQuickUpdateRole}
                                                    callback={() => {
                                                        getAllRolesAction().then((r) => {
                                                            if (r?.success) {
                                                                setRoles(r?.data?.data)
                                                            }
                                                        })
                                                    }}
                                                />
                                            <Popconfirm
                                                onConfirm={() => {
                                                    deleteRoleAction(role?.id).then((r) => {
                                                        if (r?.success) {
                                                            Notification.success(r?.data?.message)
                                                            getAllRolesAction().then((r) => {
                                                                if (r?.success) {
                                                                    setRoles(r?.data?.data)
                                                                }
                                                            })
                                                        }
                                                    })
                                                }}
                                                cancelText={"Huỷ"}
                                                okText={"Xác nhận xoá"}
                                                title={"Xoá quyền ?"}>
                                                <DeleteOutlined className={"px-1 fw-bold text-purple-bright"}/>
                                            </Popconfirm>
                                        </span>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={"col-md-9 col-sm-8 col-12"}>
                    <div className={"p-2"}>
                        {
                            selectedRole?.is_default === 0 &&
                            <div>
                                <RoleUserGroupComponent
                                    users={selectedRole?.users}
                                    roleId={currentRoleId}
                                    callback={() => {
                                        getOneRoleAction(currentRoleId).then(r => {
                                            if (r?.success) {
                                                setSelectedRole(r?.data)
                                            }
                                        })
                                    }}
                                />
                            </div>
                        }
                        {selectedRole &&
                            <table className={"table"}>
                                <thead>
                                <tr className={"bg-shadow"}>
                                    <th className={"fw-bold border p-3 text-center"} style={{width: 200}}>Module quản lý
                                    </th>
                                    {table?.permissions?.map((permission, key) =>
                                        <th key={key} style={{width: 200}}
                                            className={"fw-bold border p-3 text-center"}>{permission?.permission_name}</th>
                                    )}
                                </tr>
                                </thead>
                                <tbody>
                                {table?.modules?.map((module, m_key) =>
                                    <tr key={m_key}>
                                        <td className={"border p-2 text-center"}>{module?.module_name}</td>
                                        {table?.permissions?.map((permission, key) =>
                                            <td className={"text-center border"}>
                                                {selectedRole?.is_default === 0 &&
                                                    <Checkbox
                                                        onClick={() => {
                                                            handleChangPermission({
                                                                module: module,
                                                                permission: permission,
                                                                active: !isActive({
                                                                    module: module,
                                                                    permission: permission
                                                                })
                                                            })
                                                        }}
                                                        className={"text-purple-bright"}
                                                        checked={isActive({
                                                            module: module,
                                                            permission: permission
                                                        })}/>
                                                }

                                                {selectedRole?.is_default === 1 &&
                                                    <div>
                                                        {isActive({
                                                                module: module,
                                                                permission: permission
                                                            }) &&
                                                            <CheckOutlined className={"fw-bold text-purple-bright"}/>
                                                        }
                                                    </div>
                                                }
                                            </td>
                                        )}
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </SettingLayout>
    )
}

export default RoleSettingScreen