import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllCommentsAction = async (query) => {
    return await (new Api()).get('comments', query, getAuthHeader());
}

export const createCommentAction = async (data) => {
    return await (new Api()).post('comments', data, getAuthHeader())
}

export const getOneCommentAction = async (id, data) => {
    return await (new Api()).get(`comments/${id}`, data, getAuthHeader())
}

export const updateCommentAction = async (data, id) => {
    return await (new Api()).put(`comments/${id}`, data, getAuthHeader())
}
export const deleteCommentAction = async (data, id) => {
    return await (new Api()).put(`comments/${id}`, data, getAuthHeader())
}

