import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Notification {
    static success(message, options = {}) {
        toast.success(message, {
            position: 'top-center', // Sử dụng chuỗi vị trí
            autoClose: 3000,
            ...options,
        });
    }

    static error(message, options = {}) {
        toast.error(message, {
            position: 'top-center', // Sử dụng chuỗi vị trí
            autoClose: 5000,
            ...options,
        });
    }

    static info(message, options = {}) {
        toast.info(message, {
            position: 'top-center', // Sử dụng chuỗi vị trí
            autoClose: 4000,
            ...options,
        });
    }

    static warn(message, options = {}) {
        toast.warn(message, {
            position: 'top-center', // Sử dụng chuỗi vị trí
            autoClose: 3500,
            ...options,
        });
    }

    static renderToastContainer(props = {}) {
        return (
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                {...props}
            />
        );
    }
}

export default Notification;
