import DataTableComponent from "../../_components/DataTableComponent";
import {useEffect, useState} from "react";
import FilteringComponent from "../../_components/Columns/FilteringComponent";
import {Button} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {handleCreateUrlParams, handleParseUrlParams} from "../../_classes/Helper";
import {commonAction} from "../../_provider/_slices/CommonSlice";
import {useDispatch} from "react-redux";
import SettingLayout from "../../_layouts/SettingLayout";
import {getAllSalaryCriteriaAction} from "../../_api/SalaryCriteriaApi";

const ListSalaryCriteriaScreen = () => {
    const location = useLocation();
    const [salaryCriteriaCollection, setSalaryCriteriaCollection] = useState({})
    const nav = useNavigate()
    const [query, setQuery] = useState()
    const columnStruct = [
        {
            title: 'Tên chỉ tiêu',
            dataIndex: 'criteria_name',
            key: 'criteria_name',
            type: "criteria_name",
            width: 300
        },
        {
            title: "Loại chỉ tiêu",
            dataIndex: "direction_type",
            type: "select",
            width: 100,
            options: [
                {
                    value: 1,
                    label: "Thưởng",
                    rootClassName: "text-success"
                },
                {
                    value: 2,
                    label: "Phạt",
                    rootClassName: "text-danger"
                },
            ]
        },
        {
            title: "Giá trị mặc định",
            dataIndex: "default_value",
            type: "number",
            width: 100,
            rootClassName: "text-center",
        },
        {
            title: "Tính theo",
            dataIndex: "count_type",
            width: 150,
            type: 'select',
            rootClassName: "text-center",
            options: [
                {
                    value: 1,
                    label: "Số lượng (X * số lượng)",
                    rootClassName: "text-primary"
                },
                {
                    value: 2,
                    label: "Phần trăm (X * số lượng / 100)",
                    rootClassName: "text-purple"
                },
            ]
        },
        {
            title: 'Hành động',
            dataIndex: 'user_abilities',
            key: 'user_abilities',
            type: "user_abilities",
            width: 100
        }
    ];
    const filteringStructs = [
        {
            field: "criteria_name",
            type: "text",
            label: "Tên chỉ tiêu"
        }
    ]
    const dispatch = useDispatch()
    useEffect(() => {
        if (query) {
            const urlPath = handleCreateUrlParams(query)
            nav(urlPath)
            dispatch(commonAction({loadingState: true}))
            getAllSalaryCriteriaAction(query).then((response) => {
                if (response?.success) {
                    setSalaryCriteriaCollection(response?.data)
                    dispatch(commonAction({loadingState: false}))
                } else {
                    dispatch(commonAction({loadingState: false}))
                }
            }).catch(() => {
                dispatch(commonAction({loadingState: false}))
            })
        }
    }, [query]);

    useEffect(() => {
        const searchParams = location.search.substring(1);
        const parseParams = handleParseUrlParams(searchParams)
        setQuery({
            limit: parseParams?.limit ?? 20,
            order: parseParams?.order,
            direction: parseParams?.direction,
            filtering: parseParams?.filtering,
            offset: parseParams?.offset || 0
        })
    }, [])

    return (
        <SettingLayout tab={'salary_criteria'}>
            <div className={"d-flex justify-content-between my-4"}>
                <FilteringComponent
                    filteringStructs={filteringStructs}
                    query={query}
                    setQuery={setQuery}
                />
                <Button onClick={() => nav('/salary_criteria/create')} className={'btn-purple-bright'}>Thêm mới</Button>
            </div>
            <div>
                <DataTableComponent
                    columnStructs={columnStruct}
                    collection={salaryCriteriaCollection}
                    query={query}
                    setQuery={setQuery}
                    setCollection={setSalaryCriteriaCollection}
                />
            </div>
        </SettingLayout>
    )
}

export default ListSalaryCriteriaScreen