import {Input} from "antd";
import app_logo from "../../_assets/images/logo-ngang.png";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import UnAuthenticatedLayout from "../../_layouts/UnAuthenticatedLayout";
import {loginAction} from "../../_api/AuthenticateApi";
import {useState} from "react";
import Password from "antd/es/input/Password";
import {comingSoon} from "../../_classes/Helper";
import {useNavigate} from "react-router-dom";
import CustomLink from "../../_components/CustomLink";

const LoginScreen = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState([])
    const nav = useNavigate()
    const switchToRegister = () => {
        nav('/register')
    }
    const handleLogin = () => {
        loginAction({
            username: username,
            password: password
        }).then(r => {
            if (r.success) {
                localStorage.setItem('jwt_token', r?.data?.token)
                window.location.href = window.location.origin
            } else {
               setErrors(r?.errors)
            }
        })
    }

    return (
        <UnAuthenticatedLayout>
            <div className={"bg-base d-flex justify-content-center align-items-center"} style={{height: '100vh'}}>
                <div className={"bg-white shadow-lg p-5"} style={{width: 400, borderRadius: 20}}>
                    <div className={"text-purple-bright fw-bold h3 m-0 text-center mb-3"}>
                        <img className={"mb-3"} src={app_logo} style={{width: "180px"}}/>
                    </div>
                    <div className={"px-1"}>
                        <div className={"mb-2"}>
                            <Input value={username} onChange={(r) => setUsername(r.target.value)}
                                   prefix={<UserOutlined/>} placeholder={"Tên tài khoản"}
                                   rootClassName={"p-1 px-2 mb-2"} status={errors?.username ? "error" : false}/>
                            {errors?.username &&
                                <div className={"mb-3 text-xs text-danger"}>{errors?.username}</div>
                            }
                            <Password value={password} onChange={(r) => setPassword(r.target.value)}
                                      prefix={<LockOutlined/>} placeholder={"Mật khẩu"}
                                      status={errors?.password ? "error" : false}
                                      rootClassName={"p-1 px-2 mb-2"}/>
                            {errors?.password &&
                                <div className={"mb-3 text-xs text-danger"}>{errors?.password}</div>
                            }
                        </div>
                        <div className={"small text-purple-bright mb-2 cursor-pointer"}>
                            <CustomLink to={"/forgot_password"}>Quên mật khẩu ?</CustomLink>
                        </div>
                        <button onClick={handleLogin} className={"btn-purple-bright p-1 px-5 w-100"}>Đăng nhập</button>
                        <div className={"mt-2 small"}>
                            <span className={"me-1"}>Bạn chưa có tài khoản?</span>
                            <span onClick={switchToRegister} className={"text-purple-bright cursor-pointer"}>
                            Khởi tạo hệ thống Host
                        </span>
                        </div>
                        <div className={"m-auto w-75 border-bottom my-2 mt-4"}>
                        </div>
                        <div className={"small text-center mb-2"}>Hoặc đăng nhập bằng tài khoản đã liên kết</div>
                        <button onClick={comingSoon} className={"btn-facebook p-1 px-5 w-100 mb-2"}>
                            <span></span>
                            <span>Facebook</span>
                        </button>
                        <button onClick={comingSoon} className={"btn-google p-1 px-5 w-100 mb-3"}>
                            <span></span>
                            <span>Google</span>
                        </button>
                    </div>
                </div>
            </div>
        </UnAuthenticatedLayout>
    )
}
export default LoginScreen