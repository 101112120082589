import {useEffect} from "react";
import {useSelector} from "react-redux";
import {FacebookFilled, GoogleCircleFilled} from "@ant-design/icons";

const HomeUserInformation = () => {
    const commonState = useSelector((state) => state.common)
    useEffect(() => {

    }, []);

    return (
        <div className={"p-2 bg-white rounded-5 shadow-sm"}>
            <div className={"user-avatar p-1 w-100 text-center"}>
                <img alt={"avatar"} style={{width:'8rem'}} className={"rounded-circle"} src={commonState?.currentUser?.avatar}/>
            </div>
            <div className={"fw-bold h6 text-center mt-2 mb-0"}>
                {commonState?.currentUser?.name}
            </div>
            <div className={"text-center mb-2"}>
                ({commonState?.currentUser?.uuid})
            </div>
            <div className={"mt-3 border-top pt-2 px-3"}>
                <div className={"d-flex align-items-center"}>
                    <GoogleCircleFilled className={"text-danger"}/>
                    <span className={"ms-2"}>phamquanglinhdev@gmail.com</span>
                </div>
                <div className={"d-flex align-items-center"}>
                    <FacebookFilled className={"text-primary"}/>
                    <span className={"ms-2"}>Phạm Linh</span>
                </div>
            </div>
        </div>
    )
}

export default HomeUserInformation