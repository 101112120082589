import {Badge, Col, Drawer, Input, InputNumber, Select, Tag} from "antd";
import {useMemo, useState} from "react";
import {AppstoreAddOutlined, DeleteFilled, PlusCircleOutlined} from "@ant-design/icons";
import Ribbon from "antd/es/badge/Ribbon";
import {getAllSharedTeachersAction} from "../_api/TeacherApi";
import {mapSelectData} from "../_classes/Helper";
import {getAllSharedSupportersAction} from "../_api/SupporterApi";
import SelectSalaryCriteriaDrawer from "./Drawers/SelectSalaryCriteriaDrawer";
import Notification from "../_classes/Notification";

const CustomInputForm = ({formInput, getDataStruct, setDataStruct, attribute, setAttribute, errorBag}) => {
    return (
        <>
            {formInput?.type === 'custom_total_days' &&
                <CustomTotalDayInput attribute={attribute}/>
            }
            {formInput?.type === 'custom_promotion_percent' &&
                <PromotionPercentInput attribute={attribute}/>
            }
            {formInput?.type === 'custom_total_fee' &&
                <TotalFeeInput attribute={attribute}/>
            }
            {formInput?.type === 'custom_daily_fee' &&
                <DailyFeeInput attribute={attribute}/>
            }
            {formInput?.type === 'custom_schedules' &&
                <SchedulesInput
                    setAttribute={setAttribute}
                    attribute={attribute}
                    errorBag={errorBag}
                />
            }
            {formInput?.type === 'custom_config_details' &&
                <SalaryConfigDetailsInput attribute={attribute} setAttribute={setAttribute} errorBag={errorBag}/>
            }
        </>
    )
}

export default CustomInputForm

const SalaryConfigDetailsInput = ({attribute, setAttribute, errorBag}) => {
    const updateConfigDetail = ({criteria_id, key, value}) => {
        const newCriteriaDetail = attribute?.config_details?.map((c) => {
            if (c.criteria_id === criteria_id) {
                return {...c, [key]: value}
            }

            return c
        })

        setAttribute({
            ...attribute,
            config_details: newCriteriaDetail
        })
    }

    const deleteConfigDetail = ({criteria_id}) => {
        setAttribute({
            ...attribute,
            config_details: attribute?.config_details.filter(r => r?.criteria_id !== criteria_id),
            removed_config_detail_ids: [...attribute?.removed_config_detail_ids || [], criteria_id]
        })
    }

    const addConfigDetails = (criteria) => {
        if (attribute?.config_details?.find(r => r?.criteria_id === criteria?.id)) {
            Notification.warn("Đã tồn tại chỉ tiêu")

            return;
        }

        setAttribute({
            ...attribute,
            config_details: [
                ...attribute?.config_details || [], {
                    detail_name: criteria?.criteria_name,
                    criteria_id: criteria?.id,
                    count_type: criteria?.count_type,
                    detail_rate: criteria?.default_value || 0,
                    direction_type: criteria?.direction_type,
                    detail_description: criteria?.criteria_description,
                    criteria_code: criteria?.criteria_code,
                }
            ]
        })
        setOpenDrawer(false)
    }
    const [openDrawer, setOpenDrawer] = useState(false)
    return (
        <div className={"mt-2 "}>
            <table className={"w-100 bg-white rounded"}>
                <thead>
                <tr className={"rounded"}>
                    <th className={"border p-2 fw-bold bg-shadow rounded"}>Tên chỉ tiêu</th>
                    <th className={"text-center border p-2 fw-bold bg-shadow rounded"}>Loại</th>
                    <th className={"border p-2 text-center fw-bold bg-shadow rounded"}>Tỉ lệ</th>
                    <th className={"border p-2 text-center fw-bold bg-shadow rounded"}>Tính theo</th>
                </tr>
                </thead>
                <tbody>
                {attribute?.config_details?.map((config_detail, key) =>
                    <tr key={key}>
                        <td className={"p-2 border rounded"}>
                            <Input onChange={(r) => {
                                updateConfigDetail({
                                    criteria_id: config_detail?.criteria_id,
                                    key: "detail_name",
                                    value: r.target.value
                                })
                            }} value={config_detail?.detail_name} size={"middle"}/>
                            <div className={"text-xs p-1 text-muted"}>
                                {config_detail?.config_description}
                            </div>
                        </td>
                        <td className={"p-2 text-center border rounded"}>
                            <span
                                className={config_detail?.direction_type === 1 ? 'text-success fw-bold' : 'text-danger fw-bold'}>
                                {config_detail?.direction_type === 1 ? 'Thưởng' : 'Phạt'}
                            </span>
                        </td>
                        <td className={"border p-2 rounded"}>
                            <InputNumber onChange={(r) => {
                                updateConfigDetail({
                                    criteria_id: config_detail?.criteria_id,
                                    key: "detail_rate",
                                    value: r
                                })
                            }} value={config_detail?.detail_rate} className={"w-100"}/>
                        </td>
                        <td className={"border text-center rounded"}>
                            {config_detail?.count_type === 1 &&
                                <span>Số lượng (X * {config_detail?.detail_rate})</span>
                            }
                            {config_detail?.count_type === 2 &&
                                <span>Phần trăm (X * {config_detail?.detail_rate / 100}) %</span>
                            }
                            <DeleteFilled onClick={() => {
                                deleteConfigDetail({criteria_id: config_detail?.criteria_id})
                            }} className={"text-danger ms-2 cursor-pointer"}/>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            <Drawer
                closable
                destroyOnClose
                title={"Chọn chỉ tiêu thưởng phạt"}
                placement="right"
                open={openDrawer}
                loading={false}
                size={"large"}
                onClose={() => {
                    setOpenDrawer(false)
                }}
            >
                <SelectSalaryCriteriaDrawer callback={addConfigDetails}/>
            </Drawer>
            <div onClick={() => setOpenDrawer(true)} className={"my-2 text-purple-bright fw-bold cursor-pointer"}>
                <AppstoreAddOutlined/>
                <span className={"fw-bold ms-1"}>Thêm chỉ tiêu</span>
            </div>
        </div>
    )
}

const CustomTotalDayInput = ({attribute}) => {
    return (
        <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            readOnly={true} size={"small"}
            className={"w-100 p-2"}
            value={(attribute?.original_days || 0) + (attribute?.bonus_days || 0)}
        />
    )
}

const PromotionPercentInput = ({attribute}) => {
    const promotionPercent = ((attribute?.promotion_fee / attribute?.original_fee) * 100).toLocaleString(
        undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })

    const promotionPercentResult = attribute?.original_fee ? promotionPercent : 0
    return (
        <InputNumber
            formatter={(value) => `${value} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            readOnly={true} size={"small"}
            className={"w-100 p-2"}
            value={promotionPercentResult}
        />
    )
}

const TotalFeeInput = ({attribute}) => {
    return (
        <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            readOnly={true} size={"small"}
            className={"w-100 p-2"}
            value={(attribute?.original_fee || 0) - (attribute?.promotion_fee || 0)}
        />
    )
}

const DailyFeeInput = ({attribute}) => {
    const totalDays = attribute?.original_days + attribute?.bonus_days
    const totalFee = attribute?.original_fee - attribute?.promotion_fee
    const dailyFee = Math.round(totalFee / totalDays)

    return (
        <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            readOnly={true} size={"small"}
            className={"w-100 p-2"}
            value={dailyFee}
        />
    )
}

const SchedulesInput = ({setAttribute, attribute, errorBag}) => {
    const [teachers, setTeachers] = useState([])
    const [supporters, setSupporters] = useState([])
    const handleAddNewSchedule = () => {
        setAttribute({
            ...attribute, schedules: [...attribute?.schedules ?? [], {
                id: Date.now(),
                week_day: 2,
                start_time: "",
                end_time: "",
                shifts: [
                    {
                        id: Date.now(),
                        start_time: "",
                        end_time: "",
                        room: "",
                        supporter_id: null,
                        teacher_id: null
                    },
                ]
            }]
        })
    }

    const handleAddNewShift = ({schedule_id}) => {
        const newSchedules = attribute?.schedules.map((schedule) => {
            if (parseInt(schedule.id) === parseInt(schedule_id)) {
                return {
                    ...schedule, shifts: [...schedule?.shifts, {
                        id: Date.now(),
                        start_time: "",
                        end_time: "",
                        room: "",
                        supporter_id: null,
                        teacher_id: null
                    }]
                }
            }

            return schedule
        })

        setAttribute({...attribute, schedules: newSchedules})
    }

    useMemo(() => {
        if (!attribute?.schedules) {
            handleAddNewSchedule()
        }
    }, [attribute])

    useMemo(() => {
        getAllSharedTeachersAction().then((r) => {
            setTeachers(r?.data?.data)
        })
    }, [])

    useMemo(() => {
        getAllSharedSupportersAction().then((r) => {
            setSupporters(r?.data?.data)
        })
    }, [])

    const updateSchedule = ({id, key, data}) => {
        const newSchedules = attribute?.schedules.map((schedule) => {
            if (parseInt(schedule.id) === parseInt(id)) {
                return {...schedule, [key]: data}
            }

            return schedule
        })

        setAttribute({...attribute, schedules: newSchedules})
    }

    const updateShift = ({schedule_id, shift_id, key, data}) => {
        const newSchedules = attribute?.schedules.map((schedule) => {
            if (parseInt(schedule.id) === parseInt(schedule_id)) {
                const newShifts = schedule?.shifts?.map((shift) => {
                    if (parseInt(shift.id) === shift_id) {
                        return {...shift, [key]: data}
                    }

                    return shift
                })

                return {...schedule, shifts: newShifts}
            }

            return schedule
        })

        setAttribute({...attribute, schedules: newSchedules})
    }

    const removeSchedule = ({schedule_id}) => {
        setAttribute({
            ...attribute,
            schedules: attribute?.schedules?.filter((s) => parseInt(s?.id) !== parseInt(schedule_id)),
            removed_schedule_ids: [...attribute?.removed_schedule_ids ?? [], schedule_id]
        })
    }

    const removeShift = ({schedule_id, shift_id}) => {
        setAttribute({
            ...attribute,
            schedules: attribute?.schedules.map((schedule) => {
                if (schedule?.id === schedule_id) {
                    return {...schedule, shifts: schedule?.shifts?.filter((shift) => shift?.id !== shift_id)}
                }

                return schedule
            }),
            removed_shift_ids: [...attribute?.removed_shift_ids ?? [], shift_id]
        })
    }

    return (
        <div className={"my-2"}>
            {attribute?.schedules?.map((schedule, schedule_key) =>
                <Badge.Ribbon color={"purple"} placement={"start"} text={"Buổi học " + (schedule_key + 1)}>
                    <div key={schedule_key} className={"p-3 pt-5 bg-white border rounded mb-3 position-relative"}>
                        {attribute?.schedules.length > 1 &&
                            <div onClick={() => {
                                removeSchedule({schedule_id: schedule?.id})
                            }} style={{position: "absolute", top: 10, right: 10}}
                                 className={"px-3 btn-danger text-xs p-1 rounded cursor-pointer"}>
                                Xoá buổi học
                            </div>
                        }
                        <div className={"row m-0"}>
                            <div className={"col-4"}>
                                <div className={"mb-1"}>Chọn Thứ</div>
                                <Select
                                    size={"large"}
                                    onChange={(r) => {
                                        updateSchedule({
                                            id: schedule?.id,
                                            key: "week_day",
                                            data: r
                                        })
                                    }}
                                    value={schedule?.week_day}
                                    className={"w-100"}
                                    options={[
                                        {
                                            value: 2,
                                            label: "Thứ hai"
                                        },
                                        {
                                            value: 3,
                                            label: "Thứ ba"
                                        },
                                        {
                                            value: 4,
                                            label: "Thứ tư"
                                        },
                                        {
                                            value: 5,
                                            label: "Thứ năm"
                                        },
                                        {
                                            value: 6,
                                            label: "Thứ sáu"
                                        },
                                        {
                                            value: 7,
                                            label: "Thứ bảy"
                                        },
                                        {
                                            value: 8,
                                            label: "Chủ nhật"
                                        },
                                    ]}/>
                                <div
                                    className={"text-xs text-danger my-1"}>{errorBag["schedules." + schedule_key + ".week_day"]}</div>
                            </div>
                            <div className={"col-4"}>
                                <div className={"mb-1"}>Thời gian bắt đầu</div>
                                <div>
                                    <input
                                        value={schedule?.start_time}
                                        onChange={(r) => {
                                            updateSchedule({
                                                id: schedule?.id,
                                                key: "start_time",
                                                data: r.target.value
                                            })
                                        }}
                                        type={'time'}
                                        className={"p-1 border rounded w-100 p-2 time-input" + (errorBag["schedules." + schedule_key + ".start_time"] ? '-danger' : null)}
                                    />
                                    <div
                                        className={"text-xs text-danger my-1"}>{errorBag?.["schedules." + schedule_key + ".start_time"]?.[0] || ''}</div>
                                </div>
                            </div>
                            <div className={"col-4"}>
                                <div className={"mb-1"}>Thời gian kết thúc</div>
                                <div>
                                    <input
                                        value={schedule?.end_time}
                                        onChange={(r) => {
                                            updateSchedule({
                                                id: schedule?.id,
                                                key: "end_time",
                                                data: r.target.value
                                            })
                                        }}

                                        type={'time'}
                                        className={"p-1 border rounded w-100 p-2 time-input" + (errorBag["schedules." + schedule_key + ".end_time"] ? '-danger' : null)}
                                    />
                                    <div
                                        className={"text-xs text-danger my-1"}>{errorBag?.["schedules." + schedule_key + ".end_time"]?.[0] || ''}</div>
                                </div>
                            </div>
                        </div>
                        <div className={"border-bottom my-3"}>

                        </div>
                        {schedule?.shifts?.map((shift, shift_key) =>
                            <div key={shift_key} className={"p-2 mb-3 position-relative"}>
                                {schedule?.shifts.length > 1 &&
                                    <div
                                        onClick={() => {
                                            removeShift({
                                                schedule_id: schedule?.id,
                                                shift_id: shift?.id
                                            })
                                        }}
                                        className={"text-danger fw-bold cursor-pointer"}
                                        style={{position: "absolute", right: 10}}>Xoá ca học
                                    </div>
                                }
                                <div className={"mb-1"}><Tag color="purple">Ca học {shift_key + 1}</Tag></div>
                                <div className={"bg-label-purple p-1 rounded"}>
                                    <div className={"row m-0 py-2"}>
                                        <div className={"col-6"}>
                                            <div className={"p-1"}>
                                                <div className={"mb-2"}>Chọn giáo viên</div>
                                                <Select
                                                    value={shift?.teacher_id}
                                                    showSearch={true}
                                                    filterOption={(input, option) =>
                                                        (option?.label.toLowerCase() ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    allowClear={true}
                                                    size={'large'} options={mapSelectData({
                                                    key: 'id',
                                                    label: 'name',
                                                    data: teachers,
                                                    prefix: 'uuid',
                                                })}
                                                    onChange={(r) => {
                                                        updateShift({
                                                            schedule_id: schedule?.id,
                                                            shift_id: shift?.id,
                                                            key: 'teacher_id',
                                                            data: r
                                                        })
                                                    }}
                                                    className={"w-100"}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-6"}>
                                            <div className={"p-1"}>
                                                <div className={"mb-2"}>Chọn trợ giảng</div>
                                                <Select
                                                    value={shift?.supporter_id}
                                                    showSearch={true}
                                                    filterOption={(input, option) =>
                                                        (option?.label.toLowerCase() ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    allowClear={true}
                                                    size={'large'} options={mapSelectData({
                                                    key: 'id',
                                                    label: 'name',
                                                    data: supporters,
                                                    prefix: 'uuid',
                                                })}
                                                    onChange={(r) => {
                                                        updateShift({
                                                            schedule_id: schedule?.id,
                                                            shift_id: shift?.id,
                                                            key: 'supporter_id',
                                                            data: r
                                                        })
                                                    }}
                                                    className={"w-100"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row m-0 py-2 mb-3"}>
                                        <div className={"col-4"}>
                                            <div className={"mb-1"}>Phòng học</div>
                                            <Input
                                                value={shift?.room}
                                                onChange={(r) => {
                                                    updateShift({
                                                        schedule_id: schedule?.id,
                                                        shift_id: shift?.id,
                                                        key: "room",
                                                        data: r.target.value
                                                    })
                                                }} size={"large"}/>
                                        </div>
                                        <div className={"col-4"}>
                                            <div className={"mb-1"}>Thời gian bắt đầu ca</div>
                                            <div>
                                                <input
                                                    value={shift?.start_time}
                                                    onChange={(r) => {
                                                        updateShift({
                                                            schedule_id: schedule?.id,
                                                            shift_id: shift?.id,
                                                            key: "start_time",
                                                            data: r.target.value
                                                        })
                                                    }}
                                                    type={'time'}
                                                    className={"p-1 border rounded w-100 p-2 time-input" + (errorBag["schedules." + schedule_key + ".shifts." + shift_key + ".start_time"] ? '-danger' : null)}/>
                                                <div
                                                    className={"text-xs text-danger"}>
                                                    {errorBag?.["schedules." + schedule_key + ".shifts." + shift_key + ".start_time"]?.[0] || ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-4"}>
                                            <div className={"mb-1"}>Thời gian kết thúc ca</div>
                                            <div>
                                                <input
                                                    value={shift?.end_time}
                                                    onChange={(r) => {
                                                        updateShift({
                                                            schedule_id: schedule?.id,
                                                            shift_id: shift?.id,
                                                            key: "end_time",
                                                            data: r.target.value
                                                        })
                                                    }}
                                                    type={'time'}
                                                    className={"p-1 border rounded w-100 p-2 time-input" + (errorBag["schedules." + schedule_key + ".shifts." + shift_key + ".end_time"] ? '-danger' : null)}/>
                                                <div
                                                    className={"text-xs text-danger"}>
                                                    {errorBag?.["schedules." + schedule_key + ".shifts." + shift_key + ".end_time"]?.[0] || ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={"px-2 cursor-pointer fw-bold text-purple-bright"} onClick={() => {
                            handleAddNewShift({
                                schedule_id: schedule.id
                            })
                        }}>
                            <PlusCircleOutlined/>
                            <span className={"ms-2 fw-bold"}>Thêm ca học</span>
                        </div>
                    </div>
                </Badge.Ribbon>
            )}
            <div>
                <span className={"cursor-pointer btn-purple-bright p-2 px-3 text-xs"} onClick={handleAddNewSchedule}>Thêm buổi học</span>
            </div>
        </div>
    )
}