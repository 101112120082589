import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {Breadcrumb, Col, Drawer, Input, InputNumber, Popconfirm, Select, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    closeSalarySnapAction,
    getOneCloseSalarySnapAction,
    getOneSalarySnapAction,
    updateSalarySnapManualValue
} from "../../_api/SalarySnapApi";
import {CheckCircleFilled, CheckCircleOutlined, EditOutlined, FolderOpenFilled} from "@ant-design/icons";
import Notification from "../../_classes/Notification";
import CommentsComponent from "../../_components/CommentsComponent";
import {useSelector} from "react-redux";
import DatetimeField from "../../_components/Fields/DatetimeField";
import TextArea from "antd/lib/input/TextArea";
import {mapSelectData} from "../../_classes/Helper";
import AvatarField from "../../_components/Fields/AvatarField";
import {approveTransactionAction, createTransactionAction, getAllTransactionsAction} from "../../_api/TransactionApi";
import {toast} from "react-toastify";

const ShowSalarySnapScreen = () => {
    const nav = useNavigate()
    const [salarySnap, setSalarySnap] = useState({})
    const [editId, setEditId] = useState([])
    const [updateData, setUpdateData] = useState({})
    const {id} = useParams()
    const [onClose, setOnClose] = useState(false)
    const commonState = useSelector((state) => state.common)
    const [openQuickCreate, setOpenQuickCreate] = useState(false)
    useEffect(() => {
        getOneSalarySnapAction(id).then(r => {
            if (r.success) {
                setSalarySnap(r?.data)
            }
        })
    }, [id]);

    useEffect(() => {
        if (onClose === true) {
            getOneCloseSalarySnapAction(id).then(r => {
                if (r.success) {
                    setSalarySnap(r?.data)
                }
            })
        } else {
            getOneSalarySnapAction(id).then(r => {
                if (r.success) {
                    setSalarySnap(r?.data)
                }
            })
        }
    }, [onClose]);

    function handleUpdateManualValue() {
        updateSalarySnapManualValue(updateData).then((r) => {
            if (r?.success) {
                Notification.success(r?.data?.message)
                getOneSalarySnapAction(id).then(r => {
                    if (r.success) {
                        setSalarySnap(r?.data)
                    }
                })
            }
            setEditId(editId.filter(r => r !== updateData?.snap_detail_id))
        })
    }

    function handleCloseSalarySnap() {
        closeSalarySnapAction(id).then((r) => {
            if (r?.success) {
                Notification.success("Thành công")
                getOneSalarySnapAction(id).then(r => {
                    if (r.success) {
                        setSalarySnap(r?.data)
                    }
                })
            }
        })
    }

    return (
        <AuthenticatedLayout>
            <Breadcrumb
                style={{
                    margin: '0',
                }}
            >
                <Breadcrumb.Item className={"cursor-pointer"} onClick={() => {
                    nav(-1)
                }}>Thẻ lương</Breadcrumb.Item>
                <Breadcrumb.Item>Thẻ lương của nhân sự
                    <span className={"fw-bold cursor-pointer mx-1"}>{salarySnap?.user?.name}</span>
                    lớp
                    <span className={"fw-bold cursor-pointer mx-1"}>
                        {salarySnap?.classroom?.name}
                    </span> tháng {String((new Date(salarySnap?.period)).getMonth() + 1).padStart(2, '0')}
                    <span className={"mx-1"}>năm</span>
                    <span>
                        {String((new Date(salarySnap?.period)).getFullYear()).padStart(2, '0')}
                    </span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className={"mt-3"}>
                <div className={"row"}>
                    <Col xl={16} md={24}>
                        <div className={"p-2"}>
                            <div className={"mb-1"}>Tổng số giờ: <span
                                className={"fw-bold text-purple-bright cursor-pointer"}>{salarySnap?.salary_value}</span>
                            </div>
                            <div className={"mb-1"}>Lương theo
                                giờ: <span>{salarySnap?.salary_rate?.toLocaleString()} đ</span></div>
                            <div className={"mb-1"}>Lương được
                                nhận: <span>{salarySnap?.salary_per_hour?.toLocaleString()} đ</span></div>
                            {(salarySnap?.is_closed === 1 || onClose) &&
                                <div className={"d-flex my-2"}>
                                    <Switch checked={onClose} onClick={() => {
                                        setOnClose(!onClose)
                                    }}/>
                                    <div className={"ms-2 fw-bold"}>Xem dữ liệu chốt</div>
                                </div>
                            }
                        </div>
                        <div className={"mb-0"}>
                            <table>
                                <thead>
                                <tr>
                                    <th className={"p-2 fw-bold border"}>Tên chỉ tiêu</th>
                                    <th className={"p-2 fw-bold border"}>Loại</th>
                                    <th className={"p-2 fw-bold border"}>Giá trị</th>
                                    <th className={"p-2 fw-bold border"}>Tỉ lệ</th>
                                    <th className={"p-2 fw-bold border"}>Thành tiền</th>
                                </tr>
                                </thead>
                                <tbody>
                                {salarySnap?.snap_details?.map((snap_detail, key) =>
                                    <tr key={key}>
                                        <th className={"p-2 border"} style={{width: 300}}>
                                            <div>{snap_detail?.detail_name}</div>
                                            <div className={"text-xs"}>{snap_detail?.detail_description}</div>
                                        </th>
                                        <th className={"p-2 border"}>{snap_detail?.direction_type === 1 ? "Thưởng" : "Phạt"}</th>
                                        <th className={"p-2 border"}>
                                            {snap_detail?.criteria_code !== 'MANUAL' &&
                                                <span className={"fw-bold text-purple-bright"}>
                                                    {snap_detail?.value}
                                                </span>
                                            }
                                            {snap_detail?.criteria_code === 'MANUAL' &&
                                                <span className={"fw-bold text-orange"}>
                                                   {editId?.includes(snap_detail?.id) &&
                                                       <InputNumber defaultValue={snap_detail?.value} onChange={(r) => {
                                                           setUpdateData({
                                                               snap_detail_id: snap_detail?.id,
                                                               value: r || 0
                                                           })
                                                       }}/>
                                                   }
                                                    {!editId?.includes(snap_detail?.id) &&
                                                        <span className={"fw-bold"}> {snap_detail?.value}</span>
                                                    }
                                                    {editId?.includes(snap_detail?.id) ?
                                                        <CheckCircleOutlined
                                                            onClick={handleUpdateManualValue}
                                                            className={"ms-2 cursor-pointer h5 m-0"}/>
                                                        :
                                                        <span>
                                                            {salarySnap?.user_abilities?.can_edit === 1 &&

                                                                <EditOutlined onClick={() => {
                                                                    setEditId([...editId, snap_detail?.id])
                                                                }} className={"ms-2 cursor-pointer"}/>}
                                                        </span>
                                                    }
                                                </span>
                                            }
                                        </th>
                                        <th className={"p-2 border"}>{parseInt(snap_detail?.detail_rate)?.toLocaleString()}</th>
                                        <th className={"p-2 border"}>{snap_detail?.salary_detail_value?.toLocaleString()}</th>
                                    </tr>
                                )}
                                </tbody>
                                <tfoot>
                                <tr className={"border"}>
                                    <th colSpan={5} className={"p-2 text-end"}>
                                        Tổng thưởng phạt: <span
                                        className={"mx-1 fw-bold"}>{salarySnap?.sum_salary_detail_value?.toLocaleString()}</span> đ
                                    </th>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className={"my-3 fw-bold"}>Tổng nhận: {salarySnap?.total?.toLocaleString()} đ</div>
                        {!onClose &&
                            <Popconfirm
                                onConfirm={handleCloseSalarySnap}
                                title={"Xác nhận chốt thẻ lương này"}>
                                <button className={"cursor-pointer btn-hologram"}>
                                    Chốt thẻ lương
                                </button>
                            </Popconfirm>
                        }
                        {commonState?.permissions?.CREATE_TRANSACTION === 1 && commonState?.permissions?.UPDATE_SALARY === 1 &&
                            <React.Fragment>
                                <button onClick={() => {
                                    setOpenQuickCreate(true)
                                }} className={"ms-2 cursor-pointer btn-hologram"}>
                                    Tạo giao dịch
                                </button>
                                <Drawer
                                    closable
                                    destroyOnClose
                                    title={"Chi lương nhân sự"}
                                    placement="right"
                                    size={'large'}
                                    onClose={() => {
                                        setOpenQuickCreate(false)
                                    }}
                                    open={openQuickCreate}
                                >
                                    <QuickCreateSalaryLogTransaction
                                        objectId={onClose ? salarySnap?.id : salarySnap?.close_id}
                                        setOpen={setOpenQuickCreate}/>
                                </Drawer>
                            </React.Fragment>
                        }
                        <div className={"my-2 mt-4"}>
                            <SalaryLogTransaction
                                objectId={onClose ? salarySnap?.id : salarySnap?.close_id}
                                callback={()=>{}}/>
                        </div>
                    </Col>
                    <Col xl={8} md={24}>
                        <CommentsComponent height={"40rem"} objectId={id} objectType={'salary_log'} changeData={() => {
                        }}/>
                    </Col>
                </div>
            </div>
        </AuthenticatedLayout>
    )
}

export default ShowSalarySnapScreen

const QuickCreateSalaryLogTransaction = ({objectId, setOpen}) => {
    const [data, setData] = useState({
        object_id: objectId,
        object_type: "salary_log",
        transaction_type: "new",
    })
    const [errors, setErrors] = useState([])

    if (objectId === 0) {
        Notification.warn("Dữ liệu chưa chốt")
        setOpen(false)
        return;
    }

    const handleCreateTransaction = () => {
        createTransactionAction(data).then((r) => {
            if (r?.success) {
                Notification.success("Thêm thành công")
                setOpen(false)
            } else {
                setErrors(r?.errors)
            }
        })
    }
    return (
        <div>
            <div className={"row"}>
                <Col xl={12} md={24} className={"mb-3"}>
                    <div className={"fw-bold mb-1"}>Số tiền chi</div>
                    <InputNumber
                        value={data?.amount || 0}
                        onChange={(r) => setData({...data, amount: r})}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        placeholder={"Số tiền"} className={"w-100"}
                        suffix={"đ"}
                        size={"large"}
                    />
                </Col>
                <Col xl={12} md={24} className={"mb-3"}>
                    <div className={"fw-bold mb-1"}>Ngày chi</div>
                    <DatetimeField value={data?.transaction_day} setValue={(r) => {
                        setData({...data, transaction_day: r})
                    }}/>
                </Col>

                <Col xl={12} md={24} className={"mb-3"}>
                    <div className={"fw-bold mb-1"}>Phương thức thanh toán</div>
                    <Select
                        size={"large"}
                        defaultValue={"cash"}
                        value={data?.payment_method}
                        onChange={(r) => setData({...data, payment_method: r})}
                        options={[
                            {
                                value: "cash",
                                label: "Tiền mặt"
                            },
                            {
                                value: "atm",
                                label: "Chuyển khoản ngân hàng"
                            },
                            {
                                value: "credit",
                                label: "Thẻ trả góp"
                            }
                        ]}
                        className={"w-100"}
                    />
                </Col>
                <Col xl={12} md={24} className={"mb-3"}>
                    <div className={"fw-bold mb-1"}>Phương thức thanh toán</div>
                    <Select
                        size={"large"}
                        defaultValue={"new"}
                        value={data?.transaction_type}
                        onChange={(r) => setData({...data, transaction_type: r})}
                        options={[
                            {
                                value: "new",
                                label: "Mới (New)"
                            },
                            {
                                value: "renew",
                                label: "Gia hạn (Renew)"
                            },
                        ]}
                        className={"w-100"}
                    />
                </Col>
                <Col xl={24} md={24} className={"mb-3"}>
                    <div className={"fw-bold mb-1"}>Ghi chú thanh toán</div>
                    <TextArea
                        status={errors['notes'] ? "error" : null}
                        value={data?.notes}
                        onChange={(r) => {
                            setData({...data, notes: r.target.value})
                        }}
                        style={{minHeight: "200px"}}
                        size={"large"}
                        rootClassName={'p-2'}
                        placeholder={""}/>
                </Col>
            </div>

            <div className={"mb-3"}>
                <div className={"fw-bold mb-1"}>Bằng chứng thanh toán (Phiếu chi, giao dịch, ...)</div>
                <AvatarField value={data?.object_image} setValue={(r) => {
                    setData({...data, object_image: r})
                }}/>
            </div>

            <div className={"create"}>
                <button onClick={handleCreateTransaction} className={"btn-hologram"}>Chi lương</button>
            </div>
        </div>
    )
}

const SalaryLogTransaction = ({objectId, callback}) => {
    const [transactions, setTransaction] = useState([])
    useEffect(() => {
        if (objectId) {
            getAllTransactionsAction({
                filtering: {
                    object_type: "salary_log",
                    object_id: objectId
                }
            }).then(r => {
                if (r?.success) {
                    setTransaction(r?.data?.data)
                }
            })
        }
    }, [objectId])

    const handleApprove = ({id}) => {
        approveTransactionAction(id).then(r => {
            if (r?.success) {
                callback()
            }
        })
    }

    return (
        <div className={"p-1"}>
            <div className={"text-purple-bright mb-2 fw-bold"}>Giao dịch chi lương</div>
            <table className={"w-100"}>
                <thead>
                <tr>
                    <th className={"border p-1 fw-bold text-center"}>Mã giao dịch</th>
                    <th className={"border p-1 fw-bold text-center"}>Ngày giao dịch</th>
                    <th className={"border p-1 fw-bold text-center"}>Số tiền</th>
                    <th style={{minWidth: '15rem'}} className={"border p-1 fw-bold text-center"}>Ghi chú</th>
                    <th className={"border p-1 fw-bold text-center"}>Đinh kèm</th>
                    <th className={"border p-1 fw-bold text-center"}>Trạng thái</th>
                </tr>
                </thead>
                <tbody>
                {transactions?.map((transaction, key) =>
                    <tr>
                        <th className={"border p-1 px-3 text-center"}>#{transaction?.uuid}</th>
                        <th className={"border p-1 px-3 text-center"}>{transaction?.transaction_day}</th>
                        <th className={"border p-1 px-3 text-end fw-bold text-purple-bright text-center"}>{transaction?.amount?.toLocaleString()} đ</th>
                        <th className={"border p-1 px-3"}>
                            <div>
                                Người tạo giao dịch : <span
                                className={"fw-bold text-purple-bright"}>{transaction?.creator?.name} ({transaction?.creator?.uuid})</span>
                            </div>
                            <div>{transaction?.notes}</div>
                        </th>
                        <th className={"border p-1 px-3 text-center"}>
                            {transaction?.object_image &&
                                <FolderOpenFilled onClick={() => {
                                    window.open(transaction?.object_image)
                                }} className={"text-purple-bright"}/>
                            }
                        </th>
                        <th className={"border p-1 px-3 text-center"}>
                            <div className={"d-flex justify-content-center align-items-center"}>
                                <span>{transaction?.status === 1 ? "Đã duyệt" : "Chờ duyệt"}</span>
                                <span className={"ms-1"}>{transaction?.user_abilities?.can_approve === 1 &&
                                    <Popconfirm
                                        onConfirm={() => {
                                            handleApprove({id: transaction?.id})
                                        }}
                                        okText={"Duyệt"}
                                        cancelText={"Xem xét lại"}
                                        title={"Duyệt giao dịch này ?"}>
                                        <CheckCircleFilled className={"text-purple-bright"}/>
                                    </Popconfirm>
                                }</span>
                            </div>
                        </th>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}