import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useSocket} from "../../_provider/SocketContext";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {getAllUserNotificationAction, pinNotificationAction, readNotificationAction} from "../../_api/NotificationApi";
import {Button, Dropdown} from "antd";
import {PushpinFilled, PushpinOutlined} from "@ant-design/icons";



const NotificationDrawer = () => {
    const [notifications, setNotifications] = useState([])
    const commonState = useSelector((state) => state.common)
    const socket = useSocket()
    const nav = useNavigate()
    const [limit, setLimit] = useState(20)
    useEffect(() => {
        if (!socket) return;
        if (!commonState.currentUser) return;

        const handleMessage = (message) => {
            toast(message?.payload.title, {
                onClick: () => {
                    nav(message?.payload?.url)
                }
            })
        };

        socket.emit('join', commonState.currentUser?.uuid);

        socket.on('global', handleMessage);

        return () => {
            socket.off('global', handleMessage);
        };
    }, [socket, commonState.currentUser]);
    useEffect(() => {
        getAllUserNotificationAction({
            limit: limit
        }).then((r) => {
            if (r?.success) {
                setNotifications(r?.data?.data)
            }
        })
    }, [limit])

    function handleReadNotification({id}) {
        readNotificationAction(id).then(r => {
            if (r?.success) {
                getAllUserNotificationAction({
                    limit: limit
                }).then((r) => {
                    if (r?.success) {
                        setNotifications(r?.data?.data)
                    }
                })
            }
        })
    }

    function handlePinNotification({id}) {
        pinNotificationAction(id).then(r => {
            if (r?.success) {
                getAllUserNotificationAction({
                    limit: limit
                }).then((r) => {
                    if (r?.success) {
                        setNotifications(r?.data?.data)
                    }
                })
            }
        })
    }

    return (
        <div>
            {notifications?.map((notify, key) =>
                <div className={"border-bottom p-1 "+(notify?.read === 0 ? "bg-shadow" : null)}>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <div
                            onClick={() => {
                                handleReadNotification({id: notify?.id})
                            }}
                            className={"cursor-pointer"}>
                            <div className={"fw-bold"}>{notify?.title}</div>
                            <div className={"text-muted"}>{notify?.description}</div>
                        </div>
                        <div
                            onClick={()=>{handlePinNotification({id:notify?.id})}}
                            className={"cursor-pointer"}>
                            {notify?.pin === 0 ?
                                <div><PushpinOutlined className={"h5 mb-0 text-purple-bright"}/></div> :
                                <div><PushpinFilled className={"h5 mb-0 text-purple-bright"}/></div>}
                        </div>
                    </div>
                </div>
            )}
            <div
                onClick={() => {
                    setLimit(limit + 20)
                }}
                className={"text-xs text-center text-purple-bright cursor-pointer mt-2"}>Xem thêm
            </div>
        </div>
    )
}

export default NotificationDrawer
