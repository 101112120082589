import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import InputFormComponent from "../../_components/InputFormComponent";
import {useMemo, useState} from "react";
import {getCommonFormRequestStruct} from "../../_api/CommonApi";
import Notification from "../../_classes/Notification";
import {useNavigate, useParams} from "react-router-dom";
import {BackwardFilled} from "@ant-design/icons";
import {getOneClassroomAction, updateClassroomAction} from "../../_api/ClassroomApi";
import {getOneSalaryConfigAction, updateSalaryConfigAction} from "../../_api/SalaryCriteriaConfig";

const UpdateSalaryConfigScreen = () => {
    const [formStruct, setFormStruct] = useState([])
    const [salaryConfig, setSalaryConfig] = useState({})
    const nav = useNavigate();
    const {id} = useParams();
    const [errorBag, setErrorBag] = useState([])

    useMemo(() => {
        getCommonFormRequestStruct('salary_config', id).then((r) => {
            setFormStruct(r.data)
        })
    }, [id]);

    useMemo(() => {
        if (id !== undefined) {
            getOneSalaryConfigAction(id).then((response) => {
                setSalaryConfig(response?.data)
            })
        }
    }, [id]);

    const handleUpdateSalaryConfig = () => {
        updateSalaryConfigAction(salaryConfig, id).then((r) => {
            if (r?.success) {
                Notification.success(r?.data?.message)
                nav(-1)
            } else {
                setErrorBag(r?.errors)
            }
        })
    }
    return (
        <AuthenticatedLayout>
            <div className={"h4 text-dark"}>
                <span onClick={() => {
                    nav(-1)
                }} className={"text-xs text-purple-bright cursor-pointer mx-2"}>
                <BackwardFilled/>
                Trở về
                </span>
                <span>Chỉnh sửa cấu hình lương</span>
            </div>
            <div className={"my-3 px-2"}>
                <div>
                    <span className={"fw-bold"}>Nhân sự :</span>
                    <span> {salaryConfig?.user?.name} - {salaryConfig?.user?.uuid} </span>
                </div>
                <div>
                    <span className={"fw-bold"}>Lớp học :</span>
                    <span> {salaryConfig?.classroom?.name} - {salaryConfig?.classroom?.uuid} </span>
                </div>
            </div>
            <div className={"my-4"}>
                <InputFormComponent
                    formStruct={formStruct}
                    form={salaryConfig}
                    setForm={setSalaryConfig}
                    errorBag={errorBag}
                />
            </div>
            <button onClick={handleUpdateSalaryConfig} className={"btn-purple-bright mt-2 p-2 px-3 mx-3"}>Cập nhật
            </button>
        </AuthenticatedLayout>
    )
}

export default UpdateSalaryConfigScreen