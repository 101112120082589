import UnAuthenticatedLayout from "../../_layouts/UnAuthenticatedLayout";
import app_logo from "../../_assets/images/logo-ngang.png";
import CustomLink from "../../_components/CustomLink";
import {Input} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {useState} from "react";
import {requestForgotPasswordAction} from "../../_api/CommonApi";
import Notification from "../../_classes/Notification";
import {useNavigate} from "react-router-dom";

const ForgotPasswordScreen = () => {
    const nav = useNavigate()
    const handleForgotPassword = () => {
        requestForgotPasswordAction(data).then(r => {
            if (r?.success) {
                Notification.success("Gửi thành công, hãy kiểm tra email của bạn")
                nav('/login')
            }else {
                setErrors(r?.errors)
            }
        })
    }
    const [errors, setErrors] = useState([])
    const [data, setData] = useState({})
    return (
        <UnAuthenticatedLayout>
            <div className={"bg-base d-flex justify-content-center align-items-center"} style={{height: '100vh'}}>
                <div className={"bg-white shadow-lg p-5"} style={{width: 400, borderRadius: 10}}>
                    <div className={"text-purple-bright fw-bold h3 m-0 text-center mb-3"}>
                        <img className={"mb-3"} src={app_logo} style={{width: "180px"}}/>
                    </div>
                    <div className={"mb-2"}>
                        <Input value={data?.username} onChange={(r) => setData({...data, username: r.target.value})}
                               prefix={<UserOutlined/>} placeholder={"Tên tài khoản"}
                               rootClassName={"p-1 px-2 mb-2"} status={errors?.username ? "error" : false}/>
                        {errors?.username &&
                            <div className={"mb-3 text-xs text-danger"}>{errors?.username}</div>
                        }
                    </div>
                    <button onClick={handleForgotPassword} className={"btn-purple-bright p-1 px-5 w-100 mb-2"}>Gửi email lấy lại mật khẩu</button>
                    <div className={"small text-purple-bright mb-2 cursor-pointer text-center"}>
                        <CustomLink to={"/login"}>Quay về đăng nhập</CustomLink>
                    </div>
                </div>
            </div>
        </UnAuthenticatedLayout>
    )
}
export default ForgotPasswordScreen