import {AntDesignOutlined, DeleteFilled, DeleteOutlined, PlusCircleOutlined, UserOutlined} from "@ant-design/icons";
import {Avatar, Divider, Drawer, Select, Tooltip} from "antd";
import {useState} from "react";
import {mapSelectData} from "../_classes/Helper";
import {useSelector} from "react-redux";
import {updateRoleAction} from "../_api/RoleApi";
import Notification from "../_classes/Notification";

const RoleUserGroupComponent = ({users, callback, roleId}) => {
    const [openDrawer, setOpenDrawer] = useState(false)
    return (
        <div className={"d-flex align-items-center mb-3"}>
            <div className={"me-2 fw-bold"}>Nhân sự :</div>
            <Avatar.Group
                max={{
                    count: 2,
                    style: {
                        color: '#f56a00',
                        backgroundColor: '#fde3cf',
                    },
                }}
            >
                {users?.map((user, key) =>
                    <Tooltip key={key} title={user?.name} placement="top">
                        <Avatar src={user?.avatar}/>
                    </Tooltip>
                )}
            </Avatar.Group>
            <RelatedUserDrawer users={users} callback={callback} open={openDrawer} setOpen={setOpenDrawer}
                               roleId={roleId}/>
            <PlusCircleOutlined onClick={() => setOpenDrawer(true)}
                                className={'h3 text-gray cursor-pointer mb-0 ms-2'}/>
        </div>
    )
}

export default RoleUserGroupComponent


const RelatedUserDrawer = ({open, setOpen, callback, users, roleId}) => {
    const commonState = useSelector((state) => state.common)
    const [selectedUser, setSelectedUser] = useState()
    const addRoleUser = () => {
        const data = {
            added_user_id: selectedUser
        }

        updateRoleAction(data, roleId).then((r) => {
            if (r?.success) {
                Notification.success(r?.data?.message)
                setSelectedUser(undefined)
                setOpen(false)
                callback()
            }
        })
    }

    const deleteUserRole = (id) => {
        const data = {
            removed_user_id: id
        }

        updateRoleAction(data, roleId).then((r) => {
            if (r?.success) {
                Notification.success(r?.data?.message)
                setOpen(false)
                callback()
            }
        })
    }
    return (
        <Drawer
            closable
            destroyOnClose
            title={"Người dùng"}
            placement="right"
            open={open}
            loading={false}
            onClose={() => {
                setOpen(false)
            }}>
            <div>
                <div className={"fw-bold mb-1"}>Chọn người dùng</div>
                <Select
                    showSearch

                    filterOption={(input, option) =>
                        (option?.label.toLowerCase() ?? '').toLowerCase().includes(input.toLowerCase())
                    }

                    allowClear
                    className={"w-100"}
                    size={"large"}
                    value={selectedUser}

                    onChange={(r) => {
                        setSelectedUser(r)
                    }}

                    options={mapSelectData({
                        key: 'id',
                        label: "uuid",
                        prefix: 'name',
                        data: commonState?.users || []
                    })}
                />
                <div onClick={addRoleUser}
                     className={"my-2 rounded text-center bg-purple-bright text-white p-2 cursor-pointer"}>Thêm
                </div>
                <div className={"my-2"}>
                    <div className={"fw-bold mt-3 mb-1"}>Danh sách người dùng thuộc nhóm quyền</div>
                    {users?.map((user, key) =>
                        <div key={key} className={"p-2 border d-flex align-items-center justify-content-between"}>
                            <div className={"d-flex align-items-center"}>
                                <Avatar src={user?.avatar}/>
                                <div className={"fw-bold ms-2"}>{user?.name}</div>
                            </div>
                            <div>
                                <DeleteFilled
                                    onClick={()=>{
                                        deleteUserRole(user?.id)
                                    }}
                                    className={"h5 mb-0 cursor-pointer text-gray"}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Drawer>
    )
}