import DataTableComponent from "../../_components/DataTableComponent";
import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useEffect, useMemo, useState} from "react";
import FilteringComponent from "../../_components/Columns/FilteringComponent";
import {Button} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {handleCreateUrlParams, handleParseUrlParams} from "../../_classes/Helper";
import {getAllStudentsAction} from "../../_api/StudentApi";
import {getCommonColumnRequestStruct} from "../../_api/CommonApi";
import {getAllStudyLogsAction} from "../../_api/StudyLogApi";
import {commonAction} from "../../_provider/_slices/CommonSlice";
import {useDispatch} from "react-redux";

const ListStudentScreen = () => {
    const location = useLocation();
    const [studentCollection, setStudentCollection] = useState({})
    const nav = useNavigate()
    const [query, setQuery] = useState()
    const [columnStruct, setColumnStruct] = useState([]);
    const filteringStructs = [
        {
            field: "title",
            type: "text",
            label: "Tiêu đề buổi học"
        },
        {
            field: "uuid",
            type: "text",
            label: "Id Buổi học"
        },
        {
            field: "phone",
            type: "text",
            label: "Số điện thoại",
        },
        {
            field: "email",
            type: "text",
            label: "Email",
        },
    ]
    const dispatch = useDispatch()
    useEffect(() => {
        if (query) {
            const urlPath = handleCreateUrlParams(query)
            nav(urlPath)
            dispatch(commonAction({loadingState: true}))
            getAllStudyLogsAction(query).then((response) => {
                dispatch(commonAction({loadingState: false}))
                setStudentCollection(response?.data)
            }).catch(() => {
                dispatch(commonAction({loadingState: false}))
            })
        }
    }, [query]);

    useEffect(() => {
        const searchParams = location.search.substring(1);
        const parseParams = handleParseUrlParams(searchParams)

        setQuery({
            fields: "id," +
                "uuid," +
                "created_by," +
                "classroom_id," +
                "title," +
                "content," +
                "image," +
                "video," +
                "audio," +
                "file," +
                "link," +
                "status," +
                "created_at," +
                "user_abilities," +
                "creator," +
                "classroom," +
                "studylog_day," +
                "status_title," +
                "status_color," +
                "related_users," +
                "statistics",
            limit: parseParams?.limit ?? 20,
            order: parseParams?.order,
            direction: parseParams?.direction,
            filtering: parseParams?.filtering || null,
            offset: parseParams?.offset || 0
        })
    }, [])

    useMemo(() => {
        getCommonColumnRequestStruct('studylog').then((r) => {
            if (r?.success) {
                setColumnStruct(r?.data)
            }
        })
    }, [])
    return (
        <AuthenticatedLayout>
            <div className={"h4 text-purple-bright"}>Danh sách buổi học</div>
            <div className={"d-flex justify-content-between my-4"}>
                <FilteringComponent
                    filteringStructs={filteringStructs}
                    query={query}
                    setQuery={setQuery}
                />
                <Button onClick={() => nav('/studylog/create')} className={'btn-purple-bright'}>Thêm mới</Button>
            </div>
            <div>
                <DataTableComponent
                    columnStructs={columnStruct}
                    collection={studentCollection}
                    query={query}
                    setQuery={setQuery}
                    setCollection={setStudentCollection}
                />
            </div>
        </AuthenticatedLayout>
    )
}

export default ListStudentScreen