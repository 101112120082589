import {useState} from "react";
import {Input} from "antd";
import {BranchesOutlined} from "@ant-design/icons";
import AvatarField from "./Fields/AvatarField";
import {createBranchAction} from "../_api/BranchApi";

const CreateNewBranch = ({callback}) => {
    const [data, setData] = useState({})
    const [errors, setErrors] = useState([])
    const handleCreateBranch = () => {
        createBranchAction(data).then((r) => {
            if (r?.success) {
                if (callback) {
                    callback(true)
                }
            } else {
                setErrors(r?.errors)
            }
        })
    }
    return (
        <div className={"d-flex justify-content-center align-items-center h-full p-3"}>
            <div className={"bg-white shadow-lg p-3 rounded"} style={{minWidth: 500}}>
                <div className={"fw-bold text-purple-bright text-center h4"}>Hãy khởi tạo chi nhánh đầu tiên của bạn
                </div>
                <div className={"text-center my-3"}>
                    <AvatarField
                        placeholder={'Ảnh chi nhánh'}
                        value={data?.logo}
                        setValue={(r) => setData({...data, logo: r})}
                    />
                    {errors?.logo &&
                        <div className={"text-xs text-danger"}>{errors?.logo}</div>
                    }
                </div>
                <div className={"my-2 px-3"}>
                    <div>
                        <Input
                            value={data?.name} onChange={(r) => setData({...data, name: r.target.value})}
                            status={errors?.name ? "error" : false} prefix={<BranchesOutlined/>}
                            placeholder={'Tên chi nhánh'} rootClassName={"mb-3"}/>
                        {errors?.name &&
                            <div className={"text-xs text-danger mb-3"}>{errors?.name}</div>
                        }
                    </div>
                    <button onClick={handleCreateBranch} className={"btn-purple-bright w-100 p-1"}>Tạo chi nhánh mới
                    </button>
                </div>
            </div>
        </div>
    )
}
export default CreateNewBranch