import {Menu} from "antd";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {
    BilibiliOutlined,
    CreditCardOutlined,
    DashboardOutlined, HistoryOutlined,
    OrderedListOutlined, SettingOutlined,
    UserAddOutlined,
    UserSwitchOutlined
} from "@ant-design/icons";

const MenuComponent = ({onClick, collapsed}) => {
    const permissions = useSelector((state) => state.common).permissions
    const currentUser = useSelector((state) => state.common).currentUser

    const menuItems = [
        {
            key: '',
            icon: <DashboardOutlined/>,
            label: 'Bảng điều khiển',
            visible: 1
        },
        {
            key: 'users',
            label: 'Nhân sự',
            icon: <UserSwitchOutlined/>,
            visible: (permissions?.ACCESS_SUPPORTER || permissions?.ACCESS_TEACHER || permissions?.ACCESS_STAFF),
            children: [
                {
                    key: 'staff',
                    label: 'Nhân viên',
                    visible: permissions?.ACCESS_STAFF
                },
                {
                    key: 'teacher',
                    label: 'Giáo viên',
                    visible: permissions?.ACCESS_TEACHER
                },
                {
                    key: 'supporter',
                    label: 'Trợ giảng',
                    visible: permissions?.ACCESS_SUPPORTER
                },
            ],
        },
        {
            key: 'student',
            icon: <UserAddOutlined/>,
            label: 'Học sinh',
            visible: permissions?.ACCESS_STUDENT
        },
        {
            key: 'card',
            icon: <CreditCardOutlined/>,
            label: 'Thẻ học',
            visible: permissions?.ACCESS_CARD
        },
        {
            key: 'classroom',
            icon: <OrderedListOutlined/>,
            label: 'Lớp học',
            visible: permissions?.ACCESS_CLASSROOM
        },
        {
            key: 'studylog',
            icon: <HistoryOutlined/>,
            label: 'Buổi học',
            visible: permissions?.ACCESS_STUDYLOG
        },
        {
            key: 'salary',
            icon: <CreditCardOutlined />,
            label: 'Lương',
            visible: permissions?.ACCESS_SALARY,
            children: [
                {
                    key: 'salary_config',
                    label: 'Cấu hình lương',
                    visible: permissions?.ACCESS_SALARY,
                },
                {
                    key: 'salary_snap',
                    label: 'Thẻ lương',
                    visible: permissions?.ACCESS_SALARY,
                },
                {
                    key: 'salary_sheet',
                    label: 'Phiếu lương',
                    visible: permissions?.ACCESS_SALARY,
                },
            ],
        },
        {
            key: 'settings/role',
            icon: <SettingOutlined/>,
            label: 'Cài đặt',
            visible: currentUser?.role === 1
        },
    ].filter(child => child.visible)
    return (
        <Menu
            onClick={onClick}
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            inlineCollapsed={collapsed}
            items={menuItems
                .filter(item => item.visible) // Lọc parent dựa trên visible
                .map(item => ({
                    ...item,
                    children: item.children?.filter(child => child.visible), // Lọc children dựa trên visible
                }))
            }
            className={"menu-fit"}
        />
    )
}
export default MenuComponent