import {UploadOutlined} from "@ant-design/icons";
import {Button, Upload} from "antd";
import Notification from "../../_classes/Notification";
import {getUploadUrl} from "../../_classes/Helper";

const FileField = ({value, setValue, placeholder, multiple = false}) => {
    const props = {
        name: 'file',
        action: getUploadUrl,
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                Notification.success('Thành công');
                setValue(info.file?.response?.file)
            } else if (info.file.status === 'error') {
                Notification.error(`Tải lên thất bại`);
            }
        },
        maxCount: multiple ? 100 : 1,
    };


    return (
        <div className={"w-100 file_field"}>
            <Upload {...props}>
                <Button size={"large"} className={"w-100"} icon={<UploadOutlined/>}>
                    <span className={"text-xs"}>{value?.slice(-15) || placeholder}</span>
                </Button>
            </Upload>
        </div>
    )
}
export default FileField