import {useNavigate} from "react-router-dom";

const UserInfoColumnComponent = ({id, record}) => {
    const nav = useNavigate()
    const handleEdit = () => {
        nav(`/${record?.user_abilities?.entity}/edit/${record?.id}`)
    }
    return (
        <div onClick={handleEdit} className={"d-flex align-items-center"}>
            <div className={"rounded-circle me-2"}>
                <img alt={"pink"}
                     src={record?.student?.avatar} style={{width: "2.5rem"}} className={"rounded-circle cursor-pointer"}/>
            </div>
            <div className={"cursor-pointer"}>
                <div className={"fw-semi text-purple"}>{record?.student?.name}</div>
                <div className={"text-xs text-purple"}>Mã HS: {record?.student?.uuid}</div>
                <div className={"small text-purple"}>{record?.uuid}</div>
            </div>
        </div>
    )
}

export default UserInfoColumnComponent