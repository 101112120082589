import DataTableComponent from "../../_components/DataTableComponent";
import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useEffect, useState} from "react";
import FilteringComponent from "../../_components/Columns/FilteringComponent";
import {Button} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {handleCreateUrlParams, handleParseUrlParams} from "../../_classes/Helper";
import {getAllTeachersAction} from "../../_api/TeacherApi";

const ListTeachersScreen = () => {
    const location = useLocation();
    const [teacherCollection, setTeacherCollection] = useState({})
    const nav = useNavigate()
    const [query, setQuery] = useState()
    const columnStruct = [
        {
            title: 'Giáo viên',
            dataIndex: 'id',
            key: 'id',
            type: "user_info",
            fixed: 'left'
        },
        {
            title: 'Phân loại',
            dataIndex: 'teacher_source',
            key: 'teacher_source',
            type: 'teacher_source'
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email giáo viên',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Truy cập lần cuối',
            dataIndex: 'last_active',
            key: 'last_active',
        },
        {
            title: 'Truy cập lần cuối',
            dataIndex: 'last_active',
            key: 'last_active',
        },
        {
            title: 'Truy cập lần cuối',
            dataIndex: 'last_active',
            key: 'last_active',
        },
        {
            title: 'Truy cập lần cuối',
            dataIndex: 'last_active',
            key: 'last_active',
        },
        {
            title: 'Hành động',
            dataIndex: 'user_abilities',
            key: 'user_abilities',
            type: "user_abilities",
            width: 150
        }
    ];
    const filteringStructs = [
        {
            field: "name",
            type: "text",
            label: "Tên giáo viên"
        },
        {
            field: "phone",
            type: "text",
            label: "Số điện thoại",
        },
        {
            field: "email",
            type: "text",
            label: "Email",
        },
        {
            field: "teacher_source",
            type: "select",
            label: "Nguồn giáo viên",
            options: [
                {
                    value: 1,
                    label: "Giáo viên Việt Nam",
                },
                {
                    value: 2,
                    label: "Giáo viên nước ngoài"
                }
            ]
        }
    ]

    useEffect(() => {
        if (query) {
            const urlPath = handleCreateUrlParams(query)
            nav(urlPath)
            getAllTeachersAction(query).then((response) => {
                setTeacherCollection(response?.data)
            }).catch()
        }
    }, [query]);

    useEffect(() => {
        const searchParams = location.search.substring(1);
        const parseParams = handleParseUrlParams(searchParams)

        setQuery({
            fields: "id,uuid,name,phone,email,last_active,user_abilities,avatar,teacher_source,teacher_source_color",
            limit: parseParams?.limit ?? 20,
            order: parseParams?.order,
            direction: parseParams?.direction,
            filtering: parseParams?.filtering || null,
            offset: parseParams?.offset || 0
        })
    }, [])

    return (
        <AuthenticatedLayout>
            <div className={"h4 text-purple-bright"}>Danh sách giáo viên</div>
            <div className={"d-flex justify-content-between my-4"}>
                <FilteringComponent
                    filteringStructs={filteringStructs}
                    query={query}
                    setQuery={setQuery}
                />
                <Button onClick={() => nav('/teacher/create')} className={'btn-purple-bright'}>Thêm mới</Button>
            </div>
            <div>
                <DataTableComponent
                    columnStructs={columnStruct}
                    collection={teacherCollection}
                    query={query}
                    setQuery={setQuery}
                    setCollection={setTeacherCollection}
                />
            </div>
        </AuthenticatedLayout>
    )
}

export default ListTeachersScreen