import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import TestScreen from "../_screens/TestScreen";
import HomeScreen from "../_screens/HomeScreen";
import ListStaffsScreen from "../_screens/staff/ListStaffsScreen";
import CreateStaffsScreen from "../_screens/staff/CreateStaffsScreen";
import UpdateStaffsScreen from "../_screens/staff/UpdateStaffsScreen";
import ListTeachersScreen from "../_screens/teacher/ListTeachersScreen";
import LoginScreen from "../_screens/authenticate/LoginScreen";
import RegisterScreen from "../_screens/authenticate/RegisterScreen";
import BranchScreen from "../_screens/branch/BranchScreen";
import CreateTeacherScreen from "../_screens/teacher/CreateTeacherScreen";
import UpdateTeacherScreen from "../_screens/teacher/UpdateTeacherScreen";
import ListSupporterScreen from "../_screens/supporter/ListSupporterScreen";
import CreateSupporterScreen from "../_screens/supporter/CreateSupporterScreen";
import NotFoundScreen from "../_screens/errors/NotFoundScreen";
import UpdateSupporterScreen from "../_screens/supporter/UpdateSupporterScreen";
import ListStudentScreen from "../_screens/student/ListStudentScreen";
import CreateStudentScreen from "../_screens/student/CreateStudentScreen";
import UpdateStudentScreen from "../_screens/student/UpdateStudentScreen";
import ListCardScreen from "../_screens/card/ListCardScreen";
import CreateCardScreen from "../_screens/card/CreateCardScreen";
import UpdateCardScreen from "../_screens/card/UpdateCardScreen";
import ListClassroomScreen from "../_screens/classroom/ListClassroomScreen";
import CreateClassroomScreen from "../_screens/classroom/CreateClassroomScreen";
import UpdateClassroomScreen from "../_screens/classroom/UpdateStudentScreen";
import ListStudyLogScreen from "../_screens/studylog/ListStudyLogScreen";
import CreateStudylogScreen from "../_screens/studylog/CreateStudylogScreen";
import QuickEditStudyLogScreen from "../_screens/studylog/QuickEditStudyLogScreen";
import ForbiddenScreen from "../_screens/errors/ForbiddenScreen";
import RoleSettingScreen from "../_screens/settings/RoleSettingScreen";
import IntegrationSettingScreen from "../_screens/settings/IntegrationSettingScreen";
import ListSalaryCriteriaScreen from "../_screens/salary_criteria/ListSalaryCriteriaScreen";
import CreateSalaryCriteriaScreen from "../_screens/salary_criteria/CreateSalaryCriteriaScreen";
import ListSalaryConfigScreen from "../_screens/salary_config/ListSalaryConfigScreen";
import UpdateSalaryConfigScreen from "../_screens/salary_config/UpdateSalaryConfigScreen";
import ListSalarySnapScreen from "../_screens/salary_snap/ListSalarySnapScreen";
import ShowSalarySnapScreen from "../_screens/salary_snap/ShowSalarySnapScreen";
import ShowCardScreen from "../_screens/card/ShowCardScreen";
import ListSalarySheetScreen from "../_screens/salary_sheet/ListSalarySheetScreen";
import CurrentUserInfoScreen from "../_screens/current_user/CurrentUserInfoScreen";
import EditCurrentUserInfoScreen from "../_screens/current_user/EditCurrentUserInfoScreen";
import ForgotPasswordScreen from "../_screens/authenticate/ForgotPasswordScreen";
import ResetPasswordScreen from "../_screens/authenticate/ResetPasswordScreen";

const MainRoute = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomeScreen/>}/>

                <Route path="/staff" element={<ListStaffsScreen/>}/>
                <Route path="/staff/create" element={<CreateStaffsScreen/>}/>
                <Route path="/staff/edit/:id" element={<UpdateStaffsScreen/>}/>


                <Route path={'/teacher'} element={<ListTeachersScreen/>}/>
                <Route path={'/teacher/create'} element={<CreateTeacherScreen/>}/>
                <Route path={'/teacher/edit/:id'} element={<UpdateTeacherScreen/>}/>

                <Route path={'/supporter'} element={<ListSupporterScreen/>}/>
                <Route path={'/supporter/create'} element={<CreateSupporterScreen/>}/>
                <Route path={'/supporter/edit/:id'} element={<UpdateSupporterScreen/>}/>

                <Route path={'/student'} element={<ListStudentScreen/>}/>
                <Route path={'/student/create'} element={<CreateStudentScreen/>}/>
                <Route path={'/student/edit/:id'} element={<UpdateStudentScreen/>}/>

                <Route path={'/card'} element={<ListCardScreen/>}/>
                <Route path={'/card/create'} element={<CreateCardScreen/>}/>
                <Route path={'/card/edit/:id'} element={<UpdateCardScreen/>}/>
                <Route path={'/card/show/:id'} element={<ShowCardScreen/>}/>

                <Route path={'/classroom'} element={<ListClassroomScreen/>}/>
                <Route path={'/classroom/create'} element={<CreateClassroomScreen/>}/>
                <Route path={'/classroom/edit/:id'} element={<UpdateClassroomScreen/>}/>

                <Route path={'/studylog'} element={<ListStudyLogScreen/>}/>
                <Route path={'/studylog/create'} element={<CreateStudylogScreen/>}/>
                <Route path={'/studylog/quick_edit/:id'} element={<QuickEditStudyLogScreen/>}/>

                <Route path={'settings/salary_criteria'} element={<ListSalaryCriteriaScreen/>}/>
                <Route path={'salary_criteria/create'} element={<CreateSalaryCriteriaScreen/>}/>

                <Route path={'salary_config'} element={<ListSalaryConfigScreen/>}/>
                <Route path={'salary_config/edit/:id'} element={<UpdateSalaryConfigScreen/>}/>

                <Route path={'salary_snap'} element={<ListSalarySnapScreen/>}/>
                <Route path={'salary_snap/show/:id'} element={<ShowSalarySnapScreen/>}/>

                <Route path={'salary_sheet'} element={<ListSalarySheetScreen/>}/>

                <Route path={'/branches'} element={<BranchScreen/>}/>

                <Route path={"/settings/role"} element={<RoleSettingScreen/>}/>
                <Route path={"/settings/integration"} element={<IntegrationSettingScreen/>}/>

                <Route path={"/user"} element={<CurrentUserInfoScreen/>}/>
                <Route path={"/user/edit"} element={<EditCurrentUserInfoScreen/>}/>

                <Route path={"/login"} element={<LoginScreen/>}/>
                <Route path={"/forgot_password"} element={<ForgotPasswordScreen/>}/>
                <Route path={"/password_reset/:token"} element={<ResetPasswordScreen/>}/>
                <Route path={"/register"} element={<RegisterScreen/>}/>

                <Route path="/forbidden" element={<ForbiddenScreen/>}/>
                <Route path="*" element={<NotFoundScreen/>}/>
            </Routes>
        </Router>
    )
}

export default MainRoute