import Paragraph from "antd/es/skeleton/Paragraph";
import {useState} from "react";
import {CommentOutlined, HeartOutlined, ShareAltOutlined} from "@ant-design/icons";

const HomeUserPosts = () => {
    return (
        <div style={{maxHeight: "80vh", overflowY: "scroll"}}>
            <HomeUserPost/>
        </div>
    )
}

export default HomeUserPosts

const HomeUserPost = () => {
    const [show, setShow] = useState(false);
    return (
        <div className={"bg-white p-2 rounded shadow-sm mb-3"}>
            <div className={"author"}>
                <div className={"d-flex"}>
                    <img
                        className={"rounded-circle border"}
                        style={{width: '2.5rem'}}
                        src={"https://img.freepik.com/premium-vector/collection-people-character-illustration-group-young-people-avatars-vector-illustration_1063541-1606.jpg"}
                        alt={"hi"}/>
                    <div className={"ms-2"}>
                        <div className={"fw-bold"}>BeLi English Academy</div>
                        <div className={"fw-bold text-muted text-xs"}>Admin - 19:12 24/09/2024</div>
                    </div>
                </div>
            </div>
            <div className={"text-content my-2"}>
                <div style={{height: (show === true ? "auto" : "12rem")}} className={"overflow-hidden"}
                     dangerouslySetInnerHTML={{
                         __html: `<h5 dir="auto">[Bản cập nhật U.003.0065]</h5>
<div dir="auto"><strong>1. Dự kiến cập nhậ</strong>t : 22:00 Ng&agrave;y <em><strong>29/09/2024</strong></em></div>
<div dir="auto"><strong>2. T&iacute;nh năng cập nhật</strong> :</div>
<div dir="auto">- Bổ sung m&agrave;n h&igrave;nh import nh&acirc;n sự, gi&aacute;o vi&ecirc;n, học sinh, lớp học</div>
<div dir="auto">- Bổ sung m&agrave;n h&igrave;nh sổ quỹ, ph&acirc;n quyền sổ quỹ</div>
<div dir="auto">- Tính năng tích hợp google drive cá nhân</div>
<div dir="auto">- Sửa lỗi theo phản hồi của người dùng</div>
<div dir="auto"><strong>3. Lưu &yacute;:</strong></div>
<div dir="auto">- Trong thời gian cập nhật (22:00 - 23:00) , user hạn chế sử dụng c&aacute;c t&iacute;nh năng b&igrave;nh luận tr&aacute;nh xảy ra lỗi.</div>
<div dir="auto">Nếu có bất kì câu hỏi nào, xin vui lòng gửi ticket tại: <a href="https://forms.gle/kMLSg5u98KpVjq1g7">https://forms.gle/kMLSg5u98KpVjq1g7</a></div>
<div dir="auto" class="fw-bold mt-2">Bsmlight xin cảm ơn !</div>`
                     }}></div>

                {
                    show === false &&
                    <div onClick={() => setShow(true)} className={"my-2 cursor-pointer text-purple-bright"}>...Xem thêm
                    </div>
                }
                {
                    show === true &&
                    <div onClick={() => setShow(false)} className={"my-2 cursor-pointer text-purple-bright"}>...Ẩn
                    </div>
                }
            </div>
            <div className={"single-image pb-3"}>
                <img
                    alt={""}
                    className={"w-100 rounded"}
                    src={"https://computingaustralia.com.au/wp-content/uploads/2021/02/Why-Software-Updates-and-Patches-are-Essential-Banner.jpg"}/>
            </div>

            <div className={"action d-flex justify-content-between my-2 border-top border-bottom p-2"}>
                <div className={"react text-purple-bright cursor-pointer"}>
                    <HeartOutlined/> Thích
                </div>
                <div className={"react text-purple-bright cursor-pointer"}>
                    <CommentOutlined/> Bình luận
                </div>
                <div className={"react text-purple-bright cursor-pointer"}>
                    <ShareAltOutlined/> Chia sẻ
                </div>
            </div>
            {/*<div className={"comment my-2"}>*/}
            {/*    <div className={"mb-2"}>*/}
            {/*        <UserComment/>*/}
            {/*    </div>*/}
            {/*    <div className={"mb-2"}>*/}
            {/*        <UserComment child={true}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

const UserComment = ({child = false}) => {
    return (
        <div>
            <div className={"comment d-flex align-items-start"}>
                <img
                    src={"https://scontent.fhan3-5.fna.fbcdn.net/v/t39.30808-6/450127338_122119263722327539_8158797958100350466_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=a5f93a&_nc_eui2=AeE1tI5JCi_lVv4-6hvwXZ5yPr289rZh_0g-vbz2tmH_SO-sEtdsQima-Hh2aQjTcS0jyUMXbKQupUS7rK76K_11&_nc_ohc=l396BQqLcZMQ7kNvgEhn92J&_nc_zt=23&_nc_ht=scontent.fhan3-5.fna&cb_e2o_trans=q&oh=00_AYDLi_pFLTJLeN-DhDmfaIYH3zkQYoxkEDHw2Hu6QwxpBQ&oe=66E1D728"}
                    className={"rounded-circle"} style={{width: '2rem'}} alt={"comment_user"}/>
                <div className={"ms-2 rounded bg-base p-2 w-100"}>
                    <div className={"fw-bold mb-1"}>Phạm Quang Linh</div>
                    <div>
                        Quá tuyệt vời ạ !!
                    </div>
                </div>
            </div>
            <div className={"my-2"}>
                {child &&
                    <div className={"ms-5"}>
                        <UserComment/>
                    </div>
                }
            </div>
        </div>
    )
}