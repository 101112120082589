import {Table, Tag} from "antd";
import {useEffect} from "react";

const ListItemsColumnComponent = ({lists}) => {
    useEffect(() => {
        console.log(lists)
    }, [lists]);
    return (
        <>
            {lists?.map((item) => {
                let color = item.length > 5 ? 'geekblue' : 'green';
                return (
                    <Tag color={color} key={item}>
                        {item.toUpperCase()}
                    </Tag>
                )
            })}
        </>
    )
}

export default ListItemsColumnComponent