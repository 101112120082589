import {useNavigate} from "react-router-dom";

const StudyLogInfoColumnComponent = ({id, record}) => {
    const nav = useNavigate()
    const handleEdit = () => {
        nav(`/studylog/quick_edit/${record?.id}`)
    }
    return (
        <div onClick={handleEdit} className={"d-flex align-items-center"}>
            <div className={"cursor-pointer text-start"}>
                <div className={"text-purple mb-1 fw-bold"}>{record?.uuid}</div>
                <div className={[record?.status_color, 'fw-bold'].join(' ')}>{record?.status_title}</div>
            </div>
        </div>
    )
}

export default StudyLogInfoColumnComponent