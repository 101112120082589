import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllTeachersAction = async (query) => {
    return await (new Api()).get('teachers', query, getAuthHeader());
}

export const getAllSharedTeachersAction = async (query) => {
    return await (new Api()).get('teachers/s', query, getAuthHeader());
}

export const createTeacherAction = async (data) => {
    return await (new Api()).post('teachers', data, getAuthHeader())
}

export const getOneTeacherAction = async (id) => {
    return await (new Api()).get(`teachers/${id}`, {}, getAuthHeader())
}

export const updateTeacherAction = async (data,id) => {
    return await (new Api()).put(`teachers/${id}`, data, getAuthHeader())
}
