import {Tooltip} from "antd";

const RenewColumn = ({value, record}) => {
    return (
        <Tooltip title={record?.renew_tooltip}>
            <div className={"cursor-pointer text-center text-white rounded fw-bold " + (record?.renew_color)}>

                <span>{record?.renew_type}</span>

            </div>
        </Tooltip>
    )
}

export default RenewColumn