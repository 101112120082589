import {useEffect, useState} from "react";
import {getAllSharedCardsAction} from "../_api/CardApi";
import {Drawer, Select, Space, Switch} from "antd";
import {styled} from "@mui/material";
import {CheckCircleOutlined, CheckOutlined, CloseOutlined, EditOutlined} from "@ant-design/icons";
import {_card_log_status} from "../_statics/_menu";
import TextArea from "antd/lib/input/TextArea";
import EditCardDrawer from "./Drawers/EditCardDrawer";

const StudyCardLogsFormComponent = ({
                                        data,
                                        setData,
                                        errorBags,
                                        onEditFields,
                                        setOnEditfields,
                                        onInEdit,
                                        canEdit,
                                        handleQuickUpdate,
                                        quickEditMode,
                                    }) => {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [drawerCardId, setDrawerCardId] = useState(undefined)
    const quickEditCard = ({id}) => {
        setDrawerCardId(id)
        setOpenDrawer(true)
    }

    useEffect(() => {
        if (!quickEditMode) {
            getAllSharedCardsAction({
                filtering: {
                    "classroom_id": data?.classroom_id
                },
                fields: "id,uuid,van,student_id,classroom_id,original_days,bonus_days,original_fee,promotion_fee,paid_fee," +
                    "allow_deg,limit_deg,attended_days,total_days,total_fee,can_use_day_by_paid,unpaid_fee,can_use_day,can_use_fee," +
                    "user_abilities,van_and_attended_days,student,allow_deg,can_attendance",
                limit: 10000
            }).then((r) => {
                if (r?.success) {
                    setData({
                        ...data,
                        working_shifts: data?.working_shifts,
                        card_logs: r?.data?.data.map((card) => {
                            return {
                                card_id: card?.id,
                                uuid: card?.uuid,
                                student_id: card?.student_id,
                                student: card?.student,
                                day: 1,
                                status: 0,
                                reason: '',
                                teacher_note: '',
                                can_attendance: card?.can_attendance,
                                can_use_day: card?.can_use_day,
                                limit_deg: card?.limit_deg,
                                van_and_attended_days: card?.van_and_attended_days,
                                can_use_day_by_paid: card?.can_use_day_by_paid,
                                user_abilities: card?.user_abilities
                            }
                        })
                    })
                }
            })

        }
    }, []);
    const updateCardLog = ({card_id, key, value}) => {
        const newCardLogs = data?.card_logs.map((card_log) => {
            if (card_log?.card_id === card_id) {
                return {...card_log, [key]: value}
            }

            return card_log
        })

        setData({
            ...data,
            card_logs: newCardLogs
        })
    }
    const CustomSwitch = styled(Switch)`
        &.ant-switch-checked {
            background-color: #9055fd;
        }

        &.ant-switch-checked:hover {
            background-color: #9055fd;
        }
    `;
    return (
        <div>
            {data?.card_logs?.filter(v => v?.can_attendance === 1).length >= 1 && canEdit === 1  &&
                <div className={"fw-bold mb-1"}>Thẻ học được điểm danh</div>
            }
            {data?.card_logs?.filter(v => v?.can_attendance === 1).map((card_log, key) =>
                <div className={"bg-shadow rounded p-2 py-3"} key={key}>
                    <div className={"row"}>
                        <div className={"col-lg-2 col-4"}>
                            <div className={"p-2 text-center bg-white rounded h-100"}>
                                <img alt={"avt"} src={card_log?.student?.avatar}
                                     className={"w-75 rounded-circle border border-white border-5"}/>
                                <div
                                    className={"h6 fw-bold text-purple-bright my-2 text-center"}>{card_log?.student?.name}</div>
                                {card_log?.user_abilities?.can_edit === 1 &&
                                    <div onClick={() => {
                                        quickEditCard({id: card_log?.card_id})
                                    }} className={"my-1 text-xs text-purple-bright cursor-pointer"}>
                                        <EditOutlined title={"Sửa nhanh thông tin"}/>
                                        <span className={"ms-1"}>Sửa nhanh thông tin</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={"col-lg-4"}>
                            <div className={"bg-white p-2 rounded h-100"}>
                                <div className={"mb-1"}>
                                    <span className={"me-1"}>Mã thẻ học:</span>
                                    <span className={"fw-bold text-purple-bright"}>{card_log?.uuid}</span>
                                </div>
                                <div className={"mb-1"}>
                                    <span className={"me-1"}>Số buổi đã dùng và VAN:</span>
                                    <span
                                        className={"fw-bold text-success"}>{card_log?.van_and_attended_days}</span>
                                </div>
                                <div className={"mb-1"}>
                                    <span className={"me-1"}>Số buổi còn lại có thể dùng:</span>
                                    <span className={"fw-bold text-danger"}>{card_log?.can_use_day.toLocaleString(undefined, {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2
                                    })}</span>
                                </div>
                                {card_log?.limit_deg > 0 &&
                                    <div className={"mb-1"}>
                                    <span
                                        className={"me-1 text-danger small"}>Thẻ học này được phép điểm danh âm {card_log?.limit_deg} buổi</span>
                                    </div>
                                }
                                <div className={"mb-2"}>
                                    <div className={"mb-1 fw-bold"}>Trạng thái buổi học
                                        {quickEditMode && !onInEdit({field: `card_log.${card_log?.card_id}.status`}) && canEdit === 1  &&
                                            <span onClick={() => {
                                                setOnEditfields([...onEditFields, `card_log.${card_log?.card_id}.status`])
                                            }}
                                                  className={"cursor-pointer ms-2 text-purple-bright"}>
                                                                    <EditOutlined/>Sửa</span>
                                        }
                                        {onInEdit({field: `card_log.${card_log?.card_id}.status`}) &&
                                            <span onClick={handleQuickUpdate}
                                                  className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}><CheckCircleOutlined
                                                className={"me-2"}/>Cập nhật
                                                                    </span>
                                        }
                                    </div>
                                    <Select
                                        disabled={quickEditMode && !onInEdit({field: `card_log.${card_log?.card_id}.status`})}
                                        placeholder={"Trạng thái buổi học"}
                                        onChange={(r) => {
                                            updateCardLog({
                                                card_id: card_log?.card_id,
                                                key: "status",
                                                value: r
                                            })
                                        }}
                                        value={card_log?.status} size={"large"}
                                        options={_card_log_status} className={"w-100"}/>
                                </div>
                                <div className={"my-2"}>
                                    <Space direction="horizontal">
                                        <CustomSwitch
                                            disabled={quickEditMode && !onInEdit({field: `card_log.${card_log?.card_id}.day`})}
                                            onChange={() => {
                                                updateCardLog({
                                                    card_id: card_log?.card_id,
                                                    key: "day",
                                                    value: card_log?.day === 1 ? 0 : 1
                                                })
                                            }}
                                            size={"default"}
                                            checkedChildren={<CheckOutlined/>}
                                            unCheckedChildren={<CloseOutlined/>}
                                            checked={card_log?.day === 1}
                                        />
                                        <div
                                            className={"fw-bold"}>{card_log?.day === 1 ? "Trừ buổi học" : "Không trừ buổi học"}
                                            {quickEditMode && !onInEdit({field: `card_log.${card_log?.card_id}.day`}) && canEdit === 1  &&
                                                <span onClick={() => {
                                                    setOnEditfields([...onEditFields, `card_log.${card_log?.card_id}.day`])
                                                }}
                                                      className={"cursor-pointer ms-2 text-purple-bright"}>
                                                                    <EditOutlined/>Sửa</span>
                                            }
                                            {onInEdit({field: `card_log.${card_log?.card_id}.day`}) &&
                                                <span onClick={handleQuickUpdate}
                                                      className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}><CheckCircleOutlined
                                                    className={"me-2"}/>Cập nhật
                                                                    </span>
                                            }
                                        </div>
                                    </Space>
                                </div>
                            </div>
                        </div>
                        <div className={"col-lg-6"}>
                            <div className={"bg-white p-2 h-100 rounded"}>
                                <div className={"fw-bold mb-1"}>Lời nhắn cho phụ huynh học sinh
                                    {quickEditMode && !onInEdit({field: `card_log.${card_log?.card_id}.teacher_comment`}) && canEdit === 1  &&
                                        <span onClick={() => {
                                            setOnEditfields([...onEditFields, `card_log.${card_log?.card_id}.teacher_comment`])
                                        }}
                                              className={"cursor-pointer ms-2 text-purple-bright"}>
                                                                    <EditOutlined/>Sửa</span>
                                    }
                                    {onInEdit({field: `card_log.${card_log?.card_id}.teacher_comment`}) &&
                                        <span onClick={handleQuickUpdate}
                                              className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}><CheckCircleOutlined
                                            className={"me-2"}/>Cập nhật
                                                                    </span>
                                    }
                                </div>
                                <TextArea value={card_log?.teacher_note}
                                          disabled={quickEditMode && !onInEdit({field: `card_log.${card_log?.card_id}.teacher_comment`})}
                                          onChange={(r) => {
                                              updateCardLog({
                                                  card_id: card_log?.card_id,
                                                  key: "teacher_note",
                                                  value: r.target.value
                                              })
                                          }}
                                          size={"large"} rootClassName={"w-100 h-75"}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {data?.card_logs?.filter(v => v?.can_attendance === 0).length >= 1 &&
                <div className={"mt-3 mb-1 fw-bold"}>Thẻ học không được điểm danh</div>
            }
            <div className={"row"}>
                {data?.card_logs?.filter(c => c?.can_attendance === 0).map((card_log, key) =>
                    <div className={"col-6"}>
                        <div className={"bg-label-danger"}>
                            <div className={"p-2 d-flex align-items-center"}>
                                <div className={"text-center"}>
                                    <img src={card_log?.student?.avatar} style={{width: "4rem"}} alt={""}/>
                                    <div className={"fw-bold"}>{card_log?.student?.name}</div>
                                    <div className={"text-xs"}>{card_log?.student?.uuid}</div>
                                    {card_log?.user_abilities?.can_edit &&
                                        <span className={"cursor-pointer text-xs my-2 text-center text-purple-bright"}
                                              onClick={() => {
                                                  quickEditCard({id: card_log?.card_id})
                                              }}>
                                                <EditOutlined/>
                                                <span className={"ms-1"}>Sửa thông tin</span>
                                            </span>
                                    }
                                </div>
                                <div className={"ms-3"}>
                                    <div className={"mb-1"}>
                                        <span className={"me-1"}>Mã thẻ học:</span>
                                        <span
                                            className={"fw-bold text-primary"}>{card_log?.uuid}</span>
                                    </div>
                                    <div className={"mb-1"}>
                                        <span className={"me-1"}>Số có thể dùng theo thanh toán:</span>
                                        <span
                                            className={"fw-bold text-success"}>{card_log?.can_use_day_by_paid}</span>
                                    </div>
                                    <div className={"mb-1"}>
                                        <span className={"me-1"}>Số buổi đã dùng và VAN:</span>
                                        <span
                                            className={"fw-bold text-success"}>{card_log?.van_and_attended_days}</span>
                                    </div>
                                    <div className={"mb-1"}>
                                        <span className={"me-1"}>Số buổi còn lại có thể dùng:</span>
                                        <span className={"fw-bold text-danger"}>{card_log?.can_use_day.toLocaleString(undefined, {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2
                                        })}</span>
                                    </div>
                                    {card_log?.allow_deg &&
                                        <div className={"mb-1"}>
                                            <span className={"me-1"}>Giới hạn điểm danh âm:</span>
                                            <span className={"fw-bold text-danger"}>{card_log?.limit_deg}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Drawer
                closable
                destroyOnClose
                title={"Sửa thẻ học"}
                placement="right"
                open={openDrawer}
                loading={false}
                size={'large'}
                onClose={() => {
                    setOpenDrawer(false)
                }}
            >
                <EditCardDrawer id={drawerCardId} callback={(card) => {
                    console.log(card)
                    const newCardLog = data?.card_logs?.map((card_log) => {
                        if (card_log?.card_id === card?.id) {
                            return {
                                card_id: card?.id,
                                uuid: card?.uuid,
                                student_id: card?.student_id,
                                student: card?.student,
                                day: 1,
                                status: 0,
                                reason: '',
                                teacher_note: '',
                                can_attendance: card?.can_attendance,
                                can_use_day: card?.can_use_day,
                                limit_deg: card?.limit_deg,
                                van_and_attended_days: card?.van_and_attended_days,
                                can_use_day_by_paid: card?.can_use_day_by_paid,
                                user_abilities: card?.user_abilities
                            }
                        }

                        return card_log
                    })

                    setData({
                        ...data,
                        card_logs: newCardLog
                    })

                    setDrawerCardId(undefined)
                    setOpenDrawer(false)
                }}/>
            </Drawer>
        </div>
    )
}
export default StudyCardLogsFormComponent