import SettingLayout from "../../_layouts/SettingLayout";

const IntegrationSettingScreen = () => {
    return (
        <SettingLayout tab={'integration'}>
            Tích hợp
        </SettingLayout>
    )
}

export default IntegrationSettingScreen