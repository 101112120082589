import {useNavigate} from "react-router-dom";

const ClassroomInfoColumnComponent = ({id, record}) => {
    const nav = useNavigate()
    const handleEdit = () => {
        nav(`/classroom/edit/${record?.classroom?.id}`)
    }
    return (
        <div onClick={handleEdit} className={"d-flex align-items-center"}>
            <div className={"rounded-circle me-2"}>
                {record?.classroom?.avatar &&
                    <img alt={"pink"}

                         src={record?.classroom?.avatar} style={{width: "2.5rem"}}
                         className={"rounded-circle cursor-pointer"}/>
                }
            </div>
            <div className={"cursor-pointer"}>
                <div className={"fw-semi text-purple"}>{record?.classroom?.name}</div>
                <div className={"small text-purple"}>{record?.classroom?.uuid}</div>
            </div>
        </div>
    )
}

export default ClassroomInfoColumnComponent