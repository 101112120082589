import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import InputFormComponent from "../../_components/InputFormComponent";
import {useMemo, useState} from "react";
import {getCommonFormRequestStruct} from "../../_api/CommonApi";
import {getOneStaffAction} from "../../_api/StaffApi";
import Notification from "../../_classes/Notification";
import {useNavigate, useParams} from "react-router-dom";
import {getOneTeacherAction, updateTeacherAction} from "../../_api/TeacherApi";
import {BackwardFilled} from "@ant-design/icons";

const UpdateTeacherScreen = () => {
    const [formStruct, setFormStruct] = useState([])
    const [teacher, setTeacher] = useState({})
    const nav = useNavigate();
    const {id} = useParams();
    const [errorBag, setErrorBag] = useState([])

    useMemo(() => {
        getCommonFormRequestStruct('teacher').then((r) => {
            setFormStruct(r.data)
        })
    }, []);

    useMemo(() => {
        if (id !== undefined) {
            getOneTeacherAction(id).then((response) => {
                setTeacher(response?.data)
            })
        }
    }, [id]);

    const handleUpdateStaff = () => {
        updateTeacherAction(teacher, id).then((r) => {
            if (r?.success === true) {
                Notification.success(r?.data?.message)
                nav(-1)
            } else {
                setErrorBag(r?.errors)
            }
        })
    }
    return (
        <AuthenticatedLayout>
            <div className={"h4 text-dark"}>
                <span onClick={()=>{nav(-1)}} className={"text-xs text-purple-bright cursor-pointer mx-2"}>
                <BackwardFilled/>
                Trở về
                </span>
                <span>Chỉnh sửa giáo viên</span>
            </div>
            <div className={"my-4"}>
                <InputFormComponent
                    formStruct={formStruct}
                    form={teacher}
                    setForm={setTeacher}
                    errorBag={errorBag}
                    ignoreUpdate={['teacher_source']}
                />
            </div>
            <button onClick={handleUpdateStaff} className={"btn-purple-bright mt-2 p-2 px-3 mx-3"}>
                Cập nhật
            </button>
        </AuthenticatedLayout>
    )
}

export default UpdateTeacherScreen