import {
    CreditCardOutlined,
    DashboardOutlined, HistoryOutlined, OrderedListOutlined, SettingOutlined,
    UserAddOutlined,
    UserSwitchOutlined
} from "@ant-design/icons";

export const _menu = [
    {
        key: '',
        icon: <DashboardOutlined/>,
        label: 'Bảng điều khiển',
    },
    {
        key: 'users',
        label: 'Nhân sự',
        icon: <UserSwitchOutlined/>,
        children: [
            {
                key: 'teacher',
                label: 'Giáo viên',
            },
            {
                key: 'staff',
                label: 'Nhân viên',
            },
            {
                key: 'supporter',
                label: 'Trợ giảng',
            },
        ],
    },
    {
        key: 'student',
        icon: <UserAddOutlined/>,
        label: 'Học sinh',
    },
    {
        key: 'card',
        icon: <CreditCardOutlined/>,
        label: 'Thẻ học',
    },
    {
        key: 'classroom',
        icon: <OrderedListOutlined/>,
        label: 'Lớp học',
    },
    {
        key: 'studylog',
        icon: <HistoryOutlined/>,
        label: 'Buổi học',
    },
    {
        key: 'settings/role',
        icon: <SettingOutlined/>,
        label: 'Cài đặt',
    },
];

export const _card_log_status = [
    {
        value: 0,
        label: "Đi học, đúng giờ"
    },
    {
        value: 1,
        label: "Đi học, muộn",
    },
    {
        value: 2,
        label: "Đi học, sớm"
    },
    {
        value: 3,
        label: "Vắng, có phép"
    },
    {
        value: 4,
        label: "Vắng, không phép",
    }
];