import UnAuthenticatedLayout from "../../_layouts/UnAuthenticatedLayout";
import app_logo from "../../_assets/images/logo-ngang.png";
import CustomLink from "../../_components/CustomLink";
import {LockOutlined} from "@ant-design/icons";
import {useState} from "react";
import {resetPasswordAction} from "../../_api/CommonApi";
import Notification from "../../_classes/Notification";
import {useNavigate, useParams} from "react-router-dom";
import Password from "antd/es/input/Password";

const ResetPasswordScreen = () => {
    const {token} = useParams()
    const nav = useNavigate()
    const handleResetPassword = () => {
        resetPasswordAction(data,token).then(r => {
            if (r?.success) {
                Notification.success("Đặt lại mật khẩu thành công")
                nav('/login')
            }else {
                setErrors(r?.errors)
            }
        })
    }
    const [errors, setErrors] = useState([])
    const [data, setData] = useState({})
    return (
        <UnAuthenticatedLayout>
            <div className={"bg-base d-flex justify-content-center align-items-center"} style={{height: '100vh'}}>
                <div className={"bg-white shadow-lg p-5"} style={{width: 400, borderRadius: 10}}>
                    <div className={"text-purple-bright fw-bold h3 m-0 text-center mb-3"}>
                        <img className={"mb-3"} src={app_logo} style={{width: "180px"}}/>
                    </div>
                    <div className={"mb-2"}>
                        <Password value={data?.password} onChange={(r) => setData({...data,password:r.target.value})}
                                  prefix={<LockOutlined/>} placeholder={"Mật khẩu"}
                                  status={errors?.password ? "error" : false}
                                  rootClassName={"p-1 px-2 mb-2"}/>
                        {errors?.password &&
                            <div className={"mb-3 text-xs text-danger"}>{errors?.password}</div>
                        }

                        <Password value={data?.password_confirmation} onChange={(r) => setData({...data,password_confirmation:r.target.value})}
                                  prefix={<LockOutlined/>} placeholder={"Nhập lại mật khẩu"}
                                  status={errors?.password_confirmation ? "error" : false}
                                  rootClassName={"p-1 px-2 mb-2"}/>
                        {errors?.password_confirmation &&
                            <div className={"mb-3 text-xs text-danger"}>{errors?.password_confirmation}</div>
                        }
                    </div>
                    <button onClick={handleResetPassword} className={"btn-purple-bright p-1 px-5 w-100 mb-2"}>Đổi mật khẩu</button>
                    <div className={"small text-purple-bright mb-2 cursor-pointer text-center"}>
                        <CustomLink to={"/login"}>Quay về đăng nhập</CustomLink>
                    </div>
                </div>
            </div>
        </UnAuthenticatedLayout>
    )
}
export default ResetPasswordScreen