import TextArea from "antd/lib/input/TextArea";
import {useEffect, useState} from "react";
import {createCommentAction, getAllCommentsAction} from "../_api/CommentApi";
import {Button} from "antd";
import {toast} from "react-toastify";
import Notification from "../_classes/Notification";

const CommentsComponent = ({objectType, objectId, changeData, height = "25rem"}) => {
    const [comments, setComments] = useState([])
    const [currentMessage, setCurrentMessage] = useState("")
    const [limit, setLimit] = useState(10)
    const handleCreateComment = () => {
        const comment = {
            object_type: objectType,
            object_id: objectId,
            type: 0,
            content: currentMessage
        }

        createCommentAction(comment).then((r) => {
            if (r?.success) {
                setCurrentMessage("")
                Notification.success("Thành công")
                getAllCommentsAction({
                    filtering: {
                        object_type: objectType,
                        object_id: objectId
                    },
                    limit: limit
                }).then((r) => {
                    if (r?.success) {
                        setComments(r?.data?.data)
                    }
                })
            }
        })
    }
    useEffect(() => {
        getAllCommentsAction({
            filtering: {
                object_type: objectType,
                object_id: objectId
            },
            limit: limit
        }).then((r) => {
            if (r?.success) {
                setComments(r?.data?.data)
            }
        })
    }, [changeData, limit])
    return (
        <div className={"border rounded d-flex flex-column"}>
            <div>
                <div style={{overflowY: "scroll", flex: 1, height: height}}>
                    {comments?.map((comment, key) =>
                        <Comment comment={comment} key={key}/>
                    )}
                    <div
                        onClick={() => {
                            setLimit(limit + 5)
                        }}
                        className={"my-2 text-center text-purple-bright cursor-pointer"}>Xem thêm
                    </div>
                </div>
                <div className={"border"}>
                    <TextArea
                        autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }}
                        value={currentMessage}
                        onChange={(r) => {
                            setCurrentMessage(r.target.value)
                        }}
                        placeholder={"Nhập bình luận tại đây"} className={'w-100 border-0 rounded-0 border-top'}
                    />
                    <div className={"my-1 d-flex justify-content-end p-1"}>
                        <Button
                            onClick={handleCreateComment}
                            disabled={currentMessage === '' || currentMessage === null || currentMessage === undefined}
                            className={"bg-purple-bright border-0 rounded text-white px-3 py-1"}>Gửi</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommentsComponent

const Comment = ({comment}) => {
    return (
        <div className={"border-bottom"}>
            <div className={"d-flex p-2 align-items-start"}>
                <img alt={""} style={{width: "2rem", height: "2rem"}}
                     className={"rounded-circle"}
                     src={comment?.creator?.avatar}
                />
                <div className={"ms-2"}>
                    <div className={"fw-bold text-purple"}>{comment?.creator?.name}</div>
                    <div className={"text-xs text-secondary"}>{comment?.created_at_time}</div>
                    {comment?.type === 0 &&
                        <div>{comment?.content}</div>
                    }
                    {comment?.type === 1 &&
                        <div>
                           <span className={"fw-bold text-purple-bright"}>
                               [LOG]
                           </span>
                            <span className={"text-purple-bright"}> {comment?.content}</span>
                        </div>
                    }
                    {comment?.type === 2 &&
                        <div>
                           <span className={"fw-bold text-purple-bright"}>
                               [LOG]
                           </span>
                            <span className={"text-purple-bright"}> {comment?.content}</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}