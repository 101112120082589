import {useNavigate} from "react-router-dom";
import {EditOutlined} from "@ant-design/icons";

const UserInfoColumnComponent = ({id, record}) => {
    const nav = useNavigate()
    const handleEdit = () => {
        nav(`/${record?.creator?.entity}/edit/${record?.creator?.id}`)
    }
    return (
        <div className={"d-flex align-items-center"}>
            <div className={"rounded-circle me-2"}>
                {record?.creator?.avatar &&
                    <img alt={"pink"}

                         src={record?.creator?.avatar} style={{width: "2.5rem"}}
                         className={"rounded-circle cursor-pointer"}/>
                }
            </div>
            <div className={"cursor-pointer"}>
                <div className={"d-flex"}>
                    <div className={"fw-semi text-purple"}>{record?.creator?.name}</div>
                    {record?.creator?.avatar &&
                        <div onClick={handleEdit} className={"ms-2"}>
                            <EditOutlined/>
                        </div>
                    }
                </div>
                <div className={"small text-purple"}>{record?.creator?.uuid}</div>
            </div>
        </div>
    )
}

export default UserInfoColumnComponent