const DateColumn = ({value, record}) => {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return (
        <div>
            {value ? formattedDate : null}
        </div>
    )
}

export default DateColumn