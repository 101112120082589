import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllSalaryCriteriaAction = async (query) => {
    return await (new Api()).get('salary_criteria', query, getAuthHeader());
}


export const createSalaryCriteriaAction = async (data) => {
    return await (new Api()).post('salary_criteria', data, getAuthHeader())
}

export const getOneSalaryCriteriaAction = async (id, data) => {
    return await (new Api()).get(`salary_criteria/${id}`, data, getAuthHeader())
}

export const updateSalaryCriteriaAction = async (data, id) => {
    return await (new Api()).put(`salary_criteria/${id}`, data, getAuthHeader())
}
export const deleteSalaryCriteriaAction = async (id) => {
    return await (new Api()).delete(`salary_criteria/${id}`, getAuthHeader())
}

