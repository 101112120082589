const DateTimeColumn = ({value, record}) => {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hour = date.getHours()
    const minute = date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes().toString()
    const second = date.getSeconds() >= 10 ? date.getSeconds() : "0" + date.getSeconds().toString()
    const formattedDate = `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    return (
        <div>
            {value ? formattedDate : null}
        </div>
    )
}

export default DateTimeColumn