import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useMemo, useState} from "react";
import {getAllBranch} from "../../_api/BranchApi";
import CreateNewBranch from "../../_components/CreateNewBranch";
import BranchComponent from "../../_components/BranchComponent";

const BranchScreen = () => {
    const [branches, setBranches] = useState([])
    const branchCreated = () => {
        getAllBranch().then((r) => {
            if (r?.success) {
                setBranches(r?.data?.data)
            } else {

            }
        })
    }
    useMemo(() => {
        getAllBranch().then((r) => {
            if (r?.success) {
                setBranches(r?.data?.data)
            } else {

            }
        })
    }, []);
    return (
        <AuthenticatedLayout>
            {branches?.length === 0 &&
                <div className={"create_new_branch"}>
                    <CreateNewBranch callback={branchCreated}/>
                </div>
            }
            {branches?.length > 0 &&
                <div className={'list_branch p-2'}>
                   <div className={"row"}>
                       {branches?.map((branch, key) =>
                           <div key={key} className={"col-md-3 col-6 p-2"}>
                               <BranchComponent branch={branch}/>
                           </div>
                       )}
                   </div>
                </div>
            }
        </AuthenticatedLayout>
    )
}

export default BranchScreen