import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllUserNotificationAction = async (query) => {
    return await (new Api()).get('notification', query, getAuthHeader());
}
export const readNotificationAction = async (id) => {
    return await (new Api()).put(`notification/${id}/read`, {}, getAuthHeader())
}
export const pinNotificationAction = async (id) => {
    return await (new Api()).put(`notification/${id}/pin`, {}, getAuthHeader())
}

