import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import AvatarField from "../../_components/Fields/AvatarField";
import {useEffect, useState} from "react";
import {getCurrentUserAction, updateCurrentUserAction} from "../../_api/CommonApi";
import {Col, Input} from "antd";
import Password from "antd/es/input/Password";
import Notification from "../../_classes/Notification";

const EditCurrentUserInfoScreen = () => {
    const [user, setUser] = useState({})
    const [errors, setErrors] = useState([])
    useEffect(() => {
        getCurrentUserAction().then((r) => {
            if (r?.success) {
                setUser(r?.data)
            }
        })
    }, [])

    const handleUpdateCurrentUser = () => {
        updateCurrentUserAction(user).then((r) => {
            if (r?.success) {
                Notification.success(r?.data?.message)
                window.location.href = window.location.origin + '/#/user'
            } else {
                setErrors(r?.errors)
            }
        })
    }

    return (
        <AuthenticatedLayout>
            <div className={"mb-3"}>
                <div className={"fw-bold mb-1"}>Ảnh đại diện</div>
                <AvatarField value={user?.avatar} setValue={(r) => {
                    setUser({...user, avatar: r})
                }} placeholder={"Ảnh đại diện"}/>
            </div>
            <div className={"mb-3"}>
                <div className={"row"}>
                    <Col xl={12} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Họ và tên</div>
                        <Input size={"large"} onChange={(r) => {
                            setUser({...user, name: r.currentTarget.value})
                        }} value={user?.name}/>
                        <span className={"my-1 text-xs text-danger"}>{errors?.name}</span>
                    </Col>
                    <Col xl={12} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Mã (tên đăng nhập)</div>
                        <Input size={"large"} disabled={true} value={user?.uuid}/>
                    </Col>
                    <Col xl={12} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Email</div>
                        <Input size={"large"} onChange={(r) => {
                            setUser({...user, email: r.currentTarget.value})
                        }} value={user?.email}/>
                        <span className={"my-1 text-xs text-danger"}>{errors?.email}</span>
                    </Col>
                    <Col xl={12} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Số điện thoại</div>
                        <Input size={"large"} onChange={(r) => {
                            setUser({...user, phone: r.currentTarget.value})
                        }} value={user?.phone}/>
                        <span className={"my-1 text-xs text-danger"}>{errors?.phone}</span>
                    </Col>
                </div>
                <div className={"row"}>
                    <Col xl={12} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Mật khẩu hiện tại</div>
                        <Password size={"large"} onChange={(r) => {
                            setUser({...user, old_password: r.currentTarget.value})
                        }} value={user?.old_password}/>
                        <span className={"my-1 text-xs text-danger"}>{errors?.old_password}</span>
                    </Col>
                    <Col xl={12} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Mật khẩu mới</div>
                        <Password size={"large"} onChange={(r) => {
                            setUser({...user, password: r.currentTarget.value})
                        }} value={user?.password}/>
                        <span className={"my-1 text-xs text-danger"}>{errors?.password}</span>
                    </Col>
                    <Col xl={12} md={24} className={"mb-3"}>
                        <div className={"fw-bold mb-1"}>Xác nhận khẩu mới</div>
                        <Password size={"large"} onChange={(r) => {
                            setUser({...user, password_confirmation: r.currentTarget.value})
                        }} value={user?.password_confirmation}/>
                        <span className={"my-1 text-xs text-danger"}>{errors?.password_confirmation}</span>
                    </Col>
                </div>
            </div>
            <div className={"my-3"}>
                <button onClick={handleUpdateCurrentUser} className={"btn btn-hologram text-white"}>Chỉnh sửa thông
                    tin
                </button>
            </div>
        </AuthenticatedLayout>
    )
}

export default EditCurrentUserInfoScreen