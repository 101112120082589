import {useNavigate, useParams} from "react-router-dom";
import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useEffect, useState} from "react";
import {
    approveStudyLogsAction,
    cancelStudyLogsAction, confirmAllStudyLogsAction,
    confirmStudyLogsAction, deleteStudyLogsAction,
    getOneStudyLogsAction, refuseStudyLogsAction, restoreStudyLogsAction,
    submitStudyLogsAction
} from "../../_api/StudyLogApi";
import {
    AlibabaOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
    DeleteOutlined, RestOutlined, StopOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {Popconfirm} from "antd";
import CommentsComponent from "../../_components/CommentsComponent";
import StudyLogFormComponent from "../../_components/StudyLogFormComponent";
import {commonAction} from "../../_provider/_slices/CommonSlice";
import {useDispatch, useSelector} from "react-redux";
import Notification from "../../_classes/Notification";
import NotFoundScreen from "../errors/NotFoundScreen";

const QuickEditStudyLogScreen = () => {
    const {id} = useParams();
    const [studylog, setStudylog] = useState({})
    const dispatch = useDispatch()
    const [notFound, setNotFound] = useState(false)
    const nav = useNavigate()
    const commonState = useSelector((state) => state.common)
    useEffect(() => {
        if (id) {
            dispatch(commonAction({loadingState: true}))
            getOneStudyLogsAction(id).then(r => {
                if (r?.success) {
                    setStudylog(r?.data)
                    dispatch(commonAction({loadingState: false}))
                } else {
                    if (r?.code === 404) {
                        setNotFound(true)
                    }
                    dispatch(commonAction({loadingState: false}))
                }
            })

        }
    }, [id])

    if (notFound === true) {
        return <NotFoundScreen/>
    }

    const handleSubmitStudyLog = () => {
        dispatch(commonAction({loadingState: true}))
        submitStudyLogsAction(id).then(r => {
            if (r?.success) {
                getOneStudyLogsAction(id).then(r => {
                    if (r?.success) {
                        setStudylog(r?.data)
                    }
                })
                dispatch(commonAction({loadingState: false}))
            } else {
                dispatch(commonAction({loadingState: false}))
            }
        })
    }
    const handleCancelStudyLog = () => {
        dispatch(commonAction({loadingState: true}))
        cancelStudyLogsAction(id).then(r => {
            if (r?.success) {
                getOneStudyLogsAction(id).then(r => {
                    if (r?.success) {
                        setStudylog(r?.data)
                    }
                })
                dispatch(commonAction({loadingState: false}))
            } else {
                dispatch(commonAction({loadingState: false}))
            }
        })
    }

    const handleRestoreStudyLog = () => {
        dispatch(commonAction({loadingState: true}))
        restoreStudyLogsAction(id).then(r => {
            if (r?.success) {
                getOneStudyLogsAction(id).then(r => {
                    if (r?.success) {
                        setStudylog(r?.data)
                    }
                })
                dispatch(commonAction({loadingState: false}))
            } else {
                dispatch(commonAction({loadingState: false}))
            }
        })
    }

    const handleConfirmStudyLogAction = () => {
        dispatch(commonAction({loadingState: true}))
        confirmStudyLogsAction(id).then(r => {
            if (r?.success) {
                getOneStudyLogsAction(id).then(r => {
                    if (r?.success) {
                        setStudylog(r?.data)
                    }
                })
                dispatch(commonAction({loadingState: false}))
            } else {
                dispatch(commonAction({loadingState: false}))
            }
        })
    }

    const handleConfirmAllStudyLogAction = () => {
        dispatch(commonAction({loadingState: true}))
        confirmAllStudyLogsAction(id).then(r => {
            if (r?.success) {
                getOneStudyLogsAction(id).then(r => {
                    if (r?.success) {
                        setStudylog(r?.data)
                    }
                })
                dispatch(commonAction({loadingState: false}))
            } else {
                dispatch(commonAction({loadingState: false}))
            }
        })
    }

    const handleApproveStudyLogAction = () => {
        dispatch(commonAction({loadingState: true}))
        approveStudyLogsAction(id).then(r => {
            if (r?.success) {
                getOneStudyLogsAction(id).then(r => {
                    if (r?.success) {
                        setStudylog(r?.data)
                    }
                })
                dispatch(commonAction({loadingState: false}))
            } else {
                dispatch(commonAction({loadingState: false}))
            }
        })
    }
    const handleRefuseStudyLogAction = () => {
        dispatch(commonAction({loadingState: true}))
        refuseStudyLogsAction(id).then(r => {
            if (r?.success) {
                getOneStudyLogsAction(id).then(r => {
                    if (r?.success) {
                        setStudylog(r?.data)
                    }
                })
                dispatch(commonAction({loadingState: false}))
            } else {
                dispatch(commonAction({loadingState: false}))
            }
        })
    }

    const handleDeletedStudyLogAction = () => {
        dispatch(commonAction({loadingState: true}))
        deleteStudyLogsAction(id).then(r => {
            if (r?.success) {
                nav("/studylog")
                Notification.success(r?.data?.message)
                dispatch(commonAction({loadingState: false}))

            } else {
                dispatch(commonAction({loadingState: false}))
            }
        })
    }

    return (
        <AuthenticatedLayout layout={false}>
            <div className={"row"}>
                <div className={"col-lg-3 col-md-6 col-12 position-relative top-0"}>
                    <div className={"position-sticky top-0 bg-white p-2 rounded"}>
                        <div
                            className={"classroom_info d-flex bg-label-purple text-purple-bright align-items-center mb-2"}>
                            <div className={"p-2"}>
                                <img style={{width: "3rem"}} className={"rounded-circle"}
                                     src={studylog?.classroom?.avatar} alt={"classroom_avatar"}/>
                            </div>
                            <div className={"p-2"}>
                                <div className={"fw-bold"}>{studylog?.classroom?.name}</div>
                                <div>{studylog?.classroom?.uuid}</div>
                            </div>
                        </div>
                        <div
                            className={"studylog_status d-flex bg-label-purple text-purple-bright align-items-center mb-2"}>
                            <div className={"p-2"}>
                                <div className={[studylog?.status_color, "fw-bold"].join(' ')}>Trạng
                                    thái: {studylog?.status_title}</div>
                                <div className={"fw-bold text-dark"}>ID buổi học: {studylog?.uuid}</div>
                            </div>
                        </div>
                        <div className={"user_related mb-3"}>
                            <div className={"fw-bold mb-1"}>Xác nhận của giáo viên, trợ giảng</div>
                            {studylog?.related_users?.map((related_user, key) =>
                                <div
                                    className={"bg-label-purple rounded p-2 d-flex flex-wrap align-items-center justify-content-between"}>
                                    <span className={"m-0 d-flex align-items-center"}>
                                        <img alt={"imgg"} src={related_user?.avatar} className={"rounded-circle"}
                                             style={{width: "1.7rem"}}/>
                                        <div className={"ms-1"}>
                                            <div className={"fw-bold"}>{related_user?.name} ({related_user?.uuid})</div>
                                            {related_user?.is_accepted === 1 &&
                                                <div
                                                    className={"w-100 text-success fw-bold text-xs"}>Đã xác nhận
                                                    lúc: {related_user?.accepted_at.toLocaleString()}</div>
                                            }
                                        </div>
                                    </span>

                                    {related_user?.is_accepted === 0
                                        && studylog?.user_abilities?.can_confirm === 1
                                        && related_user.id === parseInt(commonState?.currentUser?.id)
                                        &&
                                        <Popconfirm
                                            className={"fw-bold"}
                                            title="Xác nhận thông tin buổi học"
                                            description="Xác nhận thông tin buổi học hoàn toàn chính xác, bạn sẽ không thể huỷ thay đổi"
                                            onConfirm={handleConfirmStudyLogAction}
                                            okText="Xác nhận"
                                            cancelText="Xem lại thông tin"
                                        >
                                            <CheckCircleFilled className={"text-success cursor-pointer h4 m-0"}/>
                                        </Popconfirm>
                                    }
                                </div>
                            )}
                        </div>
                        <div className={"comments p-2"}>
                            <CommentsComponent
                                objectType={"studylog"}
                                objectId={id}
                                changeData={studylog}
                            />
                        </div>
                        <div className={"action"}>
                            {studylog?.user_abilities?.can_submit === 1 &&

                                <Popconfirm
                                    className={"fw-bold"}
                                    onConfirm={handleSubmitStudyLog}
                                    okText={"Gửi lên"}
                                    cancelText={"Xem lại"}
                                    title={"Gửi lên"}>
                                    <div
                                        className={"bg-purple-bright text-white fw-bold rounded p-2 cursor-pointer mb-2"}>
                                        <UploadOutlined className={"me-2"}/>
                                        Gửi buổi học lên
                                    </div>
                                </Popconfirm>

                            }
                            {studylog?.user_abilities?.can_confirm_all === 1 &&
                                <div onClick={handleConfirmAllStudyLogAction}
                                     className={"btn-hologram text-white fw-bold rounded p-2 cursor-pointer mb-2"}>
                                    <UploadOutlined className={"me-2"}/>
                                    Xác nhận thay tất cả giáo viên, trợ giảng
                                </div>
                            }
                            {
                                studylog?.user_abilities?.can_cancel === 1 &&

                                <Popconfirm
                                    className={"fw-bold"}
                                    onConfirm={handleCancelStudyLog}
                                    okText={"Xác nhận huỷ"}
                                    cancelText={"Không"}
                                    title={"Bạn có muốn huỷ buổi học này ? Bạn vẫn có thể khôi phục lại"}>
                                    <div className={"bg-secondary text-white fw-bold rounded p-2 cursor-pointer mb-2"}>
                                        <DeleteOutlined className={"me-2"}/>
                                        Huỷ bỏ buổi học này
                                    </div>
                                </Popconfirm>

                            }
                            {
                                studylog?.user_abilities?.can_restore === 1 &&

                                <Popconfirm
                                    className={"fw-bold"}
                                    onConfirm={handleRestoreStudyLog}
                                    okText={"Xác nhận khôi phục"}
                                    cancelText={"Không"}
                                    title={"Bạn có muốn khôi phục buổi học này ? Buổi học sẽ ở trạng thái nháp"}>
                                    <div className={"bg-success text-white fw-bold rounded p-2 cursor-pointer mb-2"}>
                                        <RestOutlined className={"me-2"}/>
                                        Khôi phục buổi học này
                                    </div>
                                </Popconfirm>

                            }
                            {
                                studylog?.user_abilities?.can_approve === 1 &&

                                <Popconfirm
                                    className={"fw-bold"}
                                    onConfirm={handleApproveStudyLogAction}
                                    okText={"Xác nhận duyệt"}
                                    cancelText={"Không"}
                                    title={"Bạn xác nhận duyệt buổi học này ?"}>
                                    <div className={"bg-success text-white fw-bold rounded p-2 cursor-pointer mb-2"}>
                                        <CheckCircleFilled className={"me-2"}/>
                                        Duyệt buổi học
                                    </div>
                                </Popconfirm>

                            }
                            {
                                studylog?.user_abilities?.can_refuse === 1 &&
                                <Popconfirm
                                    className={"fw-bold"}
                                    onConfirm={handleRefuseStudyLogAction}
                                    okText={"Xác nhận từ chối"}
                                    cancelText={"Không"}
                                    title={"Bạn xác nhận từ chối buổi học này ?"}>
                                    <div className={"bg-warning text-white fw-bold rounded p-2 cursor-pointer mb-2"}>
                                        <StopOutlined className={"me-2"}/>

                                        Từ chối duyệt buổi học
                                    </div>
                                </Popconfirm>
                            }
                            {
                                studylog?.user_abilities?.can_delete === 1 &&

                                <Popconfirm
                                    className={"fw-bold"}
                                    onConfirm={handleDeletedStudyLogAction}
                                    okText={"Xác nhận xoá"}
                                    cancelText={"Không"}
                                    title={"Bạn chắc chắn muốn xoá buổi học này ? Sau khi xoá không thể khôi phục"}>
                                    <div className={"bg-danger text-white fw-bold rounded p-2 cursor-pointer mb-2"}>
                                        <DeleteOutlined className={"me-2"}/>
                                        Xoá hẳn buổi học
                                    </div>
                                </Popconfirm>
                            }
                        </div>
                    </div>
                </div>
                <div className={"col-lg-9 col-md-6 col-12 h-100"}>
                    <div className={"bg-white p-3 rounded"}>
                        <StudyLogFormComponent
                            data={studylog}
                            setData={setStudylog}
                            errorBag={[]}
                            id={studylog?.id}
                            quickEditMode={true}
                            canEdit={studylog?.user_abilities?.can_edit}
                            setErrorBag={() => {
                            }}
                        />
                    </div>
                </div>
            </div>
        </AuthenticatedLayout>
    )
}

export default QuickEditStudyLogScreen