// src/contexts/SocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

// URL của Socket.IO server
const SOCKET_SERVER_URL = 'https://ling.linhpq.top';

// Tạo context
const SocketContext = createContext(null);

// Tạo provider component
export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        // Khởi tạo kết nối Socket.IO
        const newSocket = io(SOCKET_SERVER_URL);
        setSocket(newSocket);
        return () => newSocket.close();
    }, []);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

// Custom hook để sử dụng socket trong các component
export const useSocket = () => useContext(SocketContext);
