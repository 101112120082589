import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import InputFormComponent from "../../_components/InputFormComponent";
import {getCommonFormRequestStruct} from "../../_api/CommonApi";
import Notification from "../../_classes/Notification";
import {useNavigate} from "react-router-dom";
import {createTeacherAction} from "../../_api/TeacherApi";
import {useMemo, useState} from "react";
import {BackwardFilled} from "@ant-design/icons";
import {createSupporterAction} from "../../_api/SupporterApi";

const CreateSupporterScreen = () => {
    const [formStruct, setFormStruct] = useState([])
    const [supporter, setSupporter] = useState({})
    const nav = useNavigate();
    const [errorBag,setErrorBag] = useState([])
    useMemo(() => {
        getCommonFormRequestStruct('supporter').then((r) => {
            setFormStruct(r.data)
        })
    }, []);

    const handleCreateSupporter = () => {
        createSupporterAction(supporter).then((r) => {
            if (r?.success === true) {
                Notification.success(r?.data?.message)
                nav(-1)
            } else {
                setErrorBag(r?.errors)
            }
        })
    }
    return (
        <AuthenticatedLayout>
            <div className={"h4 text-dark"}>
                <span onClick={() => {
                    nav(-1)
                }} className={"text-xs text-purple-bright cursor-pointer mx-2"}>
                <BackwardFilled/>
                Trở về
                </span>
                <span>Thêm mới trợ giảng</span>
            </div>
            <div className={"my-4"}>
                <InputFormComponent
                    formStruct={formStruct}
                    form={supporter}
                    setForm={setSupporter}
                    errorBag={errorBag}
                />
            </div>
            <button onClick={handleCreateSupporter} className={"btn-purple-bright mt-2 p-2 px-3 mx-3"}>Thêm mới
            </button>
        </AuthenticatedLayout>
    )
}

export default CreateSupporterScreen