import {useNavigate} from "react-router-dom";

const SalaryInfoColumnComponent = ({id, record}) => {
    const nav = useNavigate()
    const handleShow = () => {
        nav(`/${record?.user_abilities?.entity}/show/${record?.id}`)
    }
    return (
        <div onClick={handleShow} className={"d-flex align-items-center"}>
            <div className={"rounded-circle me-2"}>
                {(record?.avatar || record?.user?.avatar) &&
                    <img alt={"pink"}
                         src={record?.avatar || record?.user?.avatar} style={{width: "2.5rem"}}
                         className={"rounded-circle cursor-pointer"}/>
                }
            </div>
            <div className={"cursor-pointer"}>
                <div className={"fw-semi text-purple"}>{record?.user?.name}</div>
                <div className={"small text-purple"}>Mã thẻ lương: {record?.uuid }</div>
                <div className={"small text-purple"}>Mã nhân sự: {record?.user?.uuid}</div>
                <div className={record?.is_closed === 1 ? "fw-bold text-success" : "fw-bold text-danger"}>{record?.is_closed === 1 ? "Đã chốt" : "Chưa chốt"}</div>
            </div>
        </div>
    )
}

export default SalaryInfoColumnComponent