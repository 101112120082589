import {useEffect, useState} from "react";
import {Badge, Button, Card, Input, Select, Upload} from "antd";
import {formatDateDmY, formatDateYmd, mapSelectData} from "../_classes/Helper";
import TextArea from "antd/lib/input/TextArea";
import {AppstoreAddOutlined, CheckCircleOutlined, EditOutlined, OrderedListOutlined,} from "@ant-design/icons";
import GalleryUploadFieldComponent from "./GalleryUploadFieldComponent";
import {getAllSharedTeachersAction} from "../_api/TeacherApi";
import {getAllSharedSupportersAction, getAllSupportersAction} from "../_api/SupporterApi";
import StudyCardLogsFormComponent from "./StudyCardLogsFormComponent";
import {createStudyLogsAction, updateStudyLogsAction} from "../_api/StudyLogApi";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const StudyLogFormComponent = ({data, setData, errorBag, setErrorBag, id, quickEditMode = false, canEdit = false}) => {
    const [onEditFields, setOnEditfields] = useState([])
    const [teachers, setTeachers] = useState([])
    const [supporters, setSupporters] = useState([])
    const onInEdit = ({field}) => {
        return onEditFields.includes(field)
    }
    const nav = useNavigate()

    const handleCreateStudylog = () => {
        createStudyLogsAction(data).then((r) => {
            if (r?.success) {
                nav("/studylog")
            } else {
                setErrorBag(r?.errors)
            }
        })
    }

    useEffect(() => {
        getAllSharedTeachersAction().then(r => {
            if (r?.success) {
                setTeachers(r?.data?.data)
            }
        })
    }, [])

    useEffect(() => {
        getAllSharedSupportersAction().then(r => {
            if (r?.success) {
                setSupporters(r?.data?.data)
            }
        })
    }, [])

    const handleQuickUpdate = () => {
        if (data?.id) {
            updateStudyLogsAction(data, id).then((r) => {
                if (r?.success) {
                    toast.success("Cập nhật thành công")
                    setOnEditfields([])
                }
            })
        }
    }

    const upDateWorkingShift = ({working_shift_id, key, value}) => {
        const newWorkingShift = data?.working_shifts?.map((w) => {
            if (w.id === working_shift_id) {
                return {...w, [key]: value}
            }

            return w
        })

        setData({
            ...data,
            working_shifts: newWorkingShift
        })
    }

    const addWorkingShift = () => {
        setData({
            ...data,
            working_shifts: [...data?.working_shifts, {
                id: Date.now()
            }]
        })
    }

    const removeWorkingShift = (id) => {
        setData({
            ...data,
            working_shifts: data?.working_shifts.filter((ws) => ws?.id !== id)
        })
    }

    return (
        <div>
            <div className={"mb-3"}>
                <div className={"mb-1 fw-bold"}>Tiêu đề buổi học (Tối đa 255 ký tự)
                    {quickEditMode && !onInEdit({field: 'title'}) && canEdit === 1  &&
                        <span onClick={() => {
                            setOnEditfields([...onEditFields, 'title'])
                        }} className={"cursor-pointer ms-2 text-purple-bright"}>
                            <EditOutlined/>
                            Sửa
                        </span>
                    }

                    {onInEdit({field: 'title'}) &&
                        <span onClick={handleQuickUpdate}
                              className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}>
                            <CheckCircleOutlined className={"me-2"}/>Cập nhật
                        </span>
                    }
                </div>
                <Input disabled={quickEditMode && !onInEdit({field: 'title'})} size={"large"}
                       value={data?.title}
                       onChange={(r) => setData({...data, title: r.target.value})}/>
            </div>

            <div className={"mb-3"}>
                <div className={"mb-1 fw-bold"}>Nội dung buổi học
                    {quickEditMode && !onInEdit({field: 'content'}) && canEdit === 1  &&
                        <span onClick={() => {
                            setOnEditfields([...onEditFields, 'content'])
                        }} className={"cursor-pointer ms-2 text-purple-bright"}>
                            <EditOutlined/>
                            Sửa
                        </span>
                    }

                    {onInEdit({field: 'content'}) &&
                        <span onClick={handleQuickUpdate}
                              className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}>
                            <CheckCircleOutlined className={"me-2"}/>Cập nhật
                        </span>
                    }
                </div>
                <TextArea disabled={quickEditMode && !onInEdit({field: 'content'})} size={"large"}
                          value={data?.content}
                          onChange={(r) => setData({...data, content: r.target.value})}/>
            </div>

            <div className={"row mb-3"}>
                <div className={"col-4"}>
                    <div className={"youtube_link"}>
                        <div className={"fw-bold"}> Video
                            {quickEditMode && !onInEdit({field: 'video'}) && canEdit === 1  &&
                                <span onClick={() => {
                                    setOnEditfields([...onEditFields, 'video'])
                                }} className={"cursor-pointer ms-2 text-purple-bright"}>
                            <EditOutlined/>
                            Sửa
                        </span>
                            }

                            {onInEdit({field: 'video'}) &&
                                <span onClick={handleQuickUpdate}
                                      className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}>
                            <CheckCircleOutlined className={"me-2"}/>Cập nhật
                        </span>
                            }
                        </div>
                        <Input
                            disabled={quickEditMode && !onInEdit({field: 'video'})}
                            value={data?.video} onChange={(r) => setData({...data, video: r.target.value})}
                            placeholder={"Link video youtube"}
                            addonBefore={
                                <img alt={""} style={{width: '1.5rem'}} className={"rounded-circle"}
                                     src={"https://cdn-icons-png.freepik.com/256/15707/15707874.png?semt=ais_hybrid"}/>}
                        />
                    </div>
                </div>
                <div className={"col-4"}>
                    <div className={"youtube_link"}>
                        <div className={"fw-bold"}> File
                            {quickEditMode && !onInEdit({field: 'file'}) && canEdit === 1  &&
                                <span onClick={() => {
                                    setOnEditfields([...onEditFields, 'file'])
                                }} className={"cursor-pointer ms-2 text-purple-bright"}>
                            <EditOutlined/>
                            Sửa
                        </span>
                            }

                            {onInEdit({field: 'file'}) &&
                                <span onClick={handleQuickUpdate}
                                      className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}>
                            <CheckCircleOutlined className={"me-2"}/>Cập nhật
                        </span>
                            }
                        </div>
                        <Input
                            disabled={quickEditMode && !onInEdit({field: 'file'})}
                            value={data?.file} onChange={(r) => setData({...data, file: r.target.value})}
                            addonBefore={
                                <img alt={""} style={{width: '1.5rem'}} className={"rounded-circle"}
                                     src={"https://cdn-icons-png.flaticon.com/512/2913/2913963.png"}/>}
                            placeholder={"Link google drive file"}
                        />
                    </div>
                </div>

                <div className={"col-4"}>
                    <div className={"youtube_link"}>
                        <div className={"fw-bold"}> File
                            {quickEditMode && !onInEdit({field: 'audio'}) && canEdit === 1  &&
                                <span onClick={() => {
                                    setOnEditfields([...onEditFields, 'audio'])
                                }} className={"cursor-pointer ms-2 text-purple-bright"}>
                            <EditOutlined/>
                            Sửa
                        </span>
                            }

                            {onInEdit({field: 'audio'}) &&
                                <span onClick={handleQuickUpdate}
                                      className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}>
                            <CheckCircleOutlined className={"me-2"}/>Cập nhật
                        </span>
                            }
                        </div>
                        <Upload
                            disabled={quickEditMode && !onInEdit({field: 'audio'})}
                            onChange={(r) => {
                                setData({...data, audio: r.target.value})
                            }}
                            accept={"mp3"} maxCount={1}>
                            <Button className={"d-flex w-100 align-items-center justify-content-start"}>
                                <img alt={""} style={{width: '1.5rem'}} className={"rounded-circle"}
                                     src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWEtov-j-5-6N2lx_Zm6l775IMz-KnPBLPkw&s"}/>
                                <span className={"ms-1 w-100 d-block"}>Tài lên file âm thanh</span>
                            </Button>
                        </Upload>
                    </div>
                </div>
            </div>

            {/*<div className={"my-3 mb-5"}>*/}
            {/*    <div className={"mb-2 fw-bold"}>Ảnh chụp buổi học</div>*/}
            {/*    <GalleryUploadFieldComponent/>*/}
            {/*</div>*/}

            <div className={"working_shifts_form"}>
                <div className={"fw-bold mb-1"}>Ca học</div>
                {data?.working_shifts?.map((working_shift, key) =>
                    <Badge.Ribbon className={"bg-purple-bright"} text={"Ca học " + (key + 1)} placement={"start"}
                                  key={key}>
                        <div key={key} className={"p-3 bg-shadow rounded mb-3 position-relative"}>
                            {(!quickEditMode || (canEdit && quickEditMode)) &&

                                <div
                                    onClick={() => {
                                        removeWorkingShift(working_shift?.id)
                                    }}
                                    className={"position-absolute fw-bold text-danger cursor-pointer"}
                                    style={{right: 20}}>Xoá
                                </div>
                            }


                            <div className={"pt-4"}>
                                <div className={"row"}>
                                    <div className={"col-4"}>
                                        <div className={"bg-white p-2 rounded"}>
                                            <div className={"row mb-3"}>
                                                <div className={"col-6"}>
                                                    <div>
                                                        <div className={"fw-bold"}>Bắt đầu
                                                            {quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.start_time`}) && canEdit === 1  &&
                                                                <span onClick={() => {
                                                                    setOnEditfields([...onEditFields, `working_shifts.${working_shift?.id}.start_time`])
                                                                }}
                                                                      className={"cursor-pointer ms-2 text-purple-bright"}>
                                                                    <EditOutlined/>Sửa</span>
                                                            }

                                                            {onInEdit({field: `working_shifts.${working_shift?.id}.start_time`}) &&
                                                                <span onClick={handleQuickUpdate}
                                                                      className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}><CheckCircleOutlined
                                                                    className={"me-2"}/>Cập nhật
                                                                    </span>
                                                            }
                                                        </div>
                                                        <div>
                                                            <input
                                                                disabled={quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.start_time`})}
                                                                value={working_shift?.start_time}
                                                                onChange={(r) => {
                                                                    upDateWorkingShift({
                                                                        working_shift_id: working_shift?.id,
                                                                        key: "start_time",
                                                                        value: r.target.value
                                                                    })
                                                                }}
                                                                type={'time'}
                                                                className={"p-1 border rounded w-100 p-2 time-input" + (errorBag["working_shift." + key + ".start_time"] ? '-danger' : null)}
                                                            />
                                                            <div
                                                                className={"text-xs text-danger my-1"}>{errorBag?.["working_shift." + key + ".start_time"]?.[0] || ''}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-6"}>
                                                    <div>
                                                        <div className={"fw-bold"}>Kết thúc
                                                            {quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.end_time`}) && canEdit === 1  &&
                                                                <span onClick={() => {
                                                                    setOnEditfields([...onEditFields, `working_shifts.${working_shift?.id}.end_time`])
                                                                }}
                                                                      className={"cursor-pointer ms-2 text-purple-bright"}>
                                                                    <EditOutlined/>Sửa</span>
                                                            }

                                                            {onInEdit({field: `working_shifts.${working_shift?.id}.end_time`}) &&
                                                                <span onClick={handleQuickUpdate}
                                                                      className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}><CheckCircleOutlined
                                                                    className={"me-2"}/>Cập nhật
                                                                    </span>
                                                            }
                                                        </div>
                                                        <div>
                                                            <input
                                                                disabled={quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.end_time`})}
                                                                value={working_shift?.end_time}
                                                                onChange={(r) => {
                                                                    upDateWorkingShift({
                                                                        working_shift_id: working_shift?.id,
                                                                        key: "end_time",
                                                                        value: r.target.value
                                                                    })
                                                                }}
                                                                type={'time'}
                                                                className={"p-1 border rounded w-100 p-2 time-input" + (errorBag["working_shift." + key + ".end_time"] ? '-danger' : null)}
                                                            />
                                                            <div
                                                                className={"text-xs text-danger my-1"}>{errorBag?.["working_shift." + key + ".end_time"]?.[0] || ''}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"mb-3"}>
                                                <div className={"fw-bold mb-1"}>Phòng học
                                                    {quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.room`}) && canEdit === 1  &&
                                                        <span onClick={() => {
                                                            setOnEditfields([...onEditFields, `working_shifts.${working_shift?.id}.room`])
                                                        }}
                                                              className={"cursor-pointer ms-2 text-purple-bright"}>
                                                                    <EditOutlined/>Sửa</span>
                                                    }

                                                    {onInEdit({field: `working_shifts.${working_shift?.id}.room`}) &&
                                                        <span onClick={handleQuickUpdate}
                                                              className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}><CheckCircleOutlined
                                                            className={"me-2"}/>Cập nhật
                                                                    </span>
                                                    }
                                                </div>
                                                <Input
                                                    disabled={quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.room`})}
                                                    onChange={(r) => upDateWorkingShift({
                                                        working_shift_id: working_shift?.id,
                                                        key: "room",
                                                        value: r.target.value
                                                    })}
                                                    value={working_shift?.room} addonBefore={<OrderedListOutlined/>}
                                                    placeholder={"Nhập phòng học"} size={"large"}/>
                                            </div>
                                            <div className={"mb-3"}>
                                                <div className={"fw-bold mb-1"}>Giáo viên
                                                    {quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.teacher_id`}) && canEdit === 1  &&
                                                        <span onClick={() => {
                                                            setOnEditfields([...onEditFields, `working_shifts.${working_shift?.id}.teacher_id`])
                                                        }}
                                                              className={"cursor-pointer ms-2 text-purple-bright"}>
                                                                    <EditOutlined/>Sửa</span>
                                                    }

                                                    {onInEdit({field: `working_shifts.${working_shift?.id}.teacher_id`}) &&
                                                        <span onClick={handleQuickUpdate}
                                                              className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}><CheckCircleOutlined
                                                            className={"me-2"}/>Cập nhật
                                                                    </span>
                                                    }
                                                </div>
                                                <Select
                                                    disabled={quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.teacher_id`})}
                                                    onChange={(r) => upDateWorkingShift({
                                                        working_shift_id: working_shift?.id,
                                                        key: "teacher_id",
                                                        value: r
                                                    })}
                                                    showSearch={true}
                                                    filterOption={(input, option) =>
                                                        (option?.label.toLowerCase() ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }

                                                    // value={mapSelectData({
                                                    //     key: "id",
                                                    //     label: "uuid",
                                                    //     prefix: "name",
                                                    //     data: teachers
                                                    // }).find(v => v?.id === working_shift?.teacher_id)}

                                                    value={working_shift?.teacher_id}

                                                    className={"w-100"}

                                                    size={"large"}

                                                    options={mapSelectData({
                                                        key: "id",
                                                        label: "uuid",
                                                        prefix: "name",
                                                        data: teachers
                                                    })}
                                                />
                                            </div>
                                            <div className={"mb-3"}>
                                                <div className={"fw-bold mb-1"}>Trợ giảng
                                                    {quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.supporter_id`}) && canEdit === 1  &&
                                                        <span onClick={() => {
                                                            setOnEditfields([...onEditFields, `working_shifts.${working_shift?.id}.supporter_id`])
                                                        }}
                                                              className={"cursor-pointer ms-2 text-purple-bright"}>
                                                                    <EditOutlined/>Sửa</span>
                                                    }
                                                    {onInEdit({field: `working_shifts.${working_shift?.id}.supporter_id`}) &&
                                                        <span onClick={handleQuickUpdate}
                                                              className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}><CheckCircleOutlined
                                                            className={"me-2"}/>Cập nhật
                                                                    </span>
                                                    }
                                                </div>
                                                <Select
                                                    disabled={quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.supporter_id`})}
                                                    onChange={(r) => upDateWorkingShift({
                                                        working_shift_id: working_shift?.id,
                                                        key: "supporter_id",
                                                        value: r
                                                    })}
                                                    showSearch={true}
                                                    filterOption={(input, option) =>
                                                        (option?.label.toLowerCase() ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    value={working_shift?.supporter_id}
                                                    // value={mapSelectData({
                                                    //     key: "id",
                                                    //     label: "uuid",
                                                    //     prefix: "name",
                                                    //     data: supporters
                                                    // }).find(v => v?.id === working_shift?.supporter_id)}

                                                    className={"w-100"}

                                                    size={"large"}

                                                    options={mapSelectData({
                                                        key: "id",
                                                        label: "uuid",
                                                        prefix: "name",
                                                        data: supporters
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-4"}>
                                        <div className={"bg-white p-2 rounded h-100"}>
                                            <div className={"fw-bold mb-1"}>Báo cáo của giáo viên
                                                {quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.teacher_comment`}) && canEdit === 1  &&
                                                    <span onClick={() => {
                                                        setOnEditfields([...onEditFields, `working_shifts.${working_shift?.id}.teacher_comment`])
                                                    }}
                                                          className={"cursor-pointer ms-2 text-purple-bright"}>
                                                                    <EditOutlined/>Sửa</span>
                                                }
                                                {onInEdit({field: `working_shifts.${working_shift?.id}.teacher_comment`}) &&
                                                    <span onClick={handleQuickUpdate}
                                                          className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}><CheckCircleOutlined
                                                        className={"me-2"}/>Cập nhật
                                                                    </span>
                                                }
                                            </div>
                                            <TextArea
                                                disabled={quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.teacher_comment`})}
                                                value={working_shift?.teacher_comment}
                                                onChange={(r) => {
                                                    upDateWorkingShift({
                                                        working_shift_id: working_shift?.id,
                                                        key: "teacher_comment",
                                                        value: r.target.value
                                                    })
                                                }}
                                                style={{minHeight: "88%"}} size={"large"}/>
                                        </div>
                                    </div>
                                    <div className={"col-4"}>
                                        <div className={"bg-white p-2 rounded h-100"}>
                                            <div className={"fw-bold mb-1"}>Báo cáo của trợ giảng
                                                {quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.supporter_comment`}) && canEdit === 1  &&
                                                    <span onClick={() => {
                                                        setOnEditfields([...onEditFields, `working_shifts.${working_shift?.id}.supporter_comment`])
                                                    }}
                                                          className={"cursor-pointer ms-2 text-purple-bright"}>
                                                                    <EditOutlined/>Sửa</span>
                                                }
                                                {onInEdit({field: `working_shifts.${working_shift?.id}.supporter_comment`}) &&
                                                    <span onClick={handleQuickUpdate}
                                                          className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}><CheckCircleOutlined
                                                        className={"me-2"}/>Cập nhật
                                                                    </span>
                                                }
                                            </div>
                                            <TextArea
                                                disabled={quickEditMode && !onInEdit({field: `working_shifts.${working_shift?.id}.supporter_comment`})}
                                                value={working_shift?.supporter_comment}
                                                onChange={(r) => {
                                                    upDateWorkingShift({
                                                        working_shift_id: working_shift?.id,
                                                        key: "supporter_comment",
                                                        value: r.target.value
                                                    })
                                                }}
                                                style={{minHeight: "88%"}} size={"large"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Badge.Ribbon>
                )}
            </div>

            {!quickEditMode &&
                <div className={"text-purple-bright my-2 fw-bold cursor-pointer mb-5"} onClick={addWorkingShift}>
                    <AppstoreAddOutlined/>
                    <span className={"ms-2 fw-bold "}>Thêm ca học</span>
                </div>
            }
            <div className={"card_logs"}>
                <StudyCardLogsFormComponent
                    data={data} setData={setData}
                    errorBags={[]}
                    onEditFields={onEditFields}
                    setOnEditfields={setOnEditfields}
                    onInEdit={onInEdit}
                    handleQuickUpdate={handleQuickUpdate}
                    quickEditMode={quickEditMode}
                    canEdit={canEdit}
                    id={id}
                />
            </div>

            <div className={"notes my-2 mt-3"}>
                <div className={"fw-bold mb-1"}>Ghi chú học sinh chưa có thẻ học
                    {quickEditMode && !onInEdit({field: `notes`}) && canEdit === 1  &&
                        <span onClick={() => {
                            setOnEditfields([...onEditFields, `notes`])
                        }}
                              className={"cursor-pointer ms-2 text-purple-bright"}>
                                                                    <EditOutlined/>Sửa</span>
                    }
                    {onInEdit({field: `notes`}) &&
                        <span onClick={handleQuickUpdate}
                              className={"cursor-pointer fw-bold ms-2 text-success p-2 rounded mb-2"}><CheckCircleOutlined
                            className={"me-2"}/>Cập nhật
                                                                    </span>
                    }
                </div>
                <TextArea
                    disabled={quickEditMode  && !onInEdit({field: `notes`})}
                    value={data?.notes}
                    onChange={(r) => setData({...data, notes: r.target.value})}
                    style={{minHeight: 100}} size={"large"}/>
            </div>
            <div className={"pb-5"}></div>

            {!quickEditMode &&
                <div onClick={handleCreateStudylog}
                     className={"position-fixed bg-purple-bright text-white p-2 rounded cursor-pointer"}
                     style={{bottom: 35, right: 35}}>Điểm danh
                </div>
            }
        </div>
    )
}

export default StudyLogFormComponent