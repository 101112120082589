import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllBranch = async (type) => {
    return await (new Api()).get(`branches?fields=*`, {}, getAuthHeader());
}

export const createBranchAction = async (data) => {
    return await (new Api()).post('branches', data, getAuthHeader())
}

export const accessBranchAction = async (id) => {
    return await (new Api()).put(`branches/access/${id}`, {}, getAuthHeader())
}