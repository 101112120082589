import {Input, Modal, Select, Tooltip} from "antd";
import {useEffect, useState} from "react";
import {createRoleAction, getAllRolesAction} from "../../_api/RoleApi";
import {InfoCircleFilled} from "@ant-design/icons";
import {mapSelectData} from "../../_classes/Helper";
import Notification from "../../_classes/Notification";

const CreateRoleModal = ({callback, isModalOpen, setIsModalOpen}) => {
    const [roles, setRoles] = useState([])
    const [role, setRole] = useState({})
    const [errorBag, setErrorBag] = useState([])
    useEffect(() => {
        if (isModalOpen) {
            getAllRolesAction({
                fields: "id,role_name"
            }).then((r) => {
                if (r?.success) {
                    setRoles(r?.data?.data)
                }
            })
        }
    }, [isModalOpen]);
    const handleOk = () => {
        createRoleAction(role).then((r) => {
            if (r?.success) {
                Notification.success(r?.message)
                callback()
                setIsModalOpen(false)
            } else {
                setErrorBag(r?.errors)
            }
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Modal title="Thêm nhóm quyền mới" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                   okText={"Thêm mới"} cancelText={"Huỷ"}>
                <div className={"my-2"}>
                    <div className={"fw-bold mb-1"}>Nhóm quyền gốc
                        <Tooltip className={"ms-2"} title={"Khi tạo mới sẽ copy toàn bộ quyền của nhóm quyền gốc"}>
                            <InfoCircleFilled className={"text-purple-bright"}/>
                        </Tooltip>
                    </div>
                    <Select
                        options={mapSelectData({
                            key: "id",
                            label: "role_name",
                            data: roles
                        })}
                        onChange={(r) => {
                            setRole({...role, parent_id: r})
                        }}
                        className={"w-100"}/>
                    <div className={"my-2"}>
                        <div className={"fw-bold mb-1"}>Tên nhóm quyền mới</div>
                        <Input
                            status={errorBag?.role_name ? "error" : null}
                            value={role?.role_name}
                            onChange={(r) => setRole({...role, role_name: r?.target?.value})}
                        />
                        <div className={"text-xs text-danger mt-1"}>{errorBag?.role_name}</div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default CreateRoleModal