import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import InputFormComponent from "../../_components/InputFormComponent";
import {useEffect, useState} from "react";
import {getCommonFormRequestStruct} from "../../_api/CommonApi";
import Notification from "../../_classes/Notification";
import {useNavigate} from "react-router-dom";
import {BackwardFilled} from "@ant-design/icons";
import {createCardAction} from "../../_api/CardApi";
import {createClassroomAction} from "../../_api/ClassroomApi";

const CreateClassroomScreen = () => {
    const [formStruct, setFormStruct] = useState([])
    const [classroom, setClassroom] = useState({})
    const nav = useNavigate();
    const [errorBag,setErrorBag] = useState([])
    useEffect(() => {
        getCommonFormRequestStruct('classroom').then((r) => {
            setFormStruct(r.data)
        })
    }, []);

    const handleCreateStaff = () => {
        createClassroomAction(classroom).then((r) => {
            if (r?.success === true) {
                Notification.success(r?.data?.message)
                nav(-1)
            } else {
                setErrorBag(r?.errors)
            }
        })
    }
    return (
        <AuthenticatedLayout>
            <div className={"h4 text-dark"}>
                <span onClick={() => {
                    nav(-1)
                }} className={"text-xs text-purple-bright cursor-pointer mx-2"}>
                <BackwardFilled/>
                Trở về
                </span>
                <span>Thêm mới lớp học</span>
            </div>
            <div className={"my-4"}>
                <InputFormComponent
                    formStruct={formStruct}
                    form={classroom}
                    setForm={setClassroom}
                    errorBag={errorBag}
                />
            </div>
            <button onClick={handleCreateStaff} className={"btn-purple-bright mt-2 p-2 px-3 mx-3"}>Thêm mới
            </button>
        </AuthenticatedLayout>
    )
}

export default CreateClassroomScreen