import * as React from 'react';
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {PickersDay} from '@mui/x-date-pickers/PickersDay';
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar';
import {DayCalendarSkeleton} from '@mui/x-date-pickers/DayCalendarSkeleton';
import {useEffect} from "react";

function ServerDay(props) {
    const {highlightedDays = [], day, outsideCurrentMonth, ...other} = props;

    const isSelected =
        !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

    return (
        <PickersDay
            sx={{color: isSelected ? "white" : null, backgroundColor: isSelected ? "#56ca00" : null}} {...other}
            outsideCurrentMonth={outsideCurrentMonth} day={day}/>
    );
}

const CalendarCardComponent = ({highlightedDates, onDateClick, value, handleMonthChange}) => {
    const [highlightedDays, setHighlightedDays] = React.useState([]);

    useEffect(() => {
        setHighlightedDays(highlightedDates)
    }, [highlightedDates])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                className={"w-100"}
                value={value}
                onMonthChange={handleMonthChange}
                onChange={onDateClick}
                dayOfWeekFormatter={formatDayOfWeek}
                renderLoading={() => <DayCalendarSkeleton/>}
                slots={{
                    day: ServerDay,
                }}
                slotProps={{
                    day: {
                        highlightedDays,
                    },
                }}
            />
        </LocalizationProvider>
    );
}
export default CalendarCardComponent

const formatDayOfWeek = (date) => {
    const daysOfWeek = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];
    return daysOfWeek[date.$W];
};