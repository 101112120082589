const MonthColumn = ({value, record}) => {
    const date = new Date(value);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${month}/${year}`;
    return (
        <div>
            {value ? formattedDate : null}
        </div>
    )
}

export default MonthColumn