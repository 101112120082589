import {useEffect} from "react";
import {getScheduleByCardAndStudyLogDayAction} from "../_api/ClassroomApi";
import {useSelector} from "react-redux";

const SelectScheduleComponent = ({setData, data}) => {
    useEffect(() => {
        if (data?.studylog_day && data?.classroom_id) {
            getScheduleByCardAndStudyLogDayAction(data?.classroom_id, data?.studylog_day).then((r) => {
                if (r?.success) {
                    setData({
                        ...data,
                        validSchedule: r?.data,
                        working_shifts: r?.data?.shifts,
                        classroom_schedule_id: undefined
                    })
                }
            })
        }
    }, [data?.studylog_day]);

    const commonUsers = useSelector((state) => state.common?.users)

    const getUserInfo = (id) => {
        return commonUsers.find((u) => u?.id === id)
    }
    if (data?.validSchedule)
        return (
            <div>
                <div className={''}>
                    <div className={"row m-0"}>
                        <div className={"p-1"}>
                            {data?.validSchedule?.id !== null &&
                                <div
                                    className={"p-1 px-2 bg-label-purple text-purple-bright rounded cursor-pointer schedule"}>
                                    <div
                                        className={"fw-bold"}> {data?.validSchedule?.week_day === 8 ? 'Chủ Nhật' : 'Thứ ' + data?.validSchedule?.week_day}</div>
                                    <div
                                        className={""}>{data?.validSchedule?.start_time} - {data?.validSchedule?.end_time}</div>
                                </div>
                            }

                            {data?.validSchedule?.id === null &&
                                <div
                                    className={"p-1 px-2 bg-label-purple text-purple-bright rounded cursor-pointer schedule text-center py-2"}>
                                    Không có lịch học phù hợp
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <hr/>
                </div>
                {data?.validSchedule?.id !== null &&
                    <div>
                        {data?.validSchedule?.shifts?.map((shift, key) =>
                            <div key={key}
                                 className={'bg-label-purple text-purple-bright p-2 rounded cursor-pointer mb-3'}>
                                <div className={"d-flex"}>
                                    <div className={"fw-bold"}>Ca học {key + 1}</div>
                                    <div className={"ms-2"}>{shift?.start_time} - {shift?.end_time}</div>
                                </div>
                                <div className={""}>
                                    <div>Giáo
                                        viên: {getUserInfo(shift?.teacher_id)?.name} ({getUserInfo(shift?.teacher_id)?.uuid})
                                    </div>
                                    <div>Trợ
                                        giảng: {getUserInfo(shift?.supporter_id)?.name} ({getUserInfo(shift?.supporter_id)?.uuid || 'Không có'})
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
                {!data?.classroom_schedule_id &&
                    <div>
                        {
                            data?.validSchedule?.id &&
                            <div onClick={() => {
                                setData({...data, classroom_schedule_id: data?.validSchedule?.id})
                            }} className={"btn btn-hologram fw-bold text-white w-100 cursor-pointer mb-3"}>Điểm danh buổi học này</div>
                        }
                        {
                            data?.classroom_id &&
                            <div
                                onClick={() => {
                                    setData({...data, classroom_schedule_id: -1, working_shifts: []})
                                }}
                                className={"text-purple-bright cursor-pointer"}>Điểm danh buổi học khác (Không theo
                                setting)</div>
                        }
                    </div>
                }
            </div>
        )
    else return null
}
export default SelectScheduleComponent