import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllSalaryConfigAction = async (query) => {
    return await (new Api()).get('salary_configs', query, getAuthHeader());
}

export const syncSalaryConfigAction = async () => {
    return await (new Api()).post('salary_configs/sync', {}, getAuthHeader())
}

export const getOneSalaryConfigAction = async (id, data) => {
    return await (new Api()).get(`salary_configs/${id}`, data, getAuthHeader())
}

export const updateSalaryConfigAction = async (data, id) => {
    return await (new Api()).put(`salary_configs/${id}`, data, getAuthHeader())
}

export const deleteSalaryConfigAction = async (id) => {
    return await (new Api()).delete(`salary_configs/${id}`, getAuthHeader())
}

