import {Input, Modal, Select, Tooltip} from "antd";
import {useEffect, useState} from "react";
import {createRoleAction, getAllRolesAction, getOneRoleAction, updateRoleAction} from "../../_api/RoleApi";
import {InfoCircleFilled} from "@ant-design/icons";
import {mapSelectData} from "../../_classes/Helper";
import Notification from "../../_classes/Notification";

const UpdateRoleModal = ({callback, isModalOpen, setIsModalOpen, id}) => {
    const [role, setRole] = useState({})
    const [errorBag, setErrorBag] = useState([])

    useEffect(() => {
        getOneRoleAction(id, {
            fields: "id,role_name"
        }).then((r) => {
            if (r?.success) {
                setRole(r?.data)
            }
        })
    }, [id]);
    const handleOk = () => {
        updateRoleAction(role, id).then((r) => {
            if (r?.success) {
                Notification.success(r?.message)
                callback()
                setIsModalOpen(false)
            } else {
                setErrorBag(r?.errors)
            }
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Modal title="Thêm nhóm quyền mới" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                   okText={"Cập nhật"} cancelText={"Huỷ"}>
                <div className={"my-2"}>
                    <div className={"fw-bold mb-1"}>Tên nhóm quyền</div>
                    <Input
                        status={errorBag?.role_name ? "error" : null}
                        value={role?.role_name}
                        onChange={(r) => setRole({...role, role_name: r?.target?.value})}
                    />
                    <div className={"text-xs text-danger mt-1"}>{errorBag?.role_name}</div>
                </div>
            </Modal>
        </>
    );
}

export default UpdateRoleModal