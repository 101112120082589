import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllClassroomsAction = async (query) => {
    return await (new Api()).get('classrooms', query, getAuthHeader());
}

export const getAllSharedClassroomsAction = async (query) => {
    return await (new Api()).get('classrooms/s', query, getAuthHeader());
}

export const createClassroomAction = async (data) => {
    return await (new Api()).post('classrooms', data, getAuthHeader())
}

export const getOneClassroomAction = async (id, query = {}) => {
    return await (new Api()).get(`classrooms/${id}`, query, getAuthHeader())
}

export const updateClassroomAction = async (data, id) => {
    return await (new Api()).put(`classrooms/${id}`, data, getAuthHeader())
}

export const getClassroomHighlightDays = async (month, id) => {
    return await (new Api()).get(`classrooms/${id}/highlight_days/${month || 0}`, {}, getAuthHeader())
}

export const getScheduleByCardAndStudyLogDayAction = async (classroom_id, studylog_day) => {
    return await (new Api()).get(`classrooms/${classroom_id}/schedule/${studylog_day}`, {}, getAuthHeader())
}
