import {Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";

const CriteriaNameColumn = ({value, record}) => {
    return (
        <div className={"text-start"}>
            <div className={"fw-bold"}>
                <span>{record?.criteria_name}</span>
                {record?.is_system_criteria === 1 &&
                    <Tooltip className={"ms-2"} title={'Cài đặt mặc định của hệ thống'}>
                        <QuestionCircleOutlined className={"text-purple-bright cursor-pointer"}/>
                    </Tooltip>
                }
            </div>
            <div className={"text-xs text-muted"}>{record?.criteria_description || record?.criteria_name}</div>
        </div>
    )
}

export default CriteriaNameColumn