import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllRolesAction = async (query) => {
    return await (new Api()).get('roles', query, getAuthHeader());
}

export const getAllRolesTableAction = async (query) => {
    return await (new Api()).get('roles/table', query, getAuthHeader());
}

export const createRoleAction = async (data) => {
    return await (new Api()).post('roles', data, getAuthHeader())
}

export const getOneRoleAction = async (id, data) => {
    return await (new Api()).get(`roles/${id}`, data, getAuthHeader())
}

export const updateRoleAction = async (data, id) => {
    return await (new Api()).put(`roles/${id}`, data, getAuthHeader())
}
export const deleteRoleAction = async (id) => {
    return await (new Api()).delete(`roles/${id}`, getAuthHeader())
}

