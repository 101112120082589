const StatisticColumn = ({value, record}) => {
    return (
        <div className={"text-start small"}>
            {record?.statistics?.map((statisticData, key) =>
                <div key={key} className={[statisticData?.color, 'fw-bold'].join(' ')}>
                    {statisticData?.label} : {statisticData?.value}
                </div>
            )}
        </div>
    )
}

export default StatisticColumn