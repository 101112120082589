import {Tooltip} from "antd";

const WorkingShiftsColumnComponent = ({value, record}) => {
    return (
        <div className={"text-start d-flex justify-content-center"}>
            {record?.related_users?.map((user, key) =>
                <div className={"cursor-pointer"}>
                    <Tooltip title={user?.name}>
                        <div className={"rounded"}>
                            <img alt={""} src={user?.avatar} style={{width: '2rem'}} className={"rounded-circle ms-2 border"}/>
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    )
}

export default WorkingShiftsColumnComponent