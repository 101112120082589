import {useNavigate} from "react-router-dom";

const UserInfoColumnComponent = ({id, record}) => {
    const nav = useNavigate()
    const handleEdit = () => {
        nav(`/${record?.user_abilities?.entity}/edit/${record?.id}`)
    }
    return (
        <div onClick={handleEdit} className={"d-flex align-items-center"}>
            <div className={"rounded-circle me-2"}>
                {(record?.avatar || record?.user?.avatar) &&
                    <img alt={"pink"}

                         src={record?.avatar || record?.user?.avatar} style={{width: "2.5rem"}}
                         className={"rounded-circle cursor-pointer"}/>
                }
            </div>
            <div className={"cursor-pointer"}>
                <div className={"fw-semi text-purple"}>{record?.name || record?.user?.name}</div>
                <div className={"small text-purple"}>{record?.uuid || record?.user?.uuid}</div>
            </div>
        </div>
    )
}

export default UserInfoColumnComponent