import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {Select} from "antd";
import {useEffect, useMemo, useState} from "react";
import {
    getAllSharedClassroomsAction,
    getClassroomHighlightDays,
} from "../../_api/ClassroomApi";
import {addVariableToUrl, formatDateYmd, handleParseUrlVariables, mapSelectData} from "../../_classes/Helper";
import {useLocation, useNavigate} from "react-router-dom";
import CalendarCardComponent from "../../_components/CalendarCardComponent";
import dayjs from "dayjs";
import SelectScheduleComponent from "../../_components/SelectScheduleComponent";
import EmptyImage from "../../_assets/images/empty.webp"
import StudyLogFormComponent from "../../_components/StudyLogFormComponent";

const CreateStudyLogScreen = () => {
    const [classrooms, setClassrooms] = useState([])
    const [highlightDays, setHighlightDays] = useState()
    const [currenMonth, setCurrentMonth] = useState(0)
    const [data, setData] = useState({})
    const nav = useNavigate()
    const location = useLocation();
    useEffect(() => {
        if (data?.studylog_day && currenMonth === 0) {
            setCurrentMonth(data?.studylog_day);
        }
    }, [])

    useMemo(() => {
        getAllSharedClassroomsAction({
            limit: 100000
        }).then((r) => {
            if (r?.success) {
                setClassrooms(r?.data?.data)
            }
        })
    }, []);

    useEffect(() => {
        if (data?.classroom_id) {
            getClassroomHighlightDays(currenMonth, data?.classroom_id).then((r) => {
                setHighlightDays(r?.data)
            })
        }
    }, [currenMonth, data?.classroom_id])

    useEffect(() => {
        if (data?.classroom_id) {
            const urlPath = addVariableToUrl({
                data: data,
                paramsKey: ["classroom_id", "schedule_id", 'studylog_day']
            })

            nav(urlPath)

        }
    }, [data]);

    useMemo(() => {
        const searchParams = location.search.substring(1);
        const parseParams = handleParseUrlVariables(searchParams)

        setData({...data, ...parseParams})

    }, [location.search]);

    const handleDateClick = (date) => {
        setData({
            ...data,
            studylog_day: formatDateYmd(date)
        })
    };

    useEffect(() => {
        const {classroom_schedule_id: removed, ...rest} = data;

        setData(rest)

    }, [data?.classroom_id, data?.studylog_day]);

    return (
        <AuthenticatedLayout layout={false}>
            <div className={"row"}>
                <div className={'col-xl-3 col-lg-4 col-12 position-relative top-0'}>
                    <div className={"position-sticky top-0"}>
                        <div className={"select_classroom bg-white p-3 rounded mb-3"}>
                            <Select
                                placeholder={"Chọn lớp điểm danh"}
                                className={"w-100"}
                                options={mapSelectData({
                                    key: 'id',
                                    label: 'uuid',
                                    prefix: 'name',
                                    data: classrooms || []
                                })}
                                size={"large"}
                                value={data?.classroom_id ? parseInt(data?.classroom_id) : null}
                                onChange={(r) => {
                                    setData({
                                        ...data,
                                        classroom_id: r
                                    })
                                }}
                                showSearch={true}
                                filterOption={(input, option) =>
                                    (option?.label.toLowerCase() ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </div>
                        <div className={"select_days bg-white p-3 rounded mb-3"}>
                            <CalendarCardComponent
                                highlightedDates={highlightDays}
                                onDateClick={handleDateClick}
                                value={data?.studylog_day ? dayjs(data?.studylog_day) : null}
                                handleMonthChange={(r) => {
                                    setData({...data, studylog_day: undefined})
                                    setCurrentMonth(formatDateYmd(r))
                                }}
                            />
                        </div>
                        <div className={"select_days bg-white p-3 rounded"}>
                            <SelectScheduleComponent
                                setData={setData}
                                data={data}
                            />
                        </div>
                    </div>
                </div>
                {(data?.classroom_schedule_id !== null && data?.classroom_schedule_id !== undefined) ?
                    <div className={"col-xl-9 col-lg-8 col-12"}>
                        <div className={"bg-white p-3 rounded mb-3"}>
                            <StudyLogFormComponent
                                data={data}
                                setData={setData}
                                errorBag={[]}
                            />
                        </div>
                    </div>
                    :
                    <div className={"col-xl-9 col-lg-8 col-12"}>
                        <div className={"d-flex justify-content-center align-items-center h-100"}>
                            <div className={"text-center"}>
                                <img alt={""} src={EmptyImage} style={{width: "5rem"}}/>
                                <div className={"fw-bold"}> Vui lòng chọn buổi học để điểm danh</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </AuthenticatedLayout>
    )
}

export default CreateStudyLogScreen