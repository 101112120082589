import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllSalarySnapAction = async (query) => {
    return await (new Api()).get('salary_snaps', query, getAuthHeader());
}

export const getOneSalarySnapAction = async (id, data) => {
    return await (new Api()).get(`salary_snaps/${id}`, data, getAuthHeader())
}

export const getOneCloseSalarySnapAction = async (id, data) => {
    return await (new Api()).get(`salary_snaps/${id}/close`, data, getAuthHeader())
}

export const closeSalarySnapAction = async (id) => {
    return await (new Api()).put(`salary_snaps/${id}/close`, {}, getAuthHeader())
}

export const recalculateSalarySnapAction = async (id, data) => {
    return await (new Api()).put(`salary_snaps/${id}/recalculate`, {}, getAuthHeader())
}

export const recalculateAllSalarySnapAction = async () => {
    return await (new Api()).post(`salary_snaps/recalculate`, {}, getAuthHeader())
}

export const updateSalarySnapManualValue = async (data) => {
    return await (new Api()).post(`salary_snaps/snap_detail/manual_update`, data, getAuthHeader())
}