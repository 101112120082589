import AuthenticatedLayout from "./AuthenticatedLayout";
import {Tabs} from "antd";
import {useNavigate} from "react-router-dom";

const SettingLayout = ({children, tab}) => {
    const nav = useNavigate()
    const items = [
        {
            key: 'role',
            label: 'Cấu hình phân quyền',
        },
        {
            key: 'integration',
            label: 'Tích hợp',
        },
        {
            key: 'salary_criteria',
            label: 'Cấu hình lương',
        }
    ];

    const handleSwitchTab = (event) => {
        nav(`/settings/${event}`)
    }

    return (
        <AuthenticatedLayout>
            <div>
                <Tabs defaultActiveKey={tab} items={items} onChange={handleSwitchTab}/>
                {children}
            </div>
        </AuthenticatedLayout>
    )
}

export default SettingLayout