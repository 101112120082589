import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import InputFormComponent from "../../_components/InputFormComponent";
import {useEffect, useState} from "react";
import {getCommonFormRequestStruct} from "../../_api/CommonApi";
import {createStaffAction} from "../../_api/StaffApi";
import Notification from "../../_classes/Notification";
import {useNavigate} from "react-router-dom";
import {BackwardFilled} from "@ant-design/icons";

const CreateStaffsScreen = () => {
    const [formStruct, setFormStruct] = useState([])
    const [staff, setStaff] = useState({})
    const nav = useNavigate();
    const [errorBag,setErrorBag] = useState([])
    useEffect(() => {
        getCommonFormRequestStruct('staff').then((r) => {
            setFormStruct(r.data)
        })
    }, []);

    const handleCreateStaff = () => {
        createStaffAction(staff).then((r) => {
            if (r?.success === true) {
                Notification.success(r?.data?.message)
                nav(-1)
            } else {
                setErrorBag(r?.errors)
            }
        })
    }
    return (
        <AuthenticatedLayout>
            <div className={"h4 text-dark"}>
                <span onClick={() => {
                    nav(-1)
                }} className={"text-xs text-purple-bright cursor-pointer mx-2"}>
                <BackwardFilled/>
                Trở về
                </span>
                <span>Thêm mới nhân viên</span>
            </div>
            <div className={"my-4"}>
                <InputFormComponent
                    formStruct={formStruct}
                    form={staff}
                    setForm={setStaff}
                    errorBag={errorBag}
                />
            </div>
            <button onClick={handleCreateStaff} className={"btn-purple-bright mt-2 p-2 px-3 mx-3"}>Thêm mới
            </button>
        </AuthenticatedLayout>
    )
}

export default CreateStaffsScreen