import {Col, Progress, Row} from "antd";
import {CheckCircleFilled, PlayCircleFilled, WarningFilled} from "@ant-design/icons";

const HomeImportStepComponent = () => {
    return (
        <div className={"bg-white shadow-sm p-2 w-100"}>
            <div className={"fw-bold text-purple-bright text-center h6 my-2 mb-3"}>Số hoá trung tâm của bạn</div>
            <div className={"w-100"}>
                <div className={"my-2 text-center"}>
                    <Progress type="circle" percent={75} />
                </div>
                <div>
                    <div className={"text-success fw-bold mb-1 d-flex justify-content-between"}>Import nhân viên <CheckCircleFilled className={"ms-1"}/></div>
                    <div className={"text-success fw-bold mb-1 d-flex justify-content-between"}>Import giáo viên <CheckCircleFilled className={"ms-1"}/></div>
                    <div className={"text-success fw-bold mb-1 d-flex justify-content-between"}>Import trợ giảng <CheckCircleFilled className={"ms-1"}/></div>
                    <div className={"text-purple-bright fw-bold mb-1 d-flex justify-content-between"}>Import Lớp học <PlayCircleFilled className={"ms-1"}/></div>
                    <div className={"text-muted fw-bold mb-1 d-flex justify-content-between"}>Import trợ giảng <WarningFilled className={"ms-1"}/></div>
                </div>
            </div>
        </div>
    )
}
export default HomeImportStepComponent