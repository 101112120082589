import React, {useEffect, useMemo, useState} from 'react';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {Flex, message, Upload} from 'antd';
import Notification from "../../_classes/Notification";
import {getUploadUrl} from "../../_classes/Helper";
import {toast} from "react-toastify";

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        Notification.warn('Chỉ có thể chọn JPG hoặc PNG');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        Notification.warn('Ảnh vượt quá 2MB');
    }
    return isJpgOrPng && isLt2M;
};
const AvatarField = ({value, setValue, placeholder}) => {
    const [loading, setLoading] = useState(false);
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            toast.success("Tải ảnh lên thành công", {
                position:"top-center"
            })
            setLoading(false);
            setValue(info.file.response.file);
        }
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {placeholder}
            </div>
        </button>
    );
    return (
        <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={getUploadUrl}
            beforeUpload={beforeUpload}
            onChange={handleChange}
        >
            {value ? (
                <img
                    src={value}
                    alt="avatar"
                    style={{
                        width: '100%',
                    }}
                />
            ) : (
                uploadButton
            )}
        </Upload>
    );
};
export default AvatarField;