import {Checkbox, Input, InputNumber, Select} from "antd";
import AvatarField from "./Fields/AvatarField";
import {convertToData} from "../_classes/Helper";
import TextArea from "antd/lib/input/TextArea";
import DateField from "./Fields/DateField";
import moment from 'moment';
import CustomInputForm from "./CustomInputForm";
import FileField from "./Fields/FileField";


const InputFormComponent = ({formStruct, form, setForm, errorBag, ignoreUpdate = [], drawer = false}) => {
    const setDataStruct = ({key, value, formInput}) => {
        if (formInput?.parent !== undefined && formInput?.parent !== null) {
            setForm({
                ...form, [formInput?.parent]: {
                    ...(form[formInput?.parent]), [key]: value
                }
            })
        } else {
            setForm({
                ...form, [key]: value
            })
        }
    }

    const getDataStruct = ({formInput, form}) => {
        let value = null;

        if (formInput?.parent) {
            value = form[formInput?.parent] ? form[formInput?.parent][formInput?.name] : null
        } else {
            value = form[formInput?.name];
        }

        if (formInput?.type === 'date') {
            if (typeof value === 'string') {
                return moment(value)
            }
            return value;
        }

        return value
    }

    const getSelectOption = ({formInput, form, defaultValue = null}) => {
        const selectValue = formInput?.parent ? form[formInput?.parent][formInput?.name] : form[formInput?.name];

        return {
            value: selectValue,
            label: formInput?.options[selectValue ?? defaultValue]
        }
    }

    const getMultipleSelectOption = ({formInput, form}) => {
        const selectValue = formInput?.parent ? form[formInput?.parent][formInput?.name] : form[formInput?.name];

        return (selectValue || []).map((single) => {
            return {
                value: single,
                label: formInput?.options[single]
            }
        })
    }

    return (
        <div className={"container-fluid"}>
            <div className={`row m-0 ${drawer ? "col-lg-12" : "col-lg-8"} col-12 border p-2 py-4 rounded bg-light`}>
                {formStruct?.map((formInput, key) =>
                    <div key={key} className={`col-${formInput?.size || 6} mb-2`}>
                        {
                            formInput?.type !== 'checkbox' && formInput?.type !== 'radio' &&
                            <div className={'mb-1 text-black fw-bold'}>{formInput?.label || formInput?.name}</div>
                        }

                        <CustomInputForm
                            formInput={formInput}
                            key={key}
                            getDataStruct={getDataStruct}
                            setDataStruct={setDataStruct}
                            attribute={form}
                            setAttribute={setForm}
                            errorBag={errorBag}
                        />

                        {(formInput?.type === undefined || formInput?.type === 'text') &&
                            <Input
                                disabled={ignoreUpdate.includes(formInput?.name)}
                                status={errorBag[formInput?.name] ? "error" : null}
                                value={getDataStruct({formInput: formInput, form: form})}
                                onChange={(r) => {
                                    setDataStruct({
                                        key: formInput?.name,
                                        value: r.target.value,
                                        formInput: formInput
                                    })
                                }}
                                rootClassName={'p-2'}
                                placeholder={formInput?.placeholder} key={formInput?.name}
                            />
                        }
                        {(formInput?.type === 'number') &&
                            <InputNumber
                                size={"small"}
                                className={"w-100"}
                                disabled={ignoreUpdate.includes(formInput?.name)}
                                status={errorBag[formInput?.name] ? "error" : null}
                                value={getDataStruct({formInput: formInput, form: form})}
                                onChange={(r) => {
                                    setDataStruct({
                                        key: formInput?.name,
                                        value: r,
                                        formInput: formInput
                                    })
                                }}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                rootClassName={'p-2'}
                                placeholder={formInput?.placeholder} key={formInput?.name}
                            />
                        }
                        {(formInput?.type === 'textarea') &&
                            <TextArea
                                status={errorBag[formInput?.name] ? "error" : null}
                                disabled={ignoreUpdate.includes(formInput?.name)}
                                value={getDataStruct({formInput: formInput, form: form})}
                                onChange={(r) => {
                                    setDataStruct({
                                        key: formInput?.name,
                                        value: r.target.value,
                                        formInput: formInput
                                    })
                                }}
                                rootClassName={'p-2'}
                                placeholder={formInput?.placeholder} key={formInput?.name}/>
                        }
                        {formInput?.type === 'date' &&
                            <div>
                                <DateField
                                    status={errorBag[formInput?.name] ? "error" : null}
                                    disabled={ignoreUpdate.includes(formInput?.name)}

                                    value={getDataStruct({
                                        formInput: formInput,
                                        form: form
                                    })}

                                    setValue={(r) => {
                                        if (r) {
                                            setDataStruct({
                                                key: formInput?.name,
                                                value: r,
                                                formInput: formInput
                                            })
                                        }
                                    }}
                                />
                            </div>
                        }

                        {(formInput?.type === 'avatar') &&
                            <AvatarField
                                value={getDataStruct({formInput: formInput, form: form})}
                                setValue={(value) => {
                                    setDataStruct({
                                        key: formInput?.name,
                                        value: value,
                                        formInput: formInput
                                    })
                                }}/>
                        }
                        {(formInput?.type === 'file') &&
                            <FileField
                                value={getDataStruct({formInput: formInput, form: form})}
                                setValue={(value) => {
                                    setDataStruct({
                                        key: formInput?.name,
                                        value: value,
                                        formInput: formInput
                                    })
                                }}
                                placeholder={"Tải tài liệu lên"}
                            />
                        }
                        {(formInput?.type === 'select') &&
                            <Select
                                showSearch
                                status={errorBag[formInput?.name] ? "error" : null}
                                filterOption={(input, option) =>
                                    (option?.label.toLowerCase() ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                defaultValue={0}
                                allowClear

                                style={{
                                    width: "100%",
                                    height: 40
                                }}

                                popupClassName={"p-2"}

                                value={getSelectOption({formInput: formInput, form: form, defaultValue:formInput?.default})}

                                onChange={(r) => {
                                    setDataStruct({
                                        key: formInput?.name,
                                        value: r,
                                        formInput: formInput
                                    })
                                }}

                                disabled={ignoreUpdate.includes(formInput?.name)}

                                options={convertToData({
                                    data: formInput?.options
                                }) || []}
                            />
                        }
                        {(formInput?.type === 'select_multiple') &&
                            <Select
                                mode={"multiple"}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label.toLowerCase() ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                status={errorBag[formInput?.name] ? "error" : null}
                                allowClear

                                style={{
                                    width: "100%",
                                }}

                                rootClassName={"fw-bold text-purple-bright"}
                                popupClassName={"p-2"}
                                size={"large"}
                                value={getMultipleSelectOption({formInput: formInput, form: form})}
                                defaultValue={formInput?.default}
                                onChange={(r) => {
                                    setDataStruct({
                                        key: formInput?.name,
                                        value: r,
                                        formInput: formInput
                                    })
                                }}


                                disabled={ignoreUpdate.includes(formInput?.name)}

                                options={convertToData({
                                    data: formInput?.options
                                }).filter((v) => {
                                    return !(getMultipleSelectOption({
                                        formInput: formInput,
                                        form: form
                                    }) || []).includes(v)
                                }) || []}
                            />
                        }

                        {(formInput?.type === 'checkbox') &&
                            <Checkbox
                                checked={getDataStruct({formInput: formInput, form: form})}
                                onChange={(r) => {
                                    setDataStruct({
                                        key: formInput?.name,
                                        value: r.target.checked,
                                        formInput: formInput
                                    })
                                }}
                                className={"my-2"}>
                                {formInput?.label}
                            </Checkbox>
                        }
                        <div className={"text-xs text-danger mt-1"}>{errorBag[formInput?.name]}</div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default InputFormComponent