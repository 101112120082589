import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import InputFormComponent from "../../_components/InputFormComponent";
import {useMemo, useState} from "react";
import {getCommonFormRequestStruct} from "../../_api/CommonApi";
import Notification from "../../_classes/Notification";
import {useNavigate, useParams} from "react-router-dom";
import {BackwardFilled} from "@ant-design/icons";
import {getOneSupporterAction, updateSupporterAction} from "../../_api/SupporterApi";
import {getOneStudentAction, updateStudentAction} from "../../_api/StudentApi";

const UpdateStudentScreen = () => {
    const [formStruct, setFormStruct] = useState([])
    const [student, setStudent] = useState({})
    const nav = useNavigate();
    const {id} = useParams();
    const [errorBag, setErrorBag] = useState([])

    useMemo(() => {
        getCommonFormRequestStruct('student').then((r) => {
            setFormStruct(r.data)
        })
    }, []);

    useMemo(() => {
        if (id !== undefined) {
            getOneStudentAction(id).then((response) => {
                setStudent(response?.data)
            })
        }
    }, [id]);

    const handleUpdateStaff = () => {
        updateStudentAction(student, id).then((r) => {
            if (r?.success) {
                Notification.success(r?.data?.message)
                nav(-1)
            } else {
                setErrorBag(r?.errors)
            }
        })
    }
    return (
        <AuthenticatedLayout>
            <div className={"h4 text-dark"}>
                <span onClick={() => {
                    nav(-1)
                }} className={"text-xs text-purple-bright cursor-pointer mx-2"}>
                <BackwardFilled/>
                Trở về
                </span>
                <span>Chỉnh sửa học sinh</span>
            </div>
            <div className={"my-4"}>
                <InputFormComponent
                    formStruct={formStruct}
                    form={student}
                    setForm={setStudent}
                    errorBag={errorBag}
                    ignoreUpdate={['parallel']}
                />
            </div>
            <button onClick={handleUpdateStaff} className={"btn-purple-bright mt-2 p-2 px-3 mx-3"}>Cập nhật
            </button>
        </AuthenticatedLayout>
    )
}

export default UpdateStudentScreen