import {createSlice} from '@reduxjs/toolkit';

const CommonSlice = createSlice({
    name: 'common',
    initialState: {
        system: {},
        permissions: {
            ACCESS_ALL_MODULE: 1
        },
        currentUser: {},
        loadingState: false
    },
    reducers: {
        commonAction: (state, action) => {
            return {...state, ...action.payload};
        },
    },
});

export const {commonAction} = CommonSlice.actions;
export default CommonSlice.reducer;
