import {configureStore} from '@reduxjs/toolkit';
import CommonReducer from "./_slices/CommonSlice"

const store = configureStore({
    reducer: {
        common: CommonReducer
    }
});

export default store;
