import {accessBranchAction} from "../_api/BranchApi";

const BranchComponent = ({branch}) => {
    const handleAccessBranch = () => {
        accessBranchAction(branch?.id).then((r) => {
            if (r?.success) {
                window.location.href = window.location.origin
            }
        })
    }

    return (
        <div className={"bg-white p-3 shadow-lg rounded"}>
            <div className={"logo text-center"}>
                <img src={branch?.logo} alt={"branch_logo"} style={{width: "5rem"}} className={"rounded-circle"}/>
            </div>
            <div className={"text-center my-2 h5 text-purple-bright"}>{branch?.name}</div>
            <hr/>
            <div>Mã chi nhánh : <span className={"fw-bold text-purple-bright"}>{branch?.uuid}</span></div>
            <div>Truy cập lần cuối : {branch?.last_active}</div>
            <hr/>
            <div className={"text-center"}>
                <button onClick={handleAccessBranch} className={"btn-purple-bright p-1 px-5"}>Truy cập</button>
            </div>
        </div>
    )
}
export default BranchComponent