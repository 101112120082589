import DataTableComponent from "../../_components/DataTableComponent";
import {useEffect, useState} from "react";
import FilteringComponent from "../../_components/Columns/FilteringComponent";
import {Button} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {handleCreateUrlParams, handleParseUrlParams} from "../../_classes/Helper";
import {commonAction} from "../../_provider/_slices/CommonSlice";
import {useDispatch} from "react-redux";
import SettingLayout from "../../_layouts/SettingLayout";
import {getAllSalaryCriteriaAction} from "../../_api/SalaryCriteriaApi";
import {getAllSalarySheetAction} from "../../_api/SalarySheetApi";
import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";

const ListSalarySheetScreen = () => {
    const location = useLocation();
    const [salaryCriteriaCollection, setSalaryCriteriaCollection] = useState({})
    const nav = useNavigate()
    const [query, setQuery] = useState()
    const columnStruct = [
        {
            title: 'Nhân sự',
            dataIndex: 'user',
            key: 'user',
            type: "user_info",
            width: 300
        },
        {
            title: 'Giai đoạn',
            dataIndex: 'period',
            key: 'period',
            type: "month",
            width: 300
        },
        {
            title: 'Tổng số tiền cần chi',
            dataIndex: 'total_amount',
            key: 'total_amount',
            type: "number",
            width: 300
        },
        {
            title: 'Tổng số tiền đã chi',
            dataIndex: 'total_paid',
            key: 'total_paid',
            type: "number",
            width: 300
        },
        {
            title: 'Hành động',
            dataIndex: 'salary_sheet_action',
            key: 'salary_sheet_action',
            type: "salary_sheet_action",
            width: 300
        },
    ];
    const filteringStructs = [
        {
            field: "user",
            type: "select",
            label: "Nhân sự",
            options: []
        }
    ]
    const dispatch = useDispatch()
    useEffect(() => {
        if (query) {
            const urlPath = handleCreateUrlParams(query)
            nav(urlPath)
            dispatch(commonAction({loadingState: true}))
            getAllSalarySheetAction(query).then((response) => {
                if (response?.success) {
                    setSalaryCriteriaCollection(response?.data)
                    dispatch(commonAction({loadingState: false}))
                } else {
                    dispatch(commonAction({loadingState: false}))
                }
            }).catch(() => {
                dispatch(commonAction({loadingState: false}))
            })
        }
    }, [query]);

    useEffect(() => {
        const searchParams = location.search.substring(1);
        const parseParams = handleParseUrlParams(searchParams)
        setQuery({
            limit: parseParams?.limit ?? 20,
            order: parseParams?.order,
            direction: parseParams?.direction,
            filtering: parseParams?.filtering,
            offset: parseParams?.offset || 0
        })
    }, [])

    return (
        <AuthenticatedLayout>
            <div className={"h4 text-purple-bright"}>Danh sách phiếu lương</div>
            <div className={"d-flex justify-content-between my-4"}>
                <FilteringComponent
                    filteringStructs={filteringStructs}
                    query={query}
                    setQuery={setQuery}
                />
                <Button onClick={() => nav('/salary_criteria/create')} className={'btn-purple-bright'}>Thêm mới</Button>
            </div>
            <div>
                <DataTableComponent
                    columnStructs={columnStruct}
                    collection={salaryCriteriaCollection}
                    query={query}
                    setQuery={setQuery}
                    setCollection={setSalaryCriteriaCollection}
                />
            </div>
        </AuthenticatedLayout>
    )
}

export default ListSalarySheetScreen