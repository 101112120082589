import AuthenticatedLayout from "../../_layouts/AuthenticatedLayout";
import {useSelector} from "react-redux";
import CustomLink from "../../_components/CustomLink";



const CurrentUserInfoScreen = () => {
    const commonState = useSelector((state) => state.common)

    return (
        <AuthenticatedLayout>
            <div className={"d-flex align-items-center"}>
                <img className={"border rounded-circle"} src={commonState?.currentUser?.avatar} alt={""} style={{width:"7rem"}}/>
                <div className={"ms-2"}>
                    <div className={"h4"}>{commonState?.currentUser?.name}</div>
                    <div className={"text-muted"}>{commonState?.currentUser?.uuid}</div>
                    <div className={"text-muted"}>{commonState?.currentUser?.role_name}</div>
                    <div className={"text-purple-bright cursor-pointer fw-bold"}>
                        <CustomLink to={'/user/edit'}>Sửa thông tin</CustomLink>
                    </div>
                </div>
            </div>
            <div className={"mt-3"}>
                <div className={"fw-bold mb-2"}>Thông tin chung</div>
                <div><span className={"fw-bold"}>Email</span>: {commonState?.currentUser?.email || '(Chưa điền)'}</div>
                <div><span className={"fw-bold"}>SĐT</span>: {commonState?.currentUser?.phone || '(Chưa điền)'}</div>
            </div>
        </AuthenticatedLayout>
    )
}
export default CurrentUserInfoScreen