// Route::prefix('transactions')->group(function () {
//     Route::get('/', [TransactionController::class, 'getAllTransactionsAction']);
//     Route::get('/{id}', [TransactionController::class, 'getOneTransactionAction']);
//     Route::post('/', [TransactionController::class, 'createTransactionAction']);
//     Route::put('/{id}', [TransactionController::class, 'updateTransactionAction']);
//     Route::put('/{id}/approve', [TransactionController::class, 'approveTransactionAction']);
// });

import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllTransactionsAction = async (query) => {
    return await (new Api()).get('transactions', query, getAuthHeader());
}
export const createTransactionAction = async (data) => {
    return await (new Api()).post('transactions', data, getAuthHeader())
}

export const getOneTransactionAction = async (id, data) => {
    return await (new Api()).get(`transactions/${id}`, data, getAuthHeader())
}

export const updateTransactionAction = async (data, id) => {
    return await (new Api()).put(`transactions/${id}`, data, getAuthHeader())
}

export const approveTransactionAction = async (id) => {
    return await (new Api()).put(`transactions/${id}/approve`, {}, getAuthHeader())
}
