import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from "antd";
const DateField = ({value,setValue}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
                <DatePicker
                    onChange={(r) => {
                        if (r) {
                            setValue(r)
                        }
                    }}
                    placeholder={"Chọn ngày"}
                    value={value}
                    rootClassName={" p-2"}
                    picker="date" format={"DD/MM/YYYY"}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}

export default DateField