import Api from "../_classes/Api";
import {getAuthHeader} from "../_classes/Helper";

export const getAllStaffsAction = async (query) => {
    return await (new Api()).get('staffs', query, getAuthHeader());
}

export const createStaffAction = async (data) => {
    return await (new Api()).post('staffs', data, getAuthHeader())
}

export const getOneStaffAction = async (id) => {
    return await (new Api()).get(`staffs/${id}`, {}, getAuthHeader())
}

export const updateStaffAction = async (data,id) => {
    return await (new Api()).put(`staffs/${id}`, data, getAuthHeader())
}
